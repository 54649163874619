import Dialog, { DialogProps } from '@mui/material/Dialog';
import React from 'react';
import cc from 'classcat';
import C from './CloseableDialog.css';
import { Trans } from '@lingui/macro';

export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

const CloseableDialog = ({
  onClose,
  className,
  children,
  ...p
}: Without<DialogProps, 'open'> & {
  onClose?: () => void;
  children: React.ReactChild | React.ReactChild[];
  className?: string;
}) => (
  <Dialog open onClose={onClose} className={cc([C.dialog, className])} {...p}>
    <a className={C.closeLink} onClick={onClose}>
      <Trans>Close</Trans>
    </a>
    {children}
  </Dialog>
);

export default CloseableDialog;
