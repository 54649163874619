import React from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  UseFormRegisterReturn,
} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { InputBaseProps } from '@mui/material/InputBase';
import { FilledInputProps } from '@mui/material/FilledInput';
import omit from 'lodash/omit';
import { Input } from '@mui/material';

export default function ControlledTextField(p: {
  id?: string;
  name: string;
  control: any; // TODO FIXME
  required?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  placeholder?: string;
  error?: boolean;
  register?: UseFormRegisterReturn;
  inputProps?: InputBaseProps['inputProps'];
  InputProps?: Partial<FilledInputProps>;
  multiline?: boolean;
  minRows?: number;
  rules?: any;
  variant?: 'filled' | 'outlined';
  size?: 'small' | 'medium';
  helperText?: string;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  interceptField?: <TField extends ControllerRenderProps<FieldValues, string>>(
    field: Partial<TField>,
  ) => Partial<TField>;
}) {
  return (
    <Controller
      control={p.control}
      {...omit(p.register, 'ref')}
      rules={p.rules}
      name={p.name}
      render={({ field }) => {
        const { ref, ...rest } = field;
        return (
          <TextField
            id={p.id}
            inputRef={ref}
            {...(p.interceptField ? p.interceptField(rest) : rest)}
            fullWidth
            className={p.className}
            variant={p.variant || 'filled'}
            size={p.size}
            value={field.value ?? ''}
            type={p.type}
            error={p.error}
            required={p.required}
            placeholder={p.placeholder}
            inputProps={p.inputProps}
            InputProps={p.InputProps}
            multiline={p.multiline}
            minRows={p.minRows}
            helperText={p.helperText}
            autoFocus={p.autoFocus}
            autoComplete={p.autoComplete}
          />
        );
      }}
    />
  );
}
