import React, { CSSProperties, ReactNode } from 'react';
import { MaybeArray } from 'utils/types';

const FlexRow = (p: {
  alignItems?: string;
  className?: string;
  gap?: string;
  style?: CSSProperties;
  children?: MaybeArray<ReactNode>;
  containerRef?: (el: HTMLDivElement | null) => void;
}) => (
  <div
    style={{
      gap: p.gap,
      ...p.style,
      display: 'flex',
      flexDirection: 'row',
      alignItems: p.alignItems ?? 'center',
    }}
    className={p.className}
    ref={p.containerRef}
  >
    {p.children}
  </div>
);

export default FlexRow;
