import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Typography,
  Box,
  Button,
  Autocomplete,
  TextField,
  FormHelperText,
  MenuItem,
  Select,
} from '@mui/material';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import ControlLabel from 'components/control-label/ControlLabel';
import FlexRow from 'components/flex/FlexRow';
import {
  COMMUNITY_MEMBERSHIP_ROLES,
  COMMUNITY_PERMISSIONS_TEXT,
  COMMUNITY_PERMISSION_TYPES,
  getSameOrLowerRoles,
} from 'constants/community-roles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EMAIL_REGEX } from 'utils/validation';
import { msg } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';

export async function sendInvites(
  communityId: string,
  emails: string[],
  roles: string[],
) {
  const formData = new FormData();
  formData.append(
    'content',
    JSON.stringify({
      emailsOrDomains: emails,
      roles,
    }),
  );

  await httpClient.put(`/community/${communityId}/email-membership`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export default function MutliEmailInput({
  emails,
  setEmails,
  setRoles,
  hidePlaceholder,
}: {
  emails: string[];
  setEmails: (emails: string[]) => void;
  setRoles: (roles: string) => void;
  hidePlaceholder?: boolean;
}) {
  const { _ } = useLingui();
  const [showHelp, setShowHelp] = useState(false);
  const newEmailTextFieldRef = useRef<HTMLInputElement>(null);
  const [role, setRole] = useState<string | null>('MEMBER');
  const [inputValue, setInputValue] = useState('');

  const selectedRole = useMemo(() => {
    if (!role) return null;
    return COMMUNITY_MEMBERSHIP_ROLES.find((it) => it.value === role);
  }, [role]);

  useEffect(() => {
    if (role) {
      setRoles(role);
    }
  }, [role]);

  return (
    <Stack width='100%'>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='caption'>
          {showHelp && !hidePlaceholder ? (
            _(msg`To add email, press enter`)
          ) : (
            <div>&nbsp;</div>
          )}
        </Typography>
        <Box />
        <Button
          onClick={() => {
            setEmails([]);
          }}
          variant='text'
          sx={{
            fontSize: 'var(--font-size-subtext) !important',
            marginBottom: '-0.3rem',
            marginRight: '-0.3rem',
          }}
        >
          {emails.length > 0 ? _(msg`Clear all`) : ''}
        </Button>
      </Stack>
      <Box mt={0.2} />
      <Autocomplete
        multiple
        freeSolo
        fullWidth
        options={[]}
        clearIcon={false}
        sx={{
          background: 'var(--coco-gray)',
          borderRadius: '0.5rem',
        }}
        value={emails}
        inputValue={inputValue}
        onInputChange={(e, newValue) => {
          if (!newValue) {
            setShowHelp(false);
          } else {
            setShowHelp(true);
          }

          // Detect space and comma
          if (newValue.includes(' ') || newValue.includes(',')) {
            const newEmails = newValue
              .split(/[\s,]+/)
              .filter((it) => EMAIL_REGEX.test(it))
              .filter((it) => !emails.includes(it));
            // Add only if not already present
            setEmails([...emails, ...new Set(newEmails)]);
            setInputValue('');
          } else {
            setInputValue(newValue);
          }
        }}
        onKeyDown={(e) => {
          // Detect backspace
          if (
            e.key === 'Backspace' &&
            emails.length > 0 &&
            newEmailTextFieldRef.current?.value === ''
          ) {
            setEmails(emails.slice(0, emails.length - 1));
            return;
          }
        }}
        onPaste={(e) => {
          // Try to detect multiple emails pasted at once
          const pasted = e.clipboardData.getData('text');
          const pastedEmails = pasted
            .split(/[\s,;]+/)
            .filter((it) => EMAIL_REGEX.test(it));

          // Remove duplicates, and also remove emails that are already in the list.
          const newEmails = pastedEmails.filter((it) => !emails.includes(it));

          setEmails([...emails, ...new Set(newEmails)]);

          e.preventDefault();
        }}
        onChange={(e, newValue) => {
          const newElem = e.target.value;

          if (newElem === undefined) {
            setEmails(newValue as string[]);
            return;
          }
          if (EMAIL_REGEX.test(newElem)) {
            setEmails([...emails, newElem]);
          }
        }}
        ListboxProps={{
          style: {
            display: 'block',
          },
        }}
        renderInput={(p) => {
          return (
            <TextField
              variant='filled'
              placeholder={
                emails.length === 0 ? _(msg`Add emails here…`) : undefined
              }
              name='members'
              {...p}
              inputProps={{
                ...p.inputProps,
                style: {
                  marginTop: '0.5rem',
                  marginLeft: '0.5rem',
                },
              }}
              InputProps={{
                ...p.InputProps,
                sx: {
                  alignItems: 'flex-start',
                },
              }}
              multiline
              fullWidth
              minRows={4}
              inputRef={newEmailTextFieldRef}
            />
          );
        }}
      />

      <Box mt={2} />

      <FlexRow>
        <ControlLabel
          style={{
            paddingBottom: 0,
            fontWeight: 'var(--font-weight-bold)',
            fontSize: 'var(--font-size-primary)',
            color: 'var(--color-primary)',
          }}
        >
          {_(msg`Select role:`)}
        </ControlLabel>
        <Select
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
          }}
          size='small'
          style={{ marginLeft: '1rem', flexGrow: 1 }}
        >
          {getSameOrLowerRoles(['OWNER']).map(({ label, value }) => {
            const displayLabel =
              value === 'OWNER' || value === 'MANAGER' ? (
                <>
                  {_(msg`Corridor`)} <Trans id={label.id} />
                </>
              ) : (
                <Trans id={label.id} />
              );
            return (
              <MenuItem key={value} value={value}>
                {displayLabel}
              </MenuItem>
            );
          })}
        </Select>
      </FlexRow>
      <Box mt={2} />
      {selectedRole && (
        <Box
          sx={{
            maxWidth: '600px',
          }}
        >
          {selectedRole.adviseCaution && (
            <FormHelperText style={{ color: 'var(--color-coco-pink)' }}>
              {_(msg`Make sure that ${_(selectedRole.label)} role is granted to trusted
                users only.`)}
            </FormHelperText>
          )}
          {Object.keys(COMMUNITY_PERMISSION_TYPES)
            .filter((perm) => !isNaN(Number(perm)))
            .map((perm) => {
              let icon: React.ReactNode;
              if (selectedRole.permissions?.includes(Number(perm))) {
                icon = (
                  <CheckBoxIcon style={{ color: 'green', width: '1.2rem' }} />
                );
              } else {
                icon = <CloseIcon style={{ color: 'red', width: '1.2rem' }} />;
              }
              return (
                <FormHelperText key={perm}>
                  <Stack direction='row' alignItems='center' gap='0.2rem'>
                    {icon}
                    <Trans id={COMMUNITY_PERMISSIONS_TEXT[perm].id} />
                  </Stack>
                </FormHelperText>
              );
            })}
        </Box>
      )}

      <Box mt={2} />
    </Stack>
  );
}
