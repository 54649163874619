const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const MODAL_CAPTURE_SCREEN = 'captureScreenModal';
const MODAL_IDLE = 'idleModal';
const MODAL_MEDIA_FAILURE = 'mediaFailureModal';

export const openModal = (modal: string, context?: any) => ({
    type: OPEN_MODAL,
    modal: modal,
    context
})

const openCaptureScreenModal = (initiator: 'RECORD_SPACE' | 'SHARE_SPACE') =>
    openModal(MODAL_CAPTURE_SCREEN, { initiator })

const openIdleModal = () =>
    openModal(MODAL_IDLE)

const openMediaFailureModal = () =>
    openModal(MODAL_MEDIA_FAILURE)

export const modalActionCreators = {
    openCaptureScreenModal,
    openIdleModal,
    openMediaFailureModal,
}
