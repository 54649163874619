/* eslint-disable lingui/no-unlocalized-strings */
import { AxiosResponse } from 'axios';
import * as z from 'zod';
import { useEffect, useState } from 'react';
import { reportServerError } from 'utils/report-error';
import { SyncState } from 'utils/sync-state';
import { RecoilState, useRecoilState } from 'recoil';
import { browserStorage } from 'utils/browser-storage';
import { Deferred } from '@cocoplatform/coco-rtc-client';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export interface RequestHandle<TRes> {
  syncState: SyncState;
  data: TRes | null;
  trigger: () => void;
}

export const getKey = (action: string, params: any[]) => {
  return `coco:u:$user:${action}::${params.join(':')}`;
};

export interface ReqState<TRes> {
  syncState: SyncState;
  data: TRes | null;
}

export const useRequest = <TRes = any, TParams extends any[] = []>(opts: {
  id?: string;
  typeSchema?: z.ZodType<TRes>;
  atom?: RecoilState<ReqState<TRes>>;
  request: (...params: TParams) => Promise<AxiosResponse<TRes>>;
  params: TParams;
  autoTrigger?: boolean;
  isSilent?: boolean;
  action?: string;
  persistLocally?: boolean;
  lock?: { locked: Deferred<any> | null };
}): RequestHandle<TRes> => {
  const { _ } = useLingui();
  const [{ syncState, data }, setState] = opts.atom
    ? useRecoilState(opts.atom)
    : useState<ReqState<TRes>>({
        syncState: 'pending',
        data: null,
      });
  const setData = (data: TRes | null) => setState((s) => ({ ...s, data }));
  const setSyncState = (syncState: SyncState) =>
    setState((s) => ({
      ...s,
      syncState,
    }));
  useEffect(() => {
    if (opts.persistLocally) {
      if (!opts.id) throw new Error(`id is mandatory for persistLocally`);
      const key = getKey(opts.id, opts.params);
      let raw: string | null = null;
      try {
        raw = browserStorage.getItem(key);
        if (!raw) return;
        const parsed = JSON.parse(raw);
        setData(opts.typeSchema ? opts.typeSchema.parse(parsed) : parsed);
      } catch (e) {
        console.error(e);
        browserStorage.removeItem(key);
      }
    }
  }, []);

  const trigger = async () => {
    setSyncState('loading');

    if (opts.lock) {
      if (opts.lock.locked) {
        return opts.lock.locked.promise;
      }
      opts.lock.locked = new Deferred();
    }
    try {
      const resp = await opts.request(...opts.params);

      if (opts.lock) {
        opts.lock.locked?.resolve(resp);
        opts.lock.locked = null;
      }

      setData(resp.data);
      setSyncState('loaded');

      if (resp.data && opts.persistLocally && opts.id) {
        const key = getKey(opts.id, opts.params);
        const raw = JSON.stringify(resp.data);
        try {
          browserStorage.setItem(key, raw);
        } catch (e) {
          console.error(e);
        }
      }
      return resp;
    } catch (error) {
      setSyncState('failed');
      if (opts.lock) {
        opts.lock.locked?.reject(error);
        opts.lock.locked = null;
      }
      if (opts.isSilent) {
        console.error(error);
      } else {
        reportServerError({
          title: opts.action
            ? _(msg`Failed to ${opts.action}`)
            : _(msg`Request failed`),
          error,
        });
      }
    }
  };

  useEffect(() => {
    if (opts.autoTrigger === false) return;
    trigger();
  }, opts.params);

  return {
    syncState,
    data,
    trigger,
  };
};
