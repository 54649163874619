import React, { CSSProperties } from 'react';
import FlexRow from 'components/flex/FlexRow';
import Checkbox from '@mui/material/Checkbox';
import ControlLabel from 'components/control-label/ControlLabel';
import { Trans } from '@lingui/macro';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';
// import { browserStorage } from 'utils/browser-storage';

export default function StorageOptionControl(p: {
  className?: string;
  style?: CSSProperties;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  /* const [checked, setChecked] = useState(p.checkedDefault ?? false);

  useEffect(() => {
    if (checked && browserStorage.current === sessionStorage) {
      browserStorage.useLocalStorage();
    } else if (!checked && browserStorage.current === localStorage) {
      browserStorage.useSessionStorage();
    }
  }, [checked]); */
  const track = useTrack();

  if (!SUPPORT_PERSIST_SESSION) return null;

  return (
    <FlexRow alignItems='center' className={p.className} style={p.style}>
      <Checkbox
        checked={p.checked}
        id='keepSignedIn'
        onChange={(event) => {
          track(EVENTS.KEEP_ME_SIGNED_IN, INTERACTIONS.CLICK, {
            checked: event.target.checked,
          });
          p.onChange(event.target.checked);
        }}
      />
      <ControlLabel
        htmlFor='keepSignedIn'
        style={{ padding: 0, fontSize: 'var(--font-size-subtext)' }}
      >
        <Trans>Keep me signed in on this device</Trans>
      </ControlLabel>
    </FlexRow>
  );
}
