import capitalize from 'lodash/capitalize';

export const qualifiedUserName = (u: {
  firstName?: string | null;
  lastName?: string | null;
  username?: string | null;
}) => {
  if (u.username) {
    return u.username;
  }
  const name = ([u.firstName, u.lastName].filter(Boolean) as string[])
    // .map(capitalize)
    .join(' ');
  //if (!u.username) return name;
  //return `${name} (${u.username})`;
  return name;
};
