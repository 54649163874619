/* eslint-disable lingui/no-unlocalized-strings */
import { httpClient } from '@cocoplatform/coco-rtc-client';
import * as z from 'zod';
import sha1 from 'sha1';
import { atom, useRecoilState } from 'recoil';
import { useEffect, useMemo, useRef } from 'react';
import { usePreviousDifferent } from 'rooks';
import { useHistory } from 'react-router-dom';
import { browserStorage } from './browser-storage';

declare const COCO_PASSCODE: string;

export const PASSCODE_KEY = 'coco:passcode';
const TOKEN_KEY = 'coco:token:v1';
const PROFILE_EDITABLE_KEY = 'coco:profile:editable:v1';
const PROFILE_STATE_KEY = 'coco:profile:completion-state';
const EMAIL_KEY = 'coco:email';

export const PostLoginCommunityInvitePayloadSchema = z.object({
  code: z.string(),
  targetType: z.string(),
  targetId: z.string(),
});

export interface PostLoginCommunityInvitePayload
  extends z.TypeOf<typeof PostLoginCommunityInvitePayloadSchema> { }

const getDefaultAuthState = (): AuthState => {
  try {
    const savedPasscode = browserStorage.getItem(PASSCODE_KEY, {
      attemptAll: true,
    });
    const didSetPasscode =
      savedPasscode && sha1(savedPasscode) === COCO_PASSCODE;
    return {
      savedToken: browserStorage.getItem(TOKEN_KEY) ?? undefined,
      canEditAcc: browserStorage.getItem(PROFILE_EDITABLE_KEY) === 'true',
      didSetPasscode,
      profileCompletionState: (browserStorage.getItem(PROFILE_STATE_KEY) ??
        'PENDING') as ProfileCompletionState,
      email: browserStorage.getItem(EMAIL_KEY) ?? undefined,
    };
  } catch (e: any) {
    console.error(`Failed to restore auth state from browserStorage`, e);
    return {
      savedToken: undefined,
      didSetPasscode: false,
      profileCompletionState: 'PENDING',
    };
  }
};

type ProfileCompletionState = 'PENDING' | 'COMPLETE' | 'PERMITTED_INCOMPLETE';

interface AuthState {
  savedToken?: string;
  email?: string;
  canEditAcc?: boolean;
  profileCompletionState: ProfileCompletionState;
  // isProfileUpdated: boolean;
  didSetPasscode: boolean;
}

export const authStateAtom = atom<AuthState>({
  key: 'authState',
  default: getDefaultAuthState(),
});

export const useAuthState = () => {
  const [authState, setAuthState] = useRecoilState(authStateAtom);

  // Retained for backward compat
  const isProfileUpdated =
    authState.profileCompletionState === 'PERMITTED_INCOMPLETE' ||
    authState.profileCompletionState === 'COMPLETE';

  const setEmail = (email: string) => {
    browserStorage.setItem(EMAIL_KEY, email);
    setAuthState((s) => ({ ...s, email }));
  };

  const saveToken = (token: string, enableEditAcc?: boolean) => {
    setAuthState((s) => ({
      ...s,
      ...(enableEditAcc != undefined ? { canEditAcc: enableEditAcc } : {}),
      savedToken: token,
    }));
    try {
      browserStorage.setItem(TOKEN_KEY, token);

      if (enableEditAcc != undefined) {
        browserStorage.setItem(
          PROFILE_EDITABLE_KEY,
          JSON.stringify(enableEditAcc),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clearToken = () => {
    setAuthState((s) => ({
      ...s,
      savedToken: undefined,
      profileCompletionState: 'PENDING',
    }));
    try {
      browserStorage.clear();
    } catch (e) {
      console.error(e);
    }
  };

  const setProfileCompletionState = (
    profileCompletionState: ProfileCompletionState,
  ) => {
    try {
      setAuthState((s) => ({ ...s, profileCompletionState }));
      browserStorage.setItem(PROFILE_STATE_KEY, profileCompletionState);
    } catch (e) {
      console.error(e);
    }
  };

  const authenticateWithPasscode = async (passcode: string) => {
    if (sha1(passcode) === COCO_PASSCODE) {
      browserStorage.setItem(PASSCODE_KEY, passcode);
      setAuthState((s) => ({ ...s, didSetPasscode: true }));
    }
  };

  return {
    ...authState,
    isProfileUpdated,
    isSignedIn: !!authState.savedToken,
    setEmail,
    saveToken,
    clearToken,
    setProfileCompletionState,
    authenticateWithPasscode,
  };
};

export const useAuthStateEffect = () => {
  const { savedToken: token, saveToken, clearToken } = useAuthState();
  const prevToken = usePreviousDifferent(token);
  const history = useHistory();
  const interceptorRef = useRef<number>();

  useEffect(() => {
    if (!token) {
      browserStorage.clear();
    }
  }, []);

  useMemo(() => {
    if (prevToken && interceptorRef.current != null) {
      httpClient.interceptors.request.eject(interceptorRef.current);
    }
    if (token) {
      interceptorRef.current = httpClient.interceptors.request.use((config) => {
        config.headers = config.headers ?? {};
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      });
    }
  }, [token, prevToken]);

  useEffect(() => {
    const timer = setInterval(
      () => {
        ('Refreshing token');
        httpClient.post(`/auth/refresh`).then((res) => {
          if (res.data.success) {
            saveToken(res.data.token);
          }
        });
      },
      1000 * 60 * 60,
    );

    httpClient.interceptors.response.use(
      (config) => {
        return config;
      },
      (err) => {
        console.error(err);
        if (err?.response?.status === 401) {
          clearToken();
          history.push('/app');
        }
        return Promise.reject(err);
      },
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);
};
