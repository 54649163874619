import {
  CommunityWithAggregates,
  CommunityWithAggregatesSchema,
} from '@cocoplatform/coco-rtc-shared';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { atom } from 'recoil';
import { useMemo } from 'react';
import { ReqState, useRequest } from 'utils/hooks/useRequest';

const defaultState: ReqState<CommunityWithAggregates[]> = {
  data: null,
  syncState: 'pending',
};

export const communitySummariesAtom = atom({
  key: 'communitySummaries',
  default: defaultState,
});

const communitySummariesLock = {
  locked: null,
};

export const useCommunitySummaries = (opts?: { autoLoad?: boolean }) => {
  const { syncState, data, trigger } = useRequest({
    id: 'communitySummaries',
    atom: communitySummariesAtom,
    typeSchema: CommunityWithAggregatesSchema.array(),
    request: () =>
      httpClient.get('/communities?includeRequested=true&includeInvited=true'),
    autoTrigger: opts?.autoLoad !== false,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    action: 'fetch community list',
    persistLocally: true,
    params: [],
    lock: communitySummariesLock,
  });
  return { syncState, communities: data ?? [], trigger };
};

export const useCommunitySummary = (opts: { communityId?: string }) => {
  const { communities, syncState } = useCommunitySummaries({
    autoLoad: false,
  });
  const community = useMemo(
    () =>
      opts.communityId
        ? communities?.find((it) => it.id === opts.communityId)
        : null,
    [opts.communityId],
  );
  return { community, syncState };
};
