export const Types = {
    MARK_PROJECT_DIRTY: "scratch-gui/space/MARK_PROJECT_DIRTY",
    UNMARK_PROJECT_DIRTY: "scratch-gui/space/UNMARK_PROJECT_DIRTY",
    START_SAVING_PROJECT: "scratch-gui/space/START_SAVING_PROJECT",
    END_SAVING_PROJECT: "scratch-gui/space/END_SAVING_PROJECT",
    PROJECT_LOADING_PROGRESS: "scratch-gui/space/PROJECT_LOADING_PROGRESS",
}

export const reportProjectLoadingProgress = (collaboratorId: string, progress: number) => ({
    type: Types.PROJECT_LOADING_PROGRESS,
    progress,
    collaboratorId,
})

export type ReportProjectLoadingProgressAction = ReturnType<typeof reportProjectLoadingProgress>

export const startSavingProject = () => ({
    type: Types.START_SAVING_PROJECT,
    at: +new Date(),
})

export type StartSavingProjectAction = ReturnType<typeof startSavingProject>

export const markProjectDirty = () => ({
    type: Types.MARK_PROJECT_DIRTY,
    at: +new Date(),
})

export type MarkProjectDirtyAction = ReturnType<typeof markProjectDirty>

export const unmarkProjectDirty = () => ({
    type: Types.UNMARK_PROJECT_DIRTY,
    at: +new Date(),
})

export type UnMarkProjectDirtyAction = ReturnType<typeof markProjectDirty>

export const endSavingProject = (didSucceed: boolean) => ({
    type: Types.END_SAVING_PROJECT,
    didSucceed,
    at: +new Date(),
})

export type EndSavingProjectAction = ReturnType<typeof endSavingProject>

export type ProjectAction =
    | StartSavingProjectAction
    | EndSavingProjectAction
    | MarkProjectDirtyAction
    | UnMarkProjectDirtyAction
    | ReportProjectLoadingProgressAction

export const projectActionCreators = {
    startSavingProject,
    endSavingProject,
    markProjectDirty,
    unmarkProjectDirty,
    reportProjectLoadingProgress,
}