import { atom } from "./utils/store"

export interface ClusterState {
    clusterId: string
    spaces: Record<string, ClusterSpaceInfo | null>
    visitedSpaceId?: string
}

export interface ClusterSpaceInfo {
    peerIds: string[]
}

export const clusterDetails = atom<ClusterState | null>(null)
