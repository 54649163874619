import Avatar from '@mui/material/Avatar';
import React, { CSSProperties, ReactChild } from 'react';
import { useUserProfile } from '../../atoms/user-profile';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { UserProfile } from '@cocoplatform/coco-rtc-shared';
import avatar from 'animal-avatar-generator';
import { useAuthState } from 'utils/auth-state';

export default function UserAvatar(p: {
  user?: Pick<UserProfile, 'firstName' | 'lastName' | 'avatarUrl'> | null;
  className?: string;
  size?: number | null;
  style?: CSSProperties | null;
  preContent?: ReactChild | null;
  postContent?: ReactChild | null;
}) {
  const { userProfile: currentUserProfile } = useUserProfile();
  const { profileCompletionState } = useAuthState();

  const userProfile = p.user;
  const size = p.size ?? 40;
  const src = getAvatarUrl();
  return (
    <Avatar
      className={p.className}
      style={{
        height: `${size}px`,
        width: `${size}px`,
        color: 'var(--color-subtext)',
        fontWeight: '400',
        fontSize: 'var(--font-size-subtext)',
        backgroundColor: 'var(--color-selected)',
        border: '1px solid var(--color-border)',
        ...p.style,
      }}
    >
      <>
        {p.preContent}
        {src ? (
          <img
            src={src}
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
              objectFit: 'cover',
              borderRadius: '50%',
            }}
          />
        ) : (
          getAvatarAbbr(userProfile) ||
          (userProfile?.id &&
          (userProfile?.id === currentUserProfile?.id
            ? currentUserProfile?.onboardingComplete &&
              (profileCompletionState === 'COMPLETE' ||
                profileCompletionState === 'PERMITTED_INCOMPLETE')
            : true) ? (
            <img
              src={`data:image/svg+xml;base64,${btoa(avatar(userProfile?.id || ''))}`}
              style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
            />
          ) : (
            <PersonOutlineIcon />
          ))
        )}
        {p.postContent}
      </>
    </Avatar>
  );

  function getAvatarUrl() {
    return userProfile?.avatarUrl;
  }
}

export function getAvatarAbbr(
  userProfile: Partial<UserProfile> | null | undefined,
) {
  if (userProfile?.avatarUrl) return null;
  if (!userProfile) return null;
  return [userProfile.firstName, userProfile.lastName]
    .filter(Boolean)
    .map((it) => it?.charAt(0))
    .join('')
    .toUpperCase();
}
