import FlexRow from 'components/flex/FlexRow';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import C from './PrimarySpaceSearchInput.css';
import isEqual from 'lodash/isEqual';
import { createPortal } from 'react-dom';
import PrimarySearchDialog from './PrimarySearchDialog';
import { useCommunityDetails } from 'atoms/community-details';
import { DashboardParams } from 'constants/routes';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { useRecoilState } from 'recoil';
import { communityPresenceAtom } from 'atoms/community-presence';
import UserAvatar from 'components/user-avatar/UserAvatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import cc from 'classcat';
import { Trans } from '@lingui/macro';

export default function PrimarySpaceSearchInput(p: { className?: string }) {
  const params = useParams<DashboardParams>();
  const { community } = useCommunityDetails({
    autoLoad: false,
    communityId: params.communityId,
  });
  const targetName = community?.name;
  const [hasFocus, setFocus] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const overlayContainerRef = useRef<HTMLDivElement | null>();
  const [communityPresenceMapping] = useRecoilState(communityPresenceAtom);
  const history = useHistory();

  useEffect(() => {
    overlayContainerRef.current =
      document.querySelector<HTMLDivElement>('#overlay-container');
  }, []);

  const [inputBounds, setInputBounds] = useState<{
    top: number;
    left: number;
    width: number;
  } | null>(null);

  const updateInputBounds = useCallback(() => {
    const input = containerRef.current;
    if (!input) return;
    const bounds = input.getBoundingClientRect();
    setInputBounds((oldBounds) => {
      const newBounds = {
        top: bounds.top,
        left: bounds.left,
        width: bounds.width,
      };
      if (isEqual(newBounds, oldBounds)) return oldBounds;
      return newBounds;
    });
  }, []);

  const setContainerEl = (input: HTMLDivElement | null) => {
    containerRef.current = input;
    updateInputBounds();
  };

  useEffect(() => {
    window.addEventListener('resize', updateInputBounds);
    return () => {
      window.removeEventListener('resize', updateInputBounds);
    };
  }, []);

  const { userId: participantId, query } = useQueryParams();

  const searchedParticipant = useMemo(() => {
    if (!participantId) return null;
    return communityPresenceMapping[community?.id ?? '$all']?.users?.find(
      (u) => u.id === participantId,
    );
  }, [communityPresenceMapping, participantId, community?.id]);

  return (
    <>
      <FlexRow
        className={cc([C.container, p.className])}
        containerRef={setContainerEl}
      >
        <>
          <button
            className={C.inputBtn}
            onClick={() => {
              setFocus(true);
            }}
          >
            {searchedParticipant ? (
              <UserAvatar user={searchedParticipant} size={25} />
            ) : (
              <SearchIcon style={{ color: 'var(--color-base)', height: 24 }} />
            )}
            {searchedParticipant ? (
              <span>
                <Trans>Spaces by</Trans>:{' '}
                <>
                  {searchedParticipant.firstName || searchedParticipant.lastName
                    ? `${searchedParticipant.firstName} ${searchedParticipant.lastName}`
                    : searchedParticipant.username}
                </>
              </span>
            ) : query ? (
              query
            ) : (
              <>
                <Trans>Search</Trans> {targetName ?? <Trans>CoCo Spaces</Trans>}
              </>
            )}
          </button>
          {query || searchedParticipant ? (
            <IconButton
              onClick={() => {
                history.push(location.pathname);
              }}
            >
              <>
                <CloseIcon style={{ color: 'rgba(255, 255, 255, 0.5)' }} />
              </>
            </IconButton>
          ) : null}
        </>
      </FlexRow>
      {hasFocus &&
        createPortal(
          <div
            className={C.searchDialogBackdrop}
            onClick={() => {
              setFocus(false);
            }}
          >
            <Suspense fallback={null}>
              <PrimarySearchDialog
                {...{ community }}
                style={inputBounds}
                onEnd={() => {
                  setFocus(false);
                }}
                defaultQuery={query}
              />
            </Suspense>
          </div>,
          overlayContainerRef.current!,
        )}
    </>
  );
}
