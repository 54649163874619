import React, { CSSProperties, useEffect, useState } from 'react';
import cc from 'classcat';
import qs from 'query-string';
import C from './PrimarySpaceSearchInput.css';
import FlexRow from 'components/flex/FlexRow';
import SearchIcon from '@mui/icons-material/Search';
import { CommunityDetails, User } from '@cocoplatform/coco-rtc-shared';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { reportServerError } from 'utils/report-error';
import UserAvatar from 'components/user-avatar/UserAvatar';
import DotSeparator from 'components/item-list/Separator';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import isEmpty from 'lodash/isEmpty';
import IconButton from '@mui/material/IconButton';
import { useLingui } from '@lingui/react';
import { Trans, msg, t } from '@lingui/macro';

const memo: Record<string, User[]> = {};

const fetchResults = async (
  communityId: string | undefined | null,
  searchQuery: string,
): Promise<User[]> => {
  const memoKey = `${communityId}:${searchQuery}`;
  if (memo[memoKey]) return memo[memoKey];
  try {
    const resp = await httpClient.get(`/users/search`, {
      params: {
        q: searchQuery,
        communityId,
      },
    });
    memo[memoKey] = resp.data;
    return resp.data;
  } catch (error: any) {
    reportServerError({
      title: t`Failed to fetch users`,
      error,
    });
    return [];
  }
};

export default function PrimarySearchDialog(p: {
  community?: CommunityDetails;
  className?: string | null;
  defaultQuery?: string | null;
  style?: CSSProperties | null;
  onEnd?: () => void;
}) {
  const { _ } = useLingui();
  const [searchQuery, setSearchQuery] = useState(p.defaultQuery ?? '');
  const targetName = p.community?.name;
  const [users, setUsers] = useState<User[]>([]);
  const [highlightedUserId, setHighlightedUserId] = useState<string | null>(
    null,
  );
  const history = useHistory();
  const location = useLocation();

  const [didUseKeyboardNav, setUsedKeyboardNav] = useState(false);

  useEffect(() => {
    let shouldPopulate = true;
    const timer = setTimeout(() => {
      fetchResults(p.community?.id, searchQuery).then((users) => {
        if (!shouldPopulate) return;
        setUsers(users);
      });
    }, 500);
    return () => {
      clearTimeout(timer);
      shouldPopulate = false;
    };
  }, [p.community?.id, searchQuery]);

  return (
    <div
      className={cc([C.searchDialog, p.className])}
      style={{
        ...p.style,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FlexRow className={C.inputContainer}>
        <>
          <SearchIcon
            style={{ color: 'var(--color-subtext)', height: '16px' }}
          />
          <input
            className={C.input}
            placeholder={_(
              msg`Search CoCo spaces by keywords or co-creators...`,
            )}
            autoFocus
            value={searchQuery}
            onKeyDown={(e) => {
              switch (e.key) {
                case 'Enter': {
                  if (highlightedUserId) {
                    history.push(
                      `${location.pathname}?` +
                        qs.stringify({
                          userId: highlightedUserId,
                        }),
                    );
                    p.onEnd?.();
                    return;
                  }
                  history.push(
                    `${location.pathname}?` +
                      qs.stringify({
                        query: searchQuery,
                      }),
                  );
                  p.onEnd?.();
                  break;
                }
                case 'ArrowDown': {
                  setUsedKeyboardNav(true);
                  if (!highlightedUserId) {
                    setHighlightedUserId(users[0]?.id);
                    return;
                  }
                  const idx = users.findIndex(
                    (u) => u.id === highlightedUserId,
                  );
                  setHighlightedUserId(users[idx + 1]?.id);
                  return;
                }
                case 'ArrowUp': {
                  setUsedKeyboardNav(true);
                  if (!highlightedUserId) {
                    return;
                  }
                  const idx = users.findIndex(
                    (u) => u.id === highlightedUserId,
                  );
                  setHighlightedUserId(users[idx - 1]?.id);
                  return;
                }
              }
            }}
            onInput={(e) => {
              setSearchQuery(e.currentTarget.value);
            }}
          ></input>
          {!isEmpty(searchQuery) && (
            <IconButton
              onClick={() => {
                setSearchQuery('');
              }}
              size='small'
            >
              <>
                <CloseIcon style={{ color: 'var(--color-text-muted)' }} />
              </>
            </IconButton>
          )}
        </>
      </FlexRow>
      <div className={C.searchResults}>
        <Link
          className={cc([
            C.searchLI,
            { [C.hSearchLI]: didUseKeyboardNav && !highlightedUserId },
          ])}
          to={
            `${location.pathname}?` +
            qs.stringify({
              query: searchQuery,
            })
          }
          onClick={p.onEnd}
        >
          <SearchIcon style={{ color: 'var(--color-text)', height: '16px' }} />
          <div style={{ paddingLeft: '10px' }}>
            <strong>{searchQuery}</strong> <Trans> in </Trans>{' '}
            {targetName ?? <Trans>CoCo Spaces</Trans>}
          </div>
        </Link>
        {users.length > 0 && (
          <div className={cc([C.usernameLabel, C.searchLI])}>
            <Trans>Search CoCo spaces by people</Trans>
          </div>
        )}
        {users.map((u) => (
          <Link
            className={cc({
              [C.searchLI]: true,
              [C.hSearchLI]: highlightedUserId === u.id,
            })}
            to={
              `${location.pathname}?` +
              qs.stringify({
                userId: u.id,
              })
            }
            onClick={p.onEnd}
          >
            <UserAvatar user={u} size={30} style={{ marginRight: '10px' }} />
            <>{u.username}</>
            <>{u.username && (u.firstName || u.lastName) && <DotSeparator />}</>
            <>
              {u.firstName} {u.lastName}
            </>
          </Link>
        ))}
      </div>
    </div>
  );
}
