import { useUserProfile } from 'atoms/user-profile';
import { useCommunitySummaries } from 'atoms/community-summaries';
import React, { ReactChild, ReactChildren, useEffect } from 'react';
import { useAuthState } from 'utils/auth-state';
import { P } from 'ts-pattern';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { atom } from '@cocoplatform/coco-rtc-client';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

export enum OnboardingSteps {
  ENTRY_COMMMUNITY_TYPE,
  PERSONAL_SIGNUP,
  PERSONAL_PROFILE_DETAILS,
  PERSONAL_CORRIDOR_SETUP,
  PERSONAL_INVITE_CORRIDOR,
  EDUCATIONAL_SIGNUP,
  EDUCATIONAL_PROFILE_DETAILS,
  EDUCATIONAL_ORGANIZATION_DETAILS,
  EDUCATIONAL_CORRIDOR_SETUP,
  EDUCATIONAL_INVITE_CORRIDOR,

  // These are not actually onboarding steps, but special states
  // used to signal that we are still loading onboarding related
  // metadata, or onboarding is not required.
  ONBOARDING_LOADING,
  ONBOARDING_NOT_REQUIRED,
}

export default function useInitialOnboardingStep() {
  const { _ } = useLingui();
  const { savedToken, profileCompletionState } = useAuthState();
  const { userProfile, syncState: userProfileSyncState } = useUserProfile();
  if (!savedToken) {
    return OnboardingSteps.ENTRY_COMMMUNITY_TYPE;
  } else if (!userProfile || userProfileSyncState !== 'loaded') {
    return OnboardingSteps.ONBOARDING_LOADING;
  } else if (userProfile.onboardingComplete) {
    return OnboardingSteps.ONBOARDING_NOT_REQUIRED;
  } else if (profileCompletionState === 'PERMITTED_INCOMPLETE') {
    // Login key users don't need to go through any onboarding steps.
    // Technically we will remove this completion state in the future,
    // just here for type safety rn.
    return OnboardingSteps.ONBOARDING_NOT_REQUIRED;
  } else if (profileCompletionState === 'PENDING') {
    if (userProfile.userType === 'loginKey') {
      return OnboardingSteps.ONBOARDING_NOT_REQUIRED;
    } else if (userProfile.userType === 'EDUCATIONAL') {
      return OnboardingSteps.EDUCATIONAL_PROFILE_DETAILS;
    } else {
      return OnboardingSteps.PERSONAL_PROFILE_DETAILS;
    }
  } else {
    if (userProfile.userType === 'loginKey') {
      // This should not occur!
      throw Error(
        _(
          msg`Error occured in onboarding: Login key user has to be a part of community.`,
        ),
      );
    } else if (userProfile.userType === 'EDUCATIONAL') {
      if (userProfile.hasOrg) {
        return OnboardingSteps.EDUCATIONAL_CORRIDOR_SETUP;
      } else {
        return OnboardingSteps.EDUCATIONAL_ORGANIZATION_DETAILS;
      }
    } else {
      return OnboardingSteps.PERSONAL_CORRIDOR_SETUP;
    }
  }
}
