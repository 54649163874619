import { useCommunityDetails } from 'atoms/community-details';
import { currentActiveOnboardingStep } from 'components/onboarding-flow/OnboardingContainer';
import useInitialOnboardingStep, { OnboardingSteps } from 'components/onboarding-flow/useInitialOnboardingStep';
import ROUTES, { ROUTES_REVERSE } from 'constants/routes';
import { useCallback, useEffect } from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { usePrevious } from 'rooks';
import { getMixpanel } from '@cocoplatform/coco-rtc-client';

const mixpanel = getMixpanel();

export enum INTERACTIONS {
    CLICK = 'click',
    HOVER = 'hover',
    FOCUS = 'focus',
    SYSTEM = 'system', // for system events like page navigation
};

export enum EVENTS {
    BACK = 'Back',
    JOIN_COCO = 'Join_Coco',
    SIGN_IN = 'Sign_In',
    ALT_SIGN_IN = 'Alt_Sign_In',
    ALT_SIGN_UP = 'Alt_Sign_Up',
    EMAIL_INPUT = 'Email_Input',
    CONTINUE = 'Continue',
    SIGNUP_WITH_GOOGLE = 'Signup_With_Google',
    SIGNUP_WITH_MICROSOFT = 'Signup_With_Microsoft',
    LOCALE_SWITCHER = 'Locale_Switcher',
    CHANGE_LOCALE = 'Change_Locale',
    LOCALE_SWITCHER_NAVBAR = 'Locale_Switcher_Navbar',
    SIGN_IN_NAVBAR = 'Sign_In_Navbar',
    TERMS_OF_USE = 'Terms_Of_Use',
    PRIVACY_POLICY = 'Privacy_Policy',
    EMAIL_NOT_APPROVED = 'Email_Not_Approved',
    CLOSE_MODAL = 'Close_Modal',
    JOIN_INVITE_LIST = 'Join_Invite_List',
    SUBMIT_LOGIN_CODE = 'Submit_Login_Code',
    RECEIVE_NEW_LOGIN_CODE = 'Receive_New_Login_Code',
    OPEN_GMAIL = 'Open_Gmail',
    OPEN_OUTLOOK = 'Open_Outlook',
    FIRST_NAME_INPUT = 'First_Name_Input',
    LAST_NAME_INPUT = 'Last_Name_Input',
    DISPLAY_NAME_INPUT = 'Display_Name_Input',
    COUNTRY_INPUT = 'Country_Input',
    COUNTRY_INPUT_SELECT = 'Country_Input_Select',
    PASSWORD_INPUT = 'Password_Input',
    EMAIL_PREFERENCE = 'Email_Preference',
    KEEP_ME_SIGNED_IN = 'Keep_Me_Signed_In',
    NEXT = 'Next',
    ORG_NAME_INPUT = 'Org_Name_Input',
    ORG_WEBSITE_INPUT = 'Org_Website_Input',
    ORG_AGE_INPUT = 'Org_Age_Input',
    PERSONAL_AGE_INPUT = 'Personal_Age_Input',
    PERSONAL_AGE_INPUT_SELECT = 'Personal_Age_Input_Select',
    CORRIDOR_IMAGE_INPUT = 'Corridor_Image_Input',
    CORRIDOR_NAME_INPUT = 'Corridor_Name_Input',
    CORRIDOR_DESCRIPTION_INPUT = 'Corridor_Description_Input',
    EDUCATIONAL_REQUEST_ACCESS_MODAL = 'Educational_Request_Access_Modal',
    EDUCATIONAL_REQUEST_OKAY = 'Educational_Request_Okay',
    TUTORIAL_START = 'Tutorial_Start',
    TUTORIAL_NEXT = 'Tutorial_Next',
    TUTORIAL_PREVIOUS = 'Tutorial_Previous',
    TUTORIAL_SKIP = 'Tutorial_Skip',
    TUTORIAL_FINISH = 'Tutorial_Finish',
    TUTORIAL_CLOSE = 'Tutorial_Close',
    SIGN_OUT = 'Sign_Out',
    CONTINUE_WITHOUT_PASSWORD = 'Continue_Without_Password',
    FORGOT_PASSWORD = 'Forgot_Password',
    CREATE_COCO_SPACE = 'Create_Coco_Space',
    CREATE_SPACE_ADD_MEMBER_SKIP_FOR_NOW = 'Create_Space_Add_Member_Skip_For_Now',
    CREATE_SPACE_NEXT = 'Create_Space_Next',
    CREATE_COCO_SPACE_FINISH = 'Create_Coco_Space_Finish',
    UPDATE_COCO_SPACE_FINISH = 'Update_Coco_Space_Finish',
    JOIN_SPACE = 'Join_Space',
    EXPLORE_SPACE = 'Explore_Space',
    EXPLORE_JAM = 'Explore_Jam',
    JAM_CARD = 'Jam_Card',
    ADD_MEMBERS = 'Add_Members',
    IMPORT_FROM_CLASSROOM = 'Import_From_Classroom',
    INVITE_BY_EMAIL = 'Invite_By_Email',
    CREATE_STUDENT_LOGINS = 'Create_Student_Logins',
    PAGE_NAVIGATION = 'Page_Navigation',
}



export function useTrack() {
    const location = useLocation();

    const [activeOnboardingStep] = useRecoilState(currentActiveOnboardingStep);

    const { communityId } = useParams<{ communityId: string }>();
    const { community } = useCommunityDetails({
        communityId,
        autoLoad: false,
    })

    const routes = ROUTES_REVERSE[location?.pathname] || location?.pathname;

    const trackEvent = useCallback((eventName: string, interaction: INTERACTIONS = INTERACTIONS.SYSTEM, properties?: any) => {
        const props = {
            ...properties,
            environment: ENVIRONMENT,
            page: routes,
            interaction,
            onboardingStep: location.pathname === ROUTES.ONBOARDING ? activeOnboardingStep : undefined,
            ...(communityId ? {
                communityName: community?.name,
                communityId: communityId,
            } : {}),
        };
        mixpanel.track(eventName, props);
    }, [routes, activeOnboardingStep, location.pathname, community, communityId]);

    return trackEvent;
}


export function resetMixpanel() {
    mixpanel.reset();
}


export const useGlobalButtonTracking = () => {
    const location = useLocation();

    const previousLocation = usePrevious(location.pathname);

    const track = useTrack();

    const [currentOnboardingStep] = useRecoilState(currentActiveOnboardingStep);

    useEffect(() => {
        if (currentOnboardingStep !== 'ONBOARDING_LOADING' && currentOnboardingStep !== 'ONBOARDING_NOT_REQUIRED') {
            track(currentOnboardingStep, INTERACTIONS.SYSTEM, { onboardingStep: currentOnboardingStep });
        }
    }, [currentOnboardingStep]);

    useEffect(() => {
        const routes = ROUTES_REVERSE[location.pathname];
        if (location.pathname === previousLocation) return;
        track(EVENTS.PAGE_NAVIGATION, INTERACTIONS.SYSTEM, { navigatedTo: routes });
    }, [location, previousLocation, track]);

    useEffect(() => {
        const routes = ROUTES_REVERSE[location.pathname];
        // Event handler for button clicks
        const handleButtonClick = (event) => {
            const button = event.target.closest("[data-analytics]");
            if (button) {
                const label = button.getAttribute('data-analytics');

                const eventName = label.replace(/\s/g, '_');
                track(eventName,
                    INTERACTIONS.CLICK,
                    {
                        interaction: 'click',
                    });
            }
        };

        // Attach event listener
        document.addEventListener('click', handleButtonClick);

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('click', handleButtonClick);
        };
    }, [location, previousLocation, track]);
};

