import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { CSSProperties, ReactChild } from 'react';

/**
 * Light wrapper over Dialog for convenience
 */
export default function ModalDialog(p: {
  isCloseable?: boolean;
  title?: ReactChild;
  onClose?: () => void;
  children?: React.ReactChild | React.ReactChild[];
  classNames?: {
    title?: string;
    content?: string;
  };
}) {
  return (
    <Dialog open={true} onClose={p.onClose}>
      <DialogTitle className={p.classNames?.title}>
        {p.title}
        {p.isCloseable && (
          <CloseIcon
            onClick={p.onClose}
            sx={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
              cursor: 'pointer',
            }}
          />
        )}
      </DialogTitle>
      <DialogContent className={p.classNames?.content} children={p.children} />
    </Dialog>
  );
}
