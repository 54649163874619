import React, { useEffect, useState } from 'react';
import { useAuthState } from '../../utils/auth-state';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function PasscodePage(p: {
  formVisible?: boolean;
  onSuccess?: () => void;
}) {
  const { _ } = useLingui();
  const [passcode, setPasscode] = useState<string>('');
  const [message, setMessage] = useState<string | null>(null);
  const { authenticateWithPasscode } = useAuthState();

  useEffect(() => {
    import('components/welcome-page/WelcomePage');
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await authenticateWithPasscode(passcode);
      p.onSuccess?.();
    } catch (e) {
      setMessage(_(msg`Please re-check the passcode`));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(e.currentTarget.value);
  };

  return (
    <form
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: 'white',
        visibility: p.formVisible === false ? 'hidden' : 'visible',
      }}
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      <div style={{ textAlign: 'center', flexGrow: 1 }}>
        <label
          htmlFor='passcode'
          style={{
            color: 'var(--color-subtext)',
            padding: '1rem',
            display: 'block',
          }}
        >
          <Trans>Enter Passcode</Trans>
        </label>
        <input
          autoFocus
          type='password'
          id='passcode'
          onChange={handleChange}
        />
        {message && <div style={{ color: 'maroon' }}>{message}</div>}
      </div>
    </form>
  );
}
