import * as z from "zod"

export const SpaceAssetSchema = z.object({
    assetId: z.string(),
    keyPath: z.string().optional(),
    name: z.string(),
    md5ext: z.string(),
    dataFormat: z.string(),
    tags: z.string().array().nullish(),
    categories: z.string().array().nullish(),
})

export type SpaceAsset = z.TypeOf<typeof SpaceAssetSchema>

export const CostumeSchema = SpaceAssetSchema.and(
    z.object({
        bitmapResolution: z.number(),
        rotationCenterX: z.number(),
        rotationCenterY: z.number(),
    })
)
export type Costume = z.TypeOf<typeof CostumeSchema>

export const BackdropSchema = SpaceAssetSchema.and(
    z.object({
        bitmapResolution: z.number(),
        rotationCenterX: z.number(),
        rotationCenterY: z.number(),
    })
)

export type Backdrop = z.TypeOf<typeof BackdropSchema>

export const SoundSchema = SpaceAssetSchema.and(
    z.object({
        rate: z.number(),
        sampleCount: z.number(),
    })
)

export type Sound = z.TypeOf<typeof SoundSchema>

export const SpriteSchema = z.object({
    name: z.string(),
    isStage: z.boolean(),
    tags: z.string().array().nullish(),
    costumes: CostumeSchema.array().nullish(),
    sounds: SoundSchema.array().nullish(),
    blocks: z.any(),
    variables: z.any(),
    categories: z.string().array().nullish(),
})

export type Sprite = z.TypeOf<typeof SpriteSchema>
