import React, { useEffect, useRef, useState } from 'react';
import HandBlocks from './hand-send.svg';
import CuriousCards from './hand-cards.svg';
// @ts-expect-error Present
import VideoPreviewStill from './video-preview-still.webp';
import C from './VideoEmbed.css';
import { cssUrl } from 'utils/css';
import { videoUrl } from './external-links';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function VideoEmbed() {
  const { _ } = useLingui();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [bounds, setBounds] = useState<{
    height: number;
    width: number;
  } | null>(null);

  const updateBounds = () => {
    const bounds = containerRef.current?.getBoundingClientRect();
    if (!bounds) return;
    setBounds({
      width: bounds.width,
      height: bounds.width / 1.6,
    });
  };

  useEffect(() => {
    const timeout = setTimeout(updateBounds, 500);

    window.addEventListener('resize', updateBounds);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', updateBounds);
    };
  }, []);

  return (
    <div className={C.vidOuter}>
      <img
        src={HandBlocks}
        className={C.vidHandExt}
        alt={_(msg`hand holding blocks`)}
      />
      <img
        src={CuriousCards}
        className={C.vidCardsExt}
        alt={_(msg`selecting reaction cards`)}
      />
      <div className={C.vidInner}>
        <div
          className={C.vidContainer}
          ref={containerRef}
          style={{
            backgroundImage: cssUrl(VideoPreviewStill),
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 0',
            height: bounds?.height ?? `calc(90vw / 1.6)`,
          }}
        >
          <a id='video' />
          {bounds ? <VideoFrame dims={bounds} /> : null}
        </div>
      </div>
    </div>
  );
}

export const VideoFrame = (p: {
  dims?: { height?: number; width?: number };
}) => {
  const { _ } = useLingui();
  return (
    <iframe
      src={videoUrl}
      title={_(msg`Discovering CoCo`)}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      height={p.dims?.height}
      width={p.dims?.width}
      style={{ borderRadius: '6px', marginBottom: '-5px' }}
    />
  );
};
