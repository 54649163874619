// Handlers for screen capture

import { atom, clean } from "nanostores";
import { acquireAudioTrack, cleanupAudioTrack, client, localAudioTrack, muted, stopMicOutput } from "./rtc-handlers";
import { Dispatcher } from "./actions";
import { Err } from "space-lift";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { FFmpeg } from '@ffmpeg/ffmpeg';

const ffmpeg = new FFmpeg();
let loadingFfmpegPromise: Promise<boolean> | undefined = undefined;

const getAudioContext = () => typeof window !== undefined && window.AudioContext || (window as any).webkitAudioContext;

interface RecorderState {
    screenShareStream: MediaStream | undefined;
    screenshareAudioSourceNode: MediaStreamAudioSourceNode | undefined;
    audioContext: AudioContext | undefined;
    micAudioSourceNode: MediaStreamAudioSourceNode | undefined;
    audioDestination: MediaStreamAudioDestinationNode | undefined;
    currentState: 'IDLE' | 'RECORDING' | 'PAUSED' | 'STOPPED' | 'TRANSCODING'
    startTime: number | undefined;
    recorder: MediaRecorder | undefined;
    finalBlob: string;
    mp4Blob: Blob | undefined;
    timerSeconds: number | undefined;
    timerInterval?: NodeJS.Timeout;
}

export const recorderState = atom<RecorderState>({
    screenShareStream: undefined,
    audioDestination: undefined,
    audioContext: undefined,
    currentState: 'IDLE',
    startTime: undefined,
    recorder: undefined,
    finalBlob: '',
    mp4Blob: undefined,
    micAudioSourceNode: undefined,
    screenshareAudioSourceNode: undefined,
    timerSeconds: undefined,
    timerInterval: undefined,
});

let mediaChunks: Blob[] = [];
const MEDIA_RECORDER_TIMESLICE_MS = 2000;


export const checkSupported = async () => {
    if (!navigator.mediaDevices) {
        throw new Error('MediaDevices not available in your browser. You may be able to enable this in Experimental Features');
    }
    if (typeof MediaRecorder === 'undefined') {
        throw new Error('MediaRecorder not available in your browser. You may be able to enable this in Experimental Features');
    }
}

export const getScreenShareStream = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
        preferCurrentTab: true,
    } as any);

    stream.getVideoTracks()[0].addEventListener('ended', () => {
        stopRecording().catch(err => { });
    });

    recorderState.set({
        ...recorderState.get(),
        screenShareStream: stream,
    });


    return stream;
}

export const cleanup = () => {
    const state = recorderState.get();
    if (state.recorder?.state !== 'inactive') {
        state.recorder?.stop();
    }

    if (state.timerInterval) {
        clearInterval(state.timerInterval);
    }

    if (state.micAudioSourceNode) {
        state.micAudioSourceNode.disconnect();
    }

    // If we're not recording, and there is no mic producer, then we can disable the local audio track
    console.log("producer", client.micProducer);
    if (muted) {
        cleanupAudioTrack();
    }

    if (state.screenshareAudioSourceNode) {
        state.screenshareAudioSourceNode.disconnect();
    }

    state.screenShareStream?.getTracks().forEach(track => track.stop());

    mediaChunks = [];
    recorderState.set({
        ...state,
        screenShareStream: undefined,
        timerInterval: undefined,
        startTime: undefined,
        recorder: undefined,
        audioDestination: undefined,
        micAudioSourceNode: undefined,
        screenshareAudioSourceNode: undefined,
        finalBlob: state.currentState === 'IDLE' ? '' : state.finalBlob,
        mp4Blob: state.currentState === 'IDLE' ? undefined : state.mp4Blob,
    });
}

export const startMic = async () => {
    const state = recorderState.get();
    if (state.currentState !== 'RECORDING' && state.currentState !== 'PAUSED') {
        throw new Error('Not recording right now');
    }

    if (!state.audioDestination || !state.audioContext) {
        throw new Error("Audio context not available")
    }

    let micAudioStreamSource = state.micAudioSourceNode

    if (!micAudioStreamSource) {
        if (muted) {
            await acquireAudioTrack();
        }

        const audioStream = new MediaStream();
        audioStream.addTrack(localAudioTrack!);
        micAudioStreamSource = state.audioContext.createMediaStreamSource(audioStream);
    }

    micAudioStreamSource.connect(state.audioDestination);

    recorderState.set({
        ...recorderState.get(),
        micAudioSourceNode: micAudioStreamSource
    })
};

export const disableMic = async () => {
    const state = recorderState.get();

    if (!state.micAudioSourceNode) {
        throw new Error("Mic not enabled");
    }

    state.micAudioSourceNode.disconnect();

    recorderState.set({
        ...recorderState.get(),
        micAudioSourceNode: undefined,
    });
}

export const loadFfmpeg = async () => {
    if (loadingFfmpegPromise) {
        return loadingFfmpegPromise;
    }

    console.log('Loading ffmpeg');
    // const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    ffmpeg.on('log', ({ message }) => {
        console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    loadingFfmpegPromise = ffmpeg.load({
        coreURL: '/static/ffmpeg-core.js',
        wasmURL: '/static/ffmpeg-core.wasm',
    });

    return loadingFfmpegPromise;
}

export const startRecording = async (micEnabled = false) => {
    // preload ffmpeg
    loadFfmpeg().then(d => console.log('Loaded ffmpeg!')).catch(err => {
        console.error('Failed to load ffmpeg', err);
    });

    const state = recorderState.get();
    if (state.currentState == 'RECORDING') {
        return;
    }

    const screenShareStream = state.screenShareStream;
    if (!screenShareStream) {
        throw new Error('Screen share stream not available');
    }

    const startTime = Date.now().valueOf();

    const preferredOptions = { mimeType: 'video/webm;codecs=h264' };
    const backupOptions = { mimeType: 'video/webm;codecs=vp9' };
    const lastResortOptions = { mimeType: 'video/webm;codecs=vp8,opus' };
    let options = preferredOptions;

    if (typeof MediaRecorder.isTypeSupported === 'function') {
        if (!MediaRecorder.isTypeSupported(preferredOptions.mimeType)) {
            options = backupOptions;
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                options = lastResortOptions;
            }
        }
    }

    mediaChunks = [];

    let micAudioStreamSource: MediaStreamAudioSourceNode | undefined;
    let screenshareAudioStreamSource: MediaStreamAudioSourceNode | undefined;

    const AudioContext = getAudioContext();

    const audioContext = new AudioContext() as AudioContext;
    const audioDestination = audioContext.createMediaStreamDestination();


    const screenshareAudioTrack = screenShareStream.getAudioTracks()[0];
    if (screenshareAudioTrack) {
        const audioStream = new MediaStream();
        audioStream.addTrack(screenshareAudioTrack);
        screenshareAudioStreamSource = audioContext.createMediaStreamSource(audioStream);
        screenshareAudioStreamSource.connect(audioDestination);
    }

    if (micEnabled) {
        if (!localAudioTrack) {
            await acquireAudioTrack();
        }
        const audioStream = new MediaStream();
        audioStream.addTrack(localAudioTrack!);
        micAudioStreamSource = audioContext.createMediaStreamSource(audioStream);
        micAudioStreamSource.connect(audioDestination);
    }

    const recorder = new MediaRecorder(new MediaStream([
        screenShareStream.getVideoTracks()[0],
        audioDestination.stream.getAudioTracks()[0],
    ]), options);

    recorder.start(MEDIA_RECORDER_TIMESLICE_MS);

    recorder.ondataavailable = (event) => {
        mediaChunks.push(event.data);
    }

    recorder.onstop = async () => {
        const blob = new Blob(mediaChunks, { type: 'video/webm;codecs=h264' });
        const state = recorderState.get();

        if (state.timerInterval) {
            clearInterval(state.timerInterval);
        }

        recorderState.set({
            ...recorderState.get(),
            currentState: 'TRANSCODING',
            timerInterval: undefined,
        });

        // This time, we need to await the ffmpeg load
        await loadFfmpeg();

        await ffmpeg.writeFile('input.webm', await fetchFile(blob));
        await ffmpeg.exec(['-i', 'input.webm', '-c:v', 'copy', '-c:a', 'copy', 'output.mp4']);
        const data = await ffmpeg.readFile('output.mp4');
        const mp4Blob = new Blob([(data as Uint8Array).buffer], { type: 'video/mp4' })

        recorderState.set({
            ...recorderState.get(),
            currentState: 'STOPPED',
            finalBlob: await URL.createObjectURL(mp4Blob),
            mp4Blob,
        });

        Dispatcher.openCaptureScreenModal("RECORD_SPACE");
        cleanup();
    }

    recorderState.set({
        ...state,
        currentState: 'RECORDING',
        timerSeconds: 300,
        startTime,
        recorder,
        micAudioSourceNode: micAudioStreamSource,
        screenshareAudioSourceNode: screenshareAudioStreamSource,
        audioDestination,
        audioContext,
        timerInterval: createTimerInterval(),
    });
}

const createTimerInterval = () => {
    return setInterval(() => {
        const state = recorderState.get();
        if (state.timerSeconds) {
            recorderState.set({
                ...state,
                timerSeconds: state.timerSeconds - 1,
            });
        } else {
            clearInterval(state.timerInterval!);
            recorderState.set({
                ...state,
                timerInterval: undefined,
            });
            stopRecording().catch(err => { });
        }
    }, 1000);
}


export const pauseRecording = async () => {
    const state = recorderState.get();

    if (state.currentState !== 'RECORDING' || !state.recorder) {
        throw Error("Not recording right now!");
    }

    if (state.timerInterval) {
        clearInterval(state.timerInterval);
    }


    state.recorder.pause();

    recorderState.set({
        ...state,
        timerInterval: undefined,
        currentState: 'PAUSED',
    });
}

export const resumeRecording = async () => {
    const state = recorderState.get();

    if (state.currentState !== 'PAUSED' || !state.recorder) {
        throw Error("Not paused right now!");
    }

    state.recorder.resume();

    recorderState.set({
        ...state,
        timerInterval: createTimerInterval(),
        currentState: 'RECORDING',
    });
}

export const stopRecording = async () => {
    const state = recorderState.get();

    if (!['PAUSED', 'RECORDING'].includes(state.currentState) || !state.recorder) {
        throw Error("Not recording right now!");
    }

    state.recorder.stop();
    // should automatically call on stop handler
}

export const resetRecorder = async () => {
    const state = recorderState.get();
    if (state.recorder) {
        state.recorder.onstop = null;
    }

    recorderState.set({
        ...state,
        timerInterval: undefined,
        currentState: 'IDLE',
    })
    cleanup();
};
