/* eslint-disable lingui/no-unlocalized-strings */
export const onReady = (cb: () => void) => {
  if (document.readyState === 'complete') {
    cb();
  } else {
    document.addEventListener('DOMContentLoaded', cb);
  }
};

export const enforeHttps = () => {
  if (location.protocol !== 'https:' && !location.host.match(/^localhost/)) {
    location.href = location.href.replace('http:', 'https:');
    return true;
  }
  return false;
};

export const isJSDOM = () => {
  return (
    navigator.userAgent.includes('Node.js') ||
    navigator.userAgent.includes('jsdom')
  );
};
