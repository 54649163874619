import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import CocoModal from 'components/coco-modal/coco-modal';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledTextField from 'components/forms/ControlledTextField';
import React, { useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { reportServerError } from 'utils/report-error';
import PlatformInfoFieldSet from 'components/PlatformInfoFieldSet/PlatformInfoFieldSet';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';
import GlobeIcon from '!!react-svg-loader!../locale-switcher/icon--globe.svg';
import GoogleTranslateLogo from './Google_Translate-min.png';
import C from './language-contribute-modal.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function setLanguageContributeModalDismissed() {
  localStorage.setItem('language_contribute_modal_dismissed', '1');
}

export function isLanguageContributeModalDismissed() {
  return localStorage.getItem('language_contribute_modal_dismissed') === '1';
}

export default function LanguageContributeModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const { _ } = useLingui();

  return (
    <CocoModal
      onClose={() => {
        setLanguageContributeModalDismissed();
        onClose();
      }}
      header={
        <Stack direction='row' alignItems='center'>
          <GlobeIcon
            style={{
              width: '30px',
              height: '30px',
              marginRight: '0.5rem',
              display: 'block',
            }}
          />
          {_(msg`Help us improve translations!`)}
        </Stack>
      }
    >
      <Stack px={6} py={4} minWidth={400}>
        <img
          style={{
            alignSelf: 'center',
          }}
          src={GoogleTranslateLogo}
          width='50px'
          alt='Google Translate Logo'
        />
        <Typography mt={4} variant='body1' textAlign='justify'>
          <Trans>
            Translations for this language on CoCo were generated using
            <br />
            <strong>Google Translate</strong> and may not be fully accurate.
            We'd love your help in making them better. If you'd like to
            contribute, drop us an email on{' '}
            <a
              style={{
                color: 'var(--color-primary)',
                fontWeight: 'var(--font-weight-bold)',
              }}
              className={C.mutedSubtext}
              href='mailto:hello@coco.build'
            >
              hello@coco.build
            </a>
            . Thank you for your support!
          </Trans>
        </Typography>
        <Stack justifyContent='end' direction='row' mt={1} spacing={2}>
          <Button
            onClick={() => {
              setLanguageContributeModalDismissed();
              onClose();
            }}
            variant='contained'
            color='primary'
          >
            <Trans>Dismiss</Trans>
          </Button>
        </Stack>
      </Stack>
    </CocoModal>
  );
}
