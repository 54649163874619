import { z } from "zod"
import { SpaceType, SpaceTypeSchema } from "../spaces/types"
import { UserSchema } from "../users/types"
import { FileUploadSchema } from "../../utils/file-upload"

export const CommunityParticipationPolicySchema = z.enum([
    "open",
    "org_email_restricted",
    "invite_only",
    "username_only",
])
export type CommunityParticipationPolicy = z.TypeOf<
    typeof CommunityParticipationPolicySchema
>

export type CommunityRecommendationPolicy = z.TypeOf<
    typeof CommunityRecommendationPolicySchema
>

export const CommunityRecommendationPolicySchema = z.enum([
    "internal",
    "org_email",
])

export const CommunitySettingsSchema = z
    .object({
        enabledSpaceTypes: SpaceTypeSchema.array()
            .nullish()
            .default((): SpaceType[] => ["scratch_blocks"]),
    })
    .nullish()

export type CommunitySettings = z.TypeOf<typeof CommunitySettingsSchema>

export const CommunityUserSchema = UserSchema.and(
    z.object({
        loginAllowanceId: z.string().nullish(),
        username: z.string().nullish(),
        password: z.string().nullish(),
    })
)

export type CommunityUser = z.TypeOf<typeof CommunityUserSchema>

export const CommunityMemberSchema = z.object({
    isSelf: z.boolean(),
    isAccepted: z.boolean(),
    isApproved: z.boolean(),
    isBlocked: z.boolean(),
    isReported: z.boolean(),
    googleClassrooms: z.array(z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
        section: z.string().nullish(),
    })).optional().nullish(),
    user: CommunityUserSchema.nullish(),
    email: z.string().nullish(),
    roles: z.string().array(),
    pendingRoles: z.string().array(),
    invitationCodeId: z.string().nullish(),
    invitor: UserSchema.nullish(),
    requestMessage: z.string().nullish(),
})

export type CommunityMember = z.TypeOf<typeof CommunityMemberSchema>

export const CommunityUserGroupInputSchema = z.object({
    name: z.string(),
    userIds: z.string().array(),
})

export const CommunityUserGroupSchema = z.object({
    id: z.string(),
    name: z.string(),
    userIds: z.string().array(),
    googleClassroom: z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
        section: z.string().nullish(),
    }).optional().nullish(), 
})

export type CommunityUserGroup = z.TypeOf<typeof CommunityUserGroupSchema>

export const CommunitySchema = z.object({
    id: z.string(),
    name: z.string().nullable().nullish(),
    color: z.string().nullish(),
    bannerUrl: z.string().nullish(),
    avatarUrl: z.string().nullish(),
    description: z.string().nullable().nullish(),
    participationPolicy: z.string().nullish(),
    adhocSpaceCreationPolicy: z.string().nullish(),
    owners: z.array(UserSchema).nullish(),
    isActive: z.boolean()
})

export type Community = z.TypeOf<typeof CommunitySchema>

export const InvitationCodeSchema = z.object({
    code: z.string(),
    activatedAtSSE: z.number().nullish(),
    deactivatedAtSSE: z.number().nullish(),
    emails: z.array(z.string()).nullish(),
    emailDomains: z.array(z.string()).nullish(),
})

export type InvitationCode = z.TypeOf<typeof InvitationCodeSchema>


export const InvitationCodeTargetSchema = z.object({
    community: CommunitySchema.optional(),
})

export type InvitationCodeTarget = z.TypeOf<typeof InvitationCodeTargetSchema>

export const InvitationCodeValidationResultSchema = z.object({
    isValid: z.boolean(),
    isAvailable: z.boolean(),
    isPendingApproval: z.boolean(),
    hasLoginKeys: z.boolean(),
    invited: z.boolean(),
    creator: UserSchema.nullish(),
    target: InvitationCodeTargetSchema.nullish(),
    isGoogleClassroom: z.boolean().optional().nullish(),
})

export type InvitationCodeValidationResult = z.TypeOf<
    typeof InvitationCodeValidationResultSchema
>

export const CommunityDetailsSchema = z.object({
    id: z.string(),
    isActive: z.boolean(),
    name: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    roles: z.string().array(),
    pendingRoles: z.string().array(),
    isAgeRestricted: z.boolean().optional(),
    genericInvitationCode: InvitationCodeSchema.nullish(),
    requiresApproval: z.boolean().optional(),
    pendingApproval: z.boolean().optional(),
    participationPolicy: z.string().nullish(),
    adhocSpaceCreationPolicy: z.string().nullish(),
    recommendationPolicy: z.string().nullish(),
    members: z.array(CommunityMemberSchema).nullish(),
    googleClassrooms: z.array(z.object({
        id: z.string(),
        name: z.string().optional().nullish(),
        section: z.string().optional().nullish(),
    })).nullish(),
    groups: CommunityUserGroupSchema.array(),
    color: z.string().nullish(),
    avatarUrl: z.string().nullish(),
    bannerUrl: z.string().nullish(),
    totalSpaceCount: z.number().nullish(),
    coCreatorCount: z.number().nullish(),
    deactivatedAt: z.number().nullish(),
    settings: CommunitySettingsSchema,
    videoAllowed: z.boolean().optional(),
    audioAllowed: z.boolean().optional(),
    collabAllowed: z.boolean().optional(),
    crossGroupCollabAllowed: z.boolean().optional(),
    recordingAllowed: z.boolean().optional(),
    corridorType: z.string().optional(),
    educationalAccessApproved: z.boolean().optional(),
    videoAccessApproved: z.boolean().optional(),
    personalVideoAccessRequested: z.boolean().optional(),
})

export type CommunityDetails = z.TypeOf<typeof CommunityDetailsSchema>

export const CommunityWithAggregatesSchema = CommunitySchema.and(
    z.object({
        spaceCount: z.number(),
        coCreatorCount: z.number(),
        currentUserRoles: z.string().array(),
        genericInvitationCode: z.string().optional(),
        invited: z.boolean().optional(),
    })
)

export type CommunityWithAggregates = z.TypeOf<
    typeof CommunityWithAggregatesSchema
>

export const CommunityRecommendationResultsSchema = z.object({
    communities: z.array(CommunitySchema),
})

export type CommunityRecommendationResults = z.TypeOf<
    typeof CommunityRecommendationResultsSchema
>

export const CommunityJoinRequestResultSchema = z.object({
    pendingApproval: z.boolean(),
    communityId: z.string(),
    roles: z.string().array(),
})

export const CommunityInputSchema = z.object({
    name: z.string().optional(),
    description: z.string().optional(),
    targetNames: z.string().array().nullish(),
    organizationId: z.string().optional(),
    participationPolicy: z.string().optional(),
    recommendationPolicy: z.string().optional(),
    adhocSpaceCreationPolicy: z.string().optional(),
    avatar: FileUploadSchema.optional(),
    settings: CommunitySettingsSchema.nullish(),
    banner: FileUploadSchema.optional(),
    message: z.string().optional(),
    videoAllowed: z.boolean().optional(),
    audioAllowed: z.boolean().optional(),
    collabAllowed: z.boolean().optional(),
    crossGroupCollabAllowed: z.boolean().optional(),
    recordingAllowed: z.boolean().optional(),
})

export type CommunityInput = z.TypeOf<typeof CommunityInputSchema>

export const IntervalUnitSchema = z
    .literal("minutes")
    .or(z.literal("hours"))
    .or(z.literal("days"))
    .or(z.literal("weeks"))

export const IntervalSchema = z.object({
    unit: IntervalUnitSchema,
    count: z.number(),
})

export type Interval = z.TypeOf<typeof IntervalSchema>

export const CommunityEmailSettingsInputSchema = z.object({
    communityId: z.string(),
    userId: z.string(),
    roles: z.string().array().nullish(),
    emailsOrDomains: z.array(z.string()).nullish(),
})

export type CommunityEmailSettingsInput = z.TypeOf<
    typeof CommunityEmailSettingsInputSchema
>

export const GoogleClassroomInviteInput = z.object({
    userId: z.string(),
    communityId: z.string(),
    classrooms: z.array(z.object({
        id: z.string(),
    })),
    emails: z.array(z.object({
        email: z.string(),
        role: z.string(),
        classroomId: z.string(),
        type: z.enum(["STUDENT", "TEACHER"]),
    })),
    classroomMetadata: z.record(
        z.string(),
        z.object({
            id: z.string(),
            name: z.string(),
            section: z.string(),
        })
    )
})

export type GoogleClassroomInviteInput = z.TypeOf<
    typeof GoogleClassroomInviteInput
>


export const SendInvitationEmailInputSchema = z.object({
    communityId: z.string(),
    userId: z.string(),
    invitationIDs: z.string().array(),
})

export type SendInvitationEmailInput = z.TypeOf<
    typeof SendInvitationEmailInputSchema
>

export const CommunityLinkSettingsInputSchema = z.object({
    communityId: z.string(),
    userId: z.string(),
    allowOnlyApproved: z.boolean().optional(),
    expiry: IntervalSchema.optional().or(z.boolean()),
})

export type CommunityLinkSettingsInput = z.TypeOf<
    typeof CommunityLinkSettingsInputSchema
>

export const CommunityTargetSchema = z.object({
    id: z.string(),
    name: z.string(),
    isOrg: z.boolean().nullish(),
})

export type CommunityTarget = z.TypeOf<typeof CommunityTargetSchema>
