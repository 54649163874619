import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Fade, Stack, Typography } from '@mui/material';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import PrivacyFooter from 'components/PrivacyFooter/PrivacyFooter';
import CodeForm from 'components/code-form/CodeForm';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledTextField from 'components/forms/ControlledTextField';
import SocialLoginButtons from 'components/social-login-buttons/SocialLoginButtons';
import qs from 'query-string';

import ROUTES from 'constants/routes';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { reportServerError } from 'utils/report-error';
import { EMAIL_REGEX } from 'utils/validation';
import { useAuthState } from 'utils/auth-state';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import LocaleSwitcher from 'components/locale-switcher/LocaleSwitcher';
import { EVENTS, INTERACTIONS, properties, useTrack } from 'utils/mixpanel';

export default function EducationalOnboardingSignUp() {
  const { _ } = useLingui();
  const { email } = useQueryParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm(
    email
      ? {
          defaultValues: { email },
        }
      : undefined,
  );
  const [isDispatching, setDispatching] = useState(false);
  const [dispatched, setDispatched] = useState(false);

  const { saveToken } = useAuthState();

  const history = useHistory();

  const track = useTrack();

  async function submit(data: any) {
    setDispatching(true);
    const res = await submitCode(data.email);
    setDispatching(false);

    if (res?.success) {
      setDispatched(true);
    }
  }

  if (dispatched) {
    return (
      <CodeForm
        email={getValues('email')}
        onSuccess={() => {
          history.push(ROUTES.ONBOARDING_EDUCATIONAL_PROFILE_DETAILS);
        }}
        userType='EDUCATIONAL'
      />
    );
  }

  return (
    <Fade in>
      <Stack
        mt={4}
        component='form'
        onSubmit={handleSubmit(submit)}
        alignItems='center'
        sx={{ textAlign: 'center' }}
      >
        <Typography variant='h1'>
          <Trans>Sign up with email</Trans>
        </Typography>
        <Typography variant='subtitle1' mt={3}>
          <Trans>
            We suggest signing up with the official email address
            <br />
            you use at your <strong>school / organization.</strong>
          </Trans>
        </Typography>

        <Box mt={4} />
        <ControlledTextField
          variant='outlined'
          size='small'
          name='email'
          id='email_control'
          placeholder='name@org-email.com'
          required
          rules={{
            required: true,
            pattern: EMAIL_REGEX,
          }}
          helperText={
            errors.email ? _(msg`Please enter a valid email`) : undefined
          }
          error={!!errors.email}
          inputProps={{
            onFocus: () => {
              track(EVENTS.EMAIL_INPUT, INTERACTIONS.FOCUS);
            },
          }}
          {...{ control }}
        />
        <LoadingButton
          type='submit'
          loadingPosition='end'
          variant='contained'
          sx={{ mt: 2 }}
          loading={isDispatching}
          fullWidth
          onClick={() => {
            track(EVENTS.CONTINUE, INTERACTIONS.CLICK, {
              email: getValues('email'),
            });
          }}
        >
          <strong>
            <Trans>Continue</Trans>
          </strong>
        </LoadingButton>

        <Box mt={2} />

        <Divider sx={{ width: '100%' }}>
          <Trans>or</Trans>
        </Divider>
        <Box mt={2} mb={4} width='100%'>
          <SocialLoginButtons
            signUp
            onSuccess={async ({ accessToken, provider }) => {
              try {
                const { data } = await httpClient.get(
                  `/auth/oauth/verification?${qs.stringify({
                    accessToken,
                    provider,
                    userType: 'EDUCATIONAL',
                  })}`,
                );
                saveToken(data.token, true);
              } catch (err) {
                console.error(err);
              }
            }}
          />
        </Box>
        <PrivacyFooter />
        <div style={{ marginTop: 24 }}>
          <LocaleSwitcher />
        </div>
      </Stack>
    </Fade>
  );
}

export async function submitCode(email: string) {
  try {
    const res = await httpClient.post('/auth/login-code', {
      email,
    });

    if (res?.data?.success) {
      return res.data;
    }
  } catch (error: any) {
    const response = error.response;

    if (
      response?.data.error === 'Your account has not yet been approved' ||
      response?.data.error === "This email hasn't been approved for use."
    ) {
      return;
    }

    reportServerError({
      title: t`Failed to verify login code`,
      error,
    });
  }
}
