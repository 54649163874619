import * as z from "zod"
import { FileUploadSchema } from "../../utils/file-upload"

export const SurveyInputSchema =
    z.object({
        surveyName: z.string(),
        version: z.coerce.number(),
        submission: z.any(),
        ip: z.string().nullish(),
        userId: z.string().nullish(),
        geoIpLookup: z.any().nullish(),
        cocoSessionId: z.string().nullish(),
        files: z.record(FileUploadSchema.array()).nullish()
    })

export const SurveySummarySchema =
    z.object({
        name: z.string(),
        versions: z.number().array(),
    })

export const SurveySummaryResultSchema =
    z.object({
        surveys: SurveySummarySchema.array()
    })

export const SurveySubmissionSchema = 
    z.object({
        submission: z.any(),
        ip: z.string().nullish(),
        country: z.string().nullish(),
        region: z.string().nullish(),
        geoIpLookup: z.any().nullish(),
        cocoSessionId: z.string().nullish(),
    })

export const SurveySubmissionsRequestSchema =
    z.object({
        name: z.string(),
        version: z.coerce.number(),
        offset: z.number().nullish()
    })

export const SurveySubmissionsResultSchema =
    z.object({
        offset: z.number(),
        count: z.number().nullish(),
        submissions: SurveySubmissionSchema.array()
    })
