import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import React from 'react';
import C from './PrivacyFooter.css';
import { EVENTS } from 'utils/mixpanel';

export default function PrivacyFooter() {
  return (
    <Typography variant='caption'>
      <Trans>
        By signing up, you agree with CoCo's{' '}
        <a
          data-analytics={EVENTS.TERMS_OF_USE}
          target='_blank'
          href='/terms-of-use'
          className={C.coloredLink}
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          data-analytics={EVENTS.PRIVACY_POLICY}
          target='_blank'
          href='/privacy-terms'
          className={C.coloredLink}
        >
          Privacy Policy
        </a>
        .
      </Trans>
    </Typography>
  );
}
