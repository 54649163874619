export default class BackoffTracker {
    private currentDelays = new Map<string, number>()

    constructor(private initialDelay = 1000, private incFactor = 2) {}

    getNextDelay(scope: string, id: string) {
        const key = this.getKey(scope, id)
        const lastDelay = this.currentDelays.get(key) ?? this.initialDelay
        const nextDelay = lastDelay * this.incFactor + this.getJitter()
        this.currentDelays.set(key, nextDelay)
        return lastDelay
    }

    async waitNextDelay(scope: string, id: string) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(undefined)
            }, this.getNextDelay(scope, id))
        })
    }

    resetDelay(scope: string, id: string) {
        this.currentDelays.delete(this.getKey(scope, id))
    }

    private getKey(scope: string, id: string) {
        return `${scope}:${id}`
    }

    private getJitter() {
        return 1000 * Math.random()
    }
}
