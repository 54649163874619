import * as z from "zod"

export const UserSchema = z.object({
    id: z.string(),
    username: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    avatarUrl: z.string().nullish(),

    /* @deprecated */
    email: z.string().optional(),

    emails: z
        .array(
            z.object({
                email: z.string(),
            })
        )
        .optional(),
})

export type User = z.TypeOf<typeof UserSchema>

export const LoginAllowanceSchema = z.object({
    id: z.string(),
    user: UserSchema.and(z.object({
        password: z.string().nullish(),
        groups: z.string().array().nullish()
    })),
})

export type LoginAllowance = z.TypeOf<typeof LoginAllowanceSchema>
