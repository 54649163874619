import React, { useEffect, useState } from 'react';
import MailIcon from './mail-2.svg';
import C from './HomePage.css';
import cc from 'classcat';
import TwitterIcon from './twitter.svg';
import LinkedinIcon from './linkedin.svg';
import HandExt from '../home-page/hand-get.svg';
import { linkedinUrl, twitterUrl } from './external-links';
import mitPic from './mit-pic.png';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function NoteForm() {
  const { _ } = useLingui();
  // Workaround for a style hydration issue
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <div className={C.socialLinksOuter}>
        <div className={C.socialLinks}>
          <a
            className={cc([C.btn, C.socialBtn])}
            href='mailto:hello@coco.build'
          >
            <img src={MailIcon} alt={_(msg`mail icon`)} />
            <Trans>Email</Trans>
          </a>
          <a
            className={cc([C.btn, C.socialBtn])}
            href={linkedinUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={LinkedinIcon} alt={_(msg`linkedin icon`)} />
            <Trans>LinkedIn</Trans>
          </a>
          <a
            className={cc([C.btn, C.socialBtn])}
            href={twitterUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={TwitterIcon} alt={_(msg`twitter icon`)} />
            <Trans>Twitter</Trans>
          </a>
        </div>
      </div>
      <div className={C.noteTAreaOuter}>
        {mounted && (
          <div className={C.noteTAreaInner}>
            <img src={mitPic} className={C.mitPic} alt='mit pic' />
            <Trans>
              <span className={C.firstLetter}>👋 Hi</span>, we are MASH (
              <a
                className={C.ulink}
                href={'https://www.linkedin.com/in/manujdhariwal/'}
                target={'_blank'}
              >
                Manuj Dhariwal
              </a>{' '}
              +{' '}
              <a
                className={C.ulink}
                href={'https://www.linkedin.com/in/shrutidhariwal/'}
                target={'_blank'}
              >
                Shruti Dhariwal
              </a>
              ). We're both currently PhD candidates at MIT Media Lab in the
              Lifelong Kindergarten group. We've been recipients of LEGO Papert
              Fellowship for our research at the intersection of creativity,
              learning, play, and new technologies, and have been working
              together across these areas for over a decade (
              <a
                className={C.ulink}
                href={
                  'https://news.mit.edu/2023/coco-real-time-co-creative-learning-platform-young-people-0530'
                }
                target={'_blank'}
              >
                <strong>MIT News</strong>
              </a>
              ).
              <p>
                We began conceptualizing and developing the ideas of CoCo back
                in 2019, early on in our PhD. We've always believed that the
                core values underlying CoCo—
                <span
                  style={{
                    color: '#FAABF4',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  beingness
                </span>
                ,{' '}
                <span
                  style={{
                    color: '#FFC100',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  creativeness
                </span>
                , and{' '}
                <span
                  style={{
                    color: '#76E2B1',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  togetherness
                </span>
                —are <span style={{ fontStyle: 'italic' }}>timeless</span> in
                essence but they have also become especially{' '}
                <span style={{ fontStyle: 'italic' }}>timely</span>.
              </p>
              <p>
                We feel deeply committed towards cultivating these values and
                making CoCo available for communities of young people and
                educators everywhere. Currently, we are a tiny team of three,
                with <span style={{ fontStyle: 'italic' }}>Rishit Bansal</span>{' '}
                joining us since last year, bringing his software engineering
                skills and expertise to the CoCo platform. If you’re a kindred
                spirit who resonates with these ideas and would like to help
                support this work in any way, please send us a note. We’ll be
                waiting to get a{' '}
                <span style={{ fontStyle: 'italic' }}>signal</span> from you :)
              </p>
              <p>
                Here’s to{' '}
                <span
                  style={{
                    color: '#FAABF4',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  being.
                </span>{' '}
                <span
                  style={{
                    color: '#FFC100',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  creative.
                </span>{' '}
                <span
                  style={{
                    color: '#76E2B1',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  together.
                </span>
                — always, but especially in the{' '}
                <span style={{ fontStyle: 'italic' }}>era of AI</span>.
              </p>
            </Trans>
          </div>
        )}
        <img
          src={HandExt}
          className={C.handDownExt}
          alt={_(msg`hand holding block`)}
        />
      </div>
    </>
  );
}
