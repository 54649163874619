import React, { Fragment, useEffect, useRef, useState } from 'react';
import C from './AppLogoDropdown.css';
import cc from 'classcat';
import LogoBeta from '!!react-svg-loader!./logo-beta.svg';
import Tooltip from '@mui/material/Tooltip';
import { cocoSessionId } from '../../utils/auto-session';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const coWords = [
  'Connection',
  'Construction',
  'Co-creation',
  'Compassion',
  'Communities',
  'Co-coding',
  'Collaboration',
  'Corridors',
];

export default function AppLogoDropdown() {
  const { _ } = useLingui();
  const [menuState, setMenuState] = useState('collapsed');
  const [inputPhrase, setInputPhrase] = useState('');
  const [inputBkgPhrase, setInputBkgPhrase] = useState('');
  const [hasFocus, setFocus] = useState(false);
  const [tooltip, setTooltip] = useState<string | null>(null);

  useEffect(() => {
    if (inputBkgPhrase && inputPhrase) {
      setInputBkgPhrase('');
    }
  }, [inputPhrase]);

  useEffect(() => {
    if (tooltip) {
      setTimeout(() => {
        setTooltip(null);
      }, 1000);
    }
  }, [tooltip]);

  useEffect(() => {
    for (const [currentState, eventualState] of [
      ['expanding', 'expanded'],
      ['collapsing', 'collapsed'],
    ]) {
      if (menuState === currentState) {
        const timer = setTimeout(() => {
          setMenuState((s) => {
            if (s === currentState) {
              return eventualState;
            }
            return s;
          });
        }, 1000);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [menuState]);

  useEffect(() => {
    if (menuState === 'expanding' || menuState === 'expanded') {
      const collapseOnOutsideClick = (e: MouseEvent) => {
        if (!e.target) return;

        if (inputRef.current === e.target) return;
        setMenuState((s) => {
          if (s === 'expanding' || s === 'expanded') return 'collapsing';
          return s;
        });
      };
      const collapseTimer = setTimeout(() => {
        document.body.addEventListener('click', collapseOnOutsideClick);
      }, 500);
      return () => {
        clearTimeout(collapseTimer);
        document.body.removeEventListener('click', collapseOnOutsideClick);
      };
    }
  }, [menuState]);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={C.container} ref={containerRef}>
      <LogoBeta
        className={C.appLogo}
        style={{
          filter: 'drop-shadow(0 2px 2px 4px rgba(0,0,0,0.5))',
        }}
      />
      <div className={C.menuOuter} style={{ width: 190 }}>
        <div className={C.menuInner}>
          <div
            className={cc([C.menuTrigger, C[menuState]])}
            onMouseEnter={() => setMenuState('expanding')}
            onMouseLeave={() => {
              if (!hasFocus) {
                setMenuState('collapsing');
              }
            }}
          >
            {menuState !== 'collapsed' ? (
              <>
                <ul className={C.menuExp}>
                  {coWords.map((c, idx) => (
                    <li>
                      {idx === coWords.length - 1 && <Trans>and...</Trans>}
                      <HighlightedPhrase pharse={c} highlight={'Co'} />
                    </li>
                  ))}
                </ul>
                <label>
                  <Trans>
                    Got other nice <strong>Co</strong> words?
                  </Trans>
                </label>
                <div className={C.inputContainer}>
                  <div
                    className={cc({
                      [C.inputBkg]: true,
                      [C.populated]: inputBkgPhrase,
                    })}
                  >
                    {inputBkgPhrase}
                  </div>
                  <Tooltip open={!!tooltip} title={tooltip!}>
                    <input
                      type='text'
                      className={C.input}
                      ref={inputRef}
                      value={inputPhrase}
                      onChange={(e) => {
                        setTooltip(null);
                        setInputPhrase(e.target.value);
                      }}
                      onFocus={() => {
                        setFocus(true);
                      }}
                      onBlur={() => {
                        setFocus(false);
                        setMenuState('collapsing');
                      }}
                      onKeyDown={async (e) => {
                        if (e.key !== 'Enter') return;
                        if (!inputPhrase.toLowerCase().startsWith('co')) {
                          setTooltip(_(msg`Hmm... you sure about that?`));
                          return;
                        }
                        try {
                          setInputPhrase('');
                          setInputBkgPhrase(inputPhrase);
                          await httpClient.post(
                            `/survey/co-word-suggestion/0/submission`,
                            {
                              inputPhrase,
                              cocoSessionId,
                            },
                          );
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    />
                  </Tooltip>
                  <span className={C.inputArrow}>↳</span>
                </div>
              </>
            ) : (
              <div onClick={toggleExpanded}>
                <HighlightedPhrase
                  pharse={_(msg`Connection + Construction`)}
                  highlight={_(msg`Co`)}
                />
              </div>
            )}
          </div>
          <div className={C.menuArrowContainer} onClick={toggleExpanded}>
            <div
              className={cc({
                [C.menuArrow]: true,
                [C.flipped]:
                  menuState === 'expanded' || menuState === 'expanding',
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );

  function toggleExpanded() {
    setMenuState((s) => {
      if (s === 'collapsed' || s === 'collapsing') {
        return 'expanding';
      } else {
        return 'collapsing';
      }
    });
  }
}

const HighlightedPhrase = (p: { pharse: string; highlight: string }) => {
  return (
    <>
      {p.pharse.split(p.highlight).map((it, idx) => (
        <Fragment key={`${it}-${idx}`}>
          {idx > 0 && <strong>{p.highlight}</strong>}
          {it}
        </Fragment>
      ))}
    </>
  );
};
