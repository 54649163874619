import { Trans } from '@lingui/macro';
import { Box, Divider, Fade, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useCommunityDetails } from 'atoms/community-details';
import { useCommunitySummaries } from 'atoms/community-summaries';
import { useUserProfile } from 'atoms/user-profile';
import CommunityInfoForm from 'components/community-info-form/CommunityInfoForm';
import OnboardingStepper from 'components/onboarding-flow/OnboardingStepper';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import ROUTES from 'constants/routes';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export default function PersonalOnboardingCorridorSetup(p: {
  postOnboarding?: boolean;
}) {
  const { control } = useForm();

  const { fetchUserProfile } = useUserProfile();
  const { communities, syncState, trigger } = useCommunitySummaries({
    autoLoad: false,
  });
  const history = useHistory();
  const { startGettingStartedTutorial } = useTutorial();

  return (
    <Fade in>
      <Stack alignItems='center'>
        {!p.postOnboarding && (
          <>
            <Typography variant='h1' mt={4} textAlign='center'>
              <Trans>Set up your corridor</Trans>
            </Typography>
            <Box mt={4} />
          </>
        )}
        <Typography variant='subtitle1' textAlign='center'>
          <Trans>A corridor is a safe, invite-only community of a</Trans>
          <br />
          <Trans>
            group of friends{' '}
            <strong>
              <em>being creative together!</em>
            </strong>
          </Trans>
        </Typography>
        {!p.postOnboarding && (
          <>
            <Box mt={2} />
            <Divider
              sx={{
                width: '100%',
              }}
            />
          </>
        )}
        <Box mt={4} />
        <CommunityInfoForm
          onSuccess={async ({ id }) => {
            await trigger();
            await fetchUserProfile();
            history.push(`/app/dashboard/${id}`);

            if (p.postOnboarding) {
              return;
            }
          }}
          type='PERSONAL'
        />
        <Box mt={2} />
        {!p.postOnboarding && (
          <OnboardingStepper totalSteps={2} activeStep={1} />
        )}
      </Stack>
    </Fade>
  );
}
