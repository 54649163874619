import memoizeOne from 'memoize-one';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { Maybe } from './types';

const parseQueryParams = memoizeOne((query) => {
  return qs.parse(query);
});

export const getQueryParams = () => {
  const [, queryString] = location.search.split('?');
  if (!queryString) return {};
  return parseQueryParams(queryString);
};

export const useSearchParam = (
  name: string,
  defaultVal?: string,
): [Maybe<string>, (val: Maybe<string>) => void] => {
  const qParams = getQueryParams();
  const history = useHistory();
  const setQParam = (value: string | undefined | null) => {
    const parsedQ = qs.parse(location.search.slice(1));
    parsedQ[name] = value ?? null;
    history.push(`${location.pathname}?${qs.stringify(parsedQ)}`);
  };
  return [(qParams[name] ?? defaultVal) as Maybe<string>, setQParam];
};

export const updateQueryParams = (
  queryParams: Record<string, string | (string | null)[] | null | undefined>,
  merge = true,
) => {
  if (merge) {
    queryParams = { ...getQueryParams(), ...queryParams };
  }
  location.search = '?' + qs.stringify(queryParams);
};

export const initialQueryParams = getQueryParams();

export const PROPAGATED_QUERY_PARAMS: string[] = [];

export const PROPAGATED_QUERY_PARAM_PATTERNS: RegExp[] = [/-enabled$/];

export const propagateQueryParams = (url: string) => {
  const [preHashPart, hashPart] = url.split('#');
  const [preQueryPart, queryString] = preHashPart.split('?');
  const params = qs.parse(queryString);
  Object.entries(getQueryParams())
    .filter(([key]) => {
      if (PROPAGATED_QUERY_PARAMS.indexOf(key) >= 0) {
        return true;
      }
      for (const pattern of PROPAGATED_QUERY_PARAM_PATTERNS) {
        if (pattern.test(key)) {
          return true;
        }
      }
      return false;
    })
    .forEach(([key, value]) => {
      params[key] = value;
    });
  if (hashPart) return `${preQueryPart}?${qs.stringify(params)}#${hashPart}`;
  else return `${preQueryPart}?${qs.stringify(params)}`;
};
