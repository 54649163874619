import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import Button from '@mui/material/Button';
import { useAuthState } from 'utils/auth-state';
import { useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import UserAvatar from 'components/user-avatar/UserAvatar';
import Divider from '@mui/material/Divider';
import { browserStorage } from 'utils/browser-storage';
import { useUserProfile } from 'atoms/user-profile';
import { Trans } from '@lingui/macro';
import { EVENTS, INTERACTIONS, resetMixpanel, useTrack } from 'utils/mixpanel';

export default function UserMenu({
  showAvatar = true,
}: {
  showAvatar?: boolean;
}) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });
  const history = useHistory();
  const { clearToken, canEditAcc } = useAuthState();
  const { userProfile } = useUserProfile();

  const track = useTrack();

  return (
    <>
      <Button {...bindTrigger(popupState)}>
        {showAvatar ? (
          <UserAvatar user={userProfile} size={30} />
        ) : (
          <Trans>Account</Trans>
        )}
        {/*<KeyboardArrowDownIcon style={{ color: 'white' }} />*/}
      </Button>
      <Menu {...bindMenu(popupState)}>
        {canEditAcc && (
          <MenuItem
            onClick={() => {
              history.push(ROUTES.PROFILE);
            }}
          >
            <Trans>Account Settings</Trans>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            track(EVENTS.SIGN_OUT, INTERACTIONS.CLICK);
            clearToken();
            resetMixpanel();
            browserStorage.useSessionStorage();
            // Full redirect because atoms may retain user specific data
            location.href = ROUTES.LANDING;
          }}
        >
          <Trans>Sign Out</Trans>
        </MenuItem>
      </Menu>
    </>
  );
}
