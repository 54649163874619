import { emitProject, projectFileKey } from "./project-data-coordinator"
import { atom } from "./utils/store"
import { v4 as uuid } from "uuid"
import { arrayMoveImmutable as move } from "array-move"

interface CanvasFrame {
    image?: string | Blob
    isVector: boolean
    key: string
    index: number
    rotationCenterX: number
    rotationCenterY: number
    transition?: string
}

interface CanvasEditorState {
    name: string
    currentKey: string
    localVersion: number
    frames: CanvasFrame[]
}

type Manifest = Omit<CanvasEditorState, "frames" | "localVersion"> & {
    frames: Omit<CanvasFrame, "image">[]
}

const defaultViewportDims = {
    height: 360,
    width: 480,
}

const getDefaultFrame = (): CanvasFrame => ({
    key: uuid() + ".svg",
    index: 0,
    isVector: true,
    image: undefined,
    rotationCenterX: defaultViewportDims.width / 2,
    rotationCenterY: defaultViewportDims.height / 2,
})

const getDefaultState = (): CanvasEditorState => {
    const frame = getDefaultFrame()
    return {
        name: "Untitled",
        currentKey: frame.key,
        localVersion: 0,
        frames: [frame],
    }
}

const state = atom<CanvasEditorState>(getDefaultState())

const addFrame = () => {
    const newFrame = getDefaultFrame()
    state.update((it) => ({
        ...it,
        frames: it.frames.concat(newFrame),
    }))
    emitProject({
        priority: "high",
        entries: {
            updated: [projectFileKey],
        },
    })
}

const removeFrame = (key: string) => {
    state.update((s) => ({
        ...s,
        frames: s.frames.filter((f) => f.key !== key),
    }))
    emitProject({
        priority: "high",
        entries: {
            deleted: [key],
            updated: [projectFileKey],
        },
    })
}

const moveFrame = (oldIdx: number, newIdx: number) => {
    state.update((s) => ({
        ...s,
        frames: move(s.frames, oldIdx, newIdx),
    }))
    emitProject({
        priority: "high",
        entries: {
            updated: [projectFileKey],
        },
    })
}

const updateCurrent = (cb: (cf: CanvasFrame) => CanvasFrame) => {
    let currentKey = state.get().currentKey
    state.update((s) => {
        const frames = s.frames.map((f) => {
            if (f.key === currentKey) {
                const nextState = cb(f)
                if (nextState.isVector && !f.isVector) {
                    currentKey =
                        f.key.split(".").slice(0, -1).join(".") + ".svg"
                    nextState.key = currentKey
                } else if (!nextState.isVector && f.isVector) {
                    currentKey =
                        f.key.split(".").slice(0, -1).join(".") + ".png"
                    nextState.key = currentKey
                }
                return nextState
            }
            return f
        })
        return {
            ...s,
            frames,
            currentKey,
        }
    })
    emitProject({
        priority: "high",
        entries: {
            updated: [projectFileKey, currentKey],
        },
    })
}

const setCurrent = (currentKey: string) => {
    state.update((s) => ({
        ...s,
        currentKey,
    }))
}

const reset = () => {
    state.set(getDefaultState())
}

export const getCanvasManifest = () => {
    const canvasState = state.get()
    const manifest = {
        ...canvasState,
        frames: [] as Omit<CanvasFrame, "image">[],
    }
    for (const { image, ...frame } of canvasState.frames) {
        manifest.frames.push(frame)
    }
    return manifest
}

export const getFrameContent = (key: string) => {
    return state.get().frames.find((f) => f.key === key)?.image
}

export const canvasEditorStore = {
    state,
    addFrame,
    moveFrame,
    removeFrame,
    updateCurrent,
    setCurrent,
    getFrameContent,
    getCanvasManifest,
    reset,
}

export const getCanvasDataArchive = async () => {
    const { default: JSZip } = await import("jszip")
    const zip = new JSZip()
    const { localVersion, ...canvasState } = canvasEditorStore.state.get()
    const manifest = {
        ...canvasState,
        frames: [] as Omit<CanvasFrame, "image">[],
    }
    for (const { image, ...frame } of canvasState.frames) {
        if (image) zip.file(frame.key, image)
        manifest.frames.push(frame)
    }
    zip.file(projectFileKey, JSON.stringify(manifest))
    return zip.generateAsync({ type: "blob" })
}

export const loadCanvasDataArchive = async (archive: ArrayBuffer) => {
    const { default: JSZip } = await import("jszip")
    const zip = new JSZip()
    await zip.loadAsync(archive)
    let rawManifest: string | undefined
    const imageData = new Map<string, string | Blob>()
    for (const [key, file] of Object.entries(zip.files)) {
        if (key === projectFileKey) {
            rawManifest = await file.async("string")
        } else if (key.match(/\.svg$/i)) {
            imageData.set(key, await file.async("string"))
        } else if (key.match(/\.png$/i)) {
            const buffer = await file.async("arraybuffer")
            imageData.set(key, new Blob([buffer], { type: "image/png" }))
        }
    }
    if (!rawManifest) {
        throw new Error("Project manifest missing")
    }
    const manifest: Manifest = JSON.parse(rawManifest)
    canvasEditorStore.state.update((prev) => {
        return {
            ...manifest,
            localVersion:
                prev?.localVersion == null ? 0 : prev.localVersion + 1,
            frames: manifest.frames.map((frame) => {
                const image = imageData.get(frame.key)
                if (!image) {
                    console.warn("Missing image in canvas archive: ", frame.key)
                }
                return { ...frame, image }
            }),
        }
    })
}
