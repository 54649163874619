import React from 'react';
import MicrosoftLogo from '!!react-svg-loader!../sign-up-form/microsoft-logo.svg';
import Button from '@mui/material/Button';
import { useGoogleLogin } from '@react-oauth/google';
import { Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { Trans } from '@lingui/macro';
import { EVENTS } from 'utils/mixpanel';

export default function MicrosoftSignInButton(p: {
  onSuccess: (accessToken: string) => void;
  onError: (err: any) => void;
  style?: React.CSSProperties;
  signUp?: boolean;
}) {
  const { instance } = useMsal();
  return (
    <Button
      data-analytics={EVENTS.SIGNUP_WITH_MICROSOFT}
      // variant='outlined'
      color='secondary'
      variant='contained'
      size='large'
      disableElevation
      onClick={async () => {
        const res = await instance.loginPopup({
          // eslint-disable-next-line lingui/no-unlocalized-strings
          scopes: ['User.Read'],
        });
        p.onSuccess(res.accessToken);
      }}
      sx={{
        paddingY: '0.8rem',
      }}
      startIcon={
        <MicrosoftLogo style={{ height: '1rem', marginRight: '0.5rem' }} />
      }
    >
      <Typography variant='h4'>
        {p.signUp ? <Trans>Sign up with</Trans> : <Trans>Sign in with</Trans>}{' '}
        <Trans>Microsoft</Trans>
      </Typography>
    </Button>
  );
}
