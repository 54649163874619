// import swal from 'sweetalert';

import { updateNetworkStatus } from './events/network-status';

export const reportServerError = (p: {
  title: string;
  text?: string;
  error?: any;
}) => {
  console.error(p.title, p.error);
  if (p.error?.response?.status === 401) {
    return;
  }
  if (p.error?.code === 'ERR_NETWORK') {
    updateNetworkStatus.post({ isOnline: false });
    return;
  }
  /* return swal({
    title: p.title,
    text: p.text ?? p.error?.response?.data?.error ?? 'Something went wrong',
    icon: false as any,
    dangerMode: true,
  }); */
  alert(`${p.title}\n${p.text ?? p.error?.response?.data?.error ?? ''}`);
};
