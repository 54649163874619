import { Box, ButtonBase, Fade, Stack, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import ROUTES from 'constants/routes';
import { useHistory } from 'react-router-dom';
import defaultCoverUrl from '../../assets/images/penguin-group.png';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import cc from 'classnames';

export function UserTypeButton(p: {
  type: string;
  subtext: string;
  href?: string;
  onClick?: () => void;
}) {
  const history = useHistory();
  return (
    <ButtonBase
      className={cc('analytics-track')}
      data-analytics={p.type.replace(/\s/g, '_')}
      onClick={
        p.onClick ||
        (() => {
          history.push(p.href as string);
        })
      }
    >
      <Stack
        py={3}
        px={2}
        minWidth={{
          xs: '100%',
          sm: 450,
        }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        borderRadius={2}
        sx={{
          backgroundColor: 'var(--color-base)',
          '&:hover': {
            backgroundColor: 'var(--color-selected)',
          },
        }}
      >
        <Stack alignItems='start'>
          <Typography variant='h3'>{p.type}</Typography>
          <Typography>{p.subtext}</Typography>
        </Stack>
        <ChevronRightIcon />
      </Stack>
    </ButtonBase>
  );
}

export default function OnboardingEntry() {
  const { _ } = useLingui();
  return (
    <Fade in>
      <Stack alignItems='center'>
        <Box component='img' width={350} src={defaultCoverUrl} />

        <Typography variant='h1' textAlign='center'>
          <Trans>First, choose your community</Trans>
        </Typography>
        <Typography textAlign='center' variant='subtitle1' mt={4}>
          <Trans>CoCo is designed both for educational and personal use.</Trans>{' '}
          <br />
          <Trans>
            Choose the type of community that you'll primarily use CoCo with.
          </Trans>
        </Typography>
        <Stack mt={6} spacing={4}>
          <UserTypeButton
            type={_(msg`Educational community`)}
            subtext={_(msg`(I will invite my students or other educators)`)}
            href={ROUTES.ONBOARDING_EDUCATIONAL_SIGNUP}
          />
          <UserTypeButton
            type={_(msg`Personal community`)}
            subtext={_(msg`(I will invite my friends or family)`)}
            href={ROUTES.ONBOARDING_PERSONAL_SIGNUP}
          />
        </Stack>
      </Stack>
    </Fade>
  );
}
