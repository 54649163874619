import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import { VideoFrame } from './VideoEmbed';
import CloseIcon from '@mui/icons-material/Close';
import C from './VideoOverlay.css';
import { Trans } from '@lingui/macro';

export default function VideoOverlay(p: { onClose?: () => void }) {
  const [vidBounds, setVideoBounds] = useState<
    | undefined
    | {
        width: number;
        height: number;
      }
  >();

  useEffect(() => {
    const setBounds = () => {
      setVideoBounds({
        width: window.innerWidth - 40,
        height: window.innerHeight - 80,
      });
    };
    window.addEventListener('resize', setBounds);
    setBounds();

    return () => {
      window.removeEventListener('resize', setBounds);
    };
  }, []);

  return (
    <div
      style={{
        background: 'rgba(0,0,0,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'row',
        zIndex: 100,
        padding: '40px 20px',
      }}
      onClick={p.onClose}
    >
      <div className={C.topBar}>
        <button
          onClick={p.onClose}
          className={cc([C.ghostControl, C.closeBtn])}
        >
          <CloseIcon /> <Trans>Close</Trans>
        </button>
      </div>
      {vidBounds && <VideoFrame dims={vidBounds} />}
    </div>
  );
}
