import React, { CSSProperties } from 'react';

export default function DotSeparator(p: { style?: CSSProperties }) {
  return (
    <span
      style={{
        display: 'inline-block',
        margin: '0 0.5rem',
        color: 'var(--color-subtext)',
        transform: 'scale(0.5)',
        ...p.style,
      }}
    >
      {' ● '}
    </span>
  );
}
