import React, { CSSProperties } from 'react';
import GmailLogo from '!!react-svg-loader!./gmail-logo.svg';
import OutlookLogo from '!!react-svg-loader!./outlook-logo.svg';
import C from './CodeForm.css';
import cc from 'classcat';
import BoxC from '../../styles/box.css';
import { Trans } from '@lingui/macro';
import { EVENTS } from 'utils/mixpanel';

export default function EmailProviderLinks(p: {
  style?: CSSProperties;
  className?: string;
}) {
  return (
    <ul className={cc([C.emailServiceLinks, p.className])} style={p.style}>
      <li>
        <a
          className={BoxC.flexRowCentered}
          href='https://gmail.com'
          target='_blank'
          data-analytics={EVENTS.OPEN_GMAIL}
        >
          <GmailLogo style={{ height: '1rem' }} /> <Trans>Open Gmail</Trans>
        </a>
      </li>
      <li>
        <a
          className={BoxC.flexRowCentered}
          href='https://outlook.com'
          target='_blank'
          data-analytics={EVENTS.OPEN_OUTLOOK}
        >
          <OutlookLogo style={{ height: '1rem' }} /> <Trans>Open Outlook</Trans>
        </a>
      </li>
    </ul>
  );
}
