import { Stack, SxProps, Theme, Typography } from '@mui/material';
import ControlledTextField from 'components/forms/ControlledTextField';
import React from 'react';

export default function FieldWithLabel(p: {
  label: string;
  name: string;
  field: React.ReactNode;
  row?: boolean;
  reverse?: boolean;
  labelMuted?: boolean;
  style?: SxProps<Theme>;
}) {
  let direction = 'column';
  if (p.row) {
    direction = 'row';
  }
  if (p.reverse) {
    direction += '-reverse';
  }

  return (
    <Stack
      spacing={1}
      alignItems={p.row ? 'center' : 'start'}
      justifyContent={p.reverse ? 'flex-end' : 'flex-start'}
      sx={p.style}
      direction={direction as any}
    >
      <label htmlFor={p.name}>
        <Typography
          variant='body1'
          color={p.labelMuted ? 'var(--color-subtext)' : undefined}
          mr={p.row ? 1 : 0}
        >
          {p.label}
        </Typography>
      </label>
      {p.field}
    </Stack>
  );
}
