import transform from "lodash/transform"
import { Evt } from "evt"

import {
    CollaboratorAction,
    collaboratorActionCreators,
    Types as CollaboratorActionTypes,
} from "./collaborator-actions.js"

import {
    SpaceAction,
    spaceActionCreators,
    Types as SpaceActionTypes,
} from "./space-actions.js"

import {
    ProjectAction,
    projectActionCreators,
    Types as ProjectActionTypes,
} from "./project-actions.js"

import {
    ErrorAction,
    errorActionCreators,
    Types as ErrorActionTypes,
} from "./error-actions.js"

import {
    modalActionCreators,
} from "./modal-actions.js"

export const ActionTypes = {
    ...CollaboratorActionTypes,
    ...SpaceActionTypes,
    ...ProjectActionTypes,
    ...ErrorActionTypes,
}

export const actionCreators = {
    ...spaceActionCreators,
    ...collaboratorActionCreators,
    ...projectActionCreators,
    ...errorActionCreators,
    ...modalActionCreators,
}

export const actionEmitter = new Evt<
    CollaboratorAction | ProjectAction | SpaceAction | ErrorAction
>()

declare const ENABLE_DEBUG_COLLABORATOR_TIMELINE: boolean

export const Dispatcher = transform(
    actionCreators,
    (result: any, fn: any, key: any) => {
        if (key === "updateCollaboratorStatus") {
            result[key] = (
                message: string,
                opts: { collaboratorId?: string }
            ) => {
                if (!ENABLE_DEBUG_COLLABORATOR_TIMELINE) {
                    actionEmitter.post(fn(message, opts))
                }
            }
        } else {
            result[key] = (...args: any[]) => {
                actionEmitter.post(fn(...args))
            }
        }
    },
    {}
) as {
    [K in keyof typeof actionCreators]: (
        ...args: Parameters<(typeof actionCreators)[K]>
    ) => void
}

let idleTimer: NodeJS.Timeout | null = null;


function setIdleTimer() {
    if (idleTimer) {
        clearTimeout(idleTimer);
    }
    idleTimer = setTimeout(() => {
        Dispatcher.openIdleModal();
    }, parseInt(process.env.IDLE_TIMEOUT || `${30*60*1000}`, 10) );
}

window.addEventListener("mousemove", setIdleTimer);
window.addEventListener("keydown", setIdleTimer);
window.addEventListener("click", setIdleTimer);
window.addEventListener("scroll", setIdleTimer);
window.addEventListener("resize", setIdleTimer);
window.addEventListener("focus", setIdleTimer);
 

