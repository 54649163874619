import { atom } from 'recoil';
import { i18n } from '@lingui/core';
import { localStorageEffect } from './storage';

export const LOCALE_STORAGE_KEY = 'locale';

export const updatedLocaleStateAtom = atom({
  key: 'updatedLocaleStateAtom',
  default: 'en',
});

export const localeStateAtom = atom({
  key: 'localeStateAtom',
  default: 'en',
  effects: [
    localStorageEffect(LOCALE_STORAGE_KEY),
  ],
});

export const loadI18n = async (locale) => {
  if (i18n.locale === locale) return;
  const { messages } = await import(`../locales/${locale}/messages.ts`);
  i18n.loadAndActivate({ locale, messages });
};

export const fetchAvailableLocales = async () => {
  try {
    const localesDir = require['context'](
      '../locales',
      true,
      /^\.\/[a-zA-Z-]{2,3}\/messages\.po$/,
    );
    const locales = localesDir
      .keys()
      .map((key) => key.match(/^\.\/([a-zA-Z-]+)\/messages\.po$/)?.[1] || '');
    return locales.filter(Boolean);
  } catch (error) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.error('Error fetching locales:', error);
    return [];
  }
};
