import React from 'react';
import cocoLogoUrl from './coco-logo.svg';
import cc from 'classcat';
import C from './Logo.css';

export default function Logo(p: {
  className?: string;
  labelClassName?: string;
  href?: string;
}) {
  return (
    <a
      className={cc([p.className, C.container])}
      style={{
        backgroundImage: `url(${cocoLogoUrl})`,
      }}
      onClick={() => window.location.reload()}
      href={p.href}
    ></a>
  );
}
