import React, { Suspense, useEffect, useState } from 'react';
import cc from 'classcat';
import times from 'lodash/times';
import C from './Testimonials.css';
import { cssUrl } from '../../utils/css';
import QuoteIcon from '../home-page/quote.svg';
import uniqueId from 'lodash/uniqueId';
import flatten from 'lodash/flatten';
import { msg, t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

interface TestimonialEntry {
  id: string;
  header: string;
  content: string;
  bkgColor: string;
}

const palette = [
  '#89e1ff',
  '#faabf5',
  '#76e2b1',
  '#ffd85b',
  '#ff9953',
  '#97d897',
  '#ff999e',
  '#8dc9e2',
];

// const featureTitle = [
//   'Real-time co-creation and collaboration with peers',
//   'CoCo corridors for communities',
//   'Supports block-based and text-based coding',
//   'New interactive peer programming blocks',
//   'Variety of co-creative environments',
//   'Live remixing of code and digital assets',
//   'Built-in video and audio support',
//   'A consciously designed self-less social platform',
// ];

const getFeatureTitle2 = () => [
  t`Little Language Models`,
  t`Realtime Co-Creation`,
  t`Blocks / Text Co-Coding`,
  t`Safe Private Corridors`,
  t`Collaborative Projects`,
  t`Live Remixing`,
  t`Built-in Video and Audio`,
  t`Self-less Social Platform`,
];

const getFeatureDescription = () => [
  t`Explore the foundational ideas underlying Generative AI by building your own little models with your own little data.`,
  t`Build creative projects with peers in a shared digital space. View and interact with others' projects in real-time.`,
  t`Code together with peers in live block-based and text-based coding environments (built on top of Scratch 3.0 and p5.js).`,
  t`Start a private digital corridor for your classroom or community. Create, explore, and remix spaces inside your corridor.`,
  t`Use new peer programming blocks to build collaborative stories, art, and games, or connect hardware devices across distance.`,
  t`Discover the magic of live remixing. Drag and drop code or assets from your friend's project into your own in real-time.`,
  t`No separate video calling platform required. Create an endless variety of shared programmable video experiences with peers.`,
  t`No individual profiles, no likes, no followers, no units of comparison. Just a peaceful place for — being. creative. together.`,
];

// Populate test data:
const getEntries = (): TestimonialEntry[] =>
  times(8).map((i) => ({
    id: uniqueId('testimonial-'),
    header: getFeatureTitle2()[i % getFeatureTitle2().length],
    bkgColor: palette[i % palette.length],
    content: getFeatureDescription()[i % getFeatureDescription().length],
  }));

interface FeedbackEntry {
  id: number;
  content: string;
}

const getFeedbackEntries: () => FeedbackEntry[][] = () => [
  [
    {
      id: 1,
      content: t`"You’ve made a platform that’s <strong>human in ways that few platforms even think to consider.</strong>"`,
    },
  ],
  [
    {
      id: 2,
      content: t`"I have honestly not stopped thinking about its <strong>transformative potential to fundamentally change teaching and learning</strong>."`,
    },
  ],
  [
    {
      id: 3,
      content: t`
    "I'm absolutely blown away by the workshop today! CoCo is just incredible.
    <strong> This is by far the closest thing I've had to sitting in a room
    with others and working on projects together. Just wow.</strong>"`,
    },
  ],
  [
    {
      id: 4,
      content: t`
    "By <strong>introducing probabilistic thinking and AI concepts in an engaging and accessible way</strong>, CoCo can help children develop foundational skills that are crucial for their future."`,
    },
  ],
  [
    {
      id: 5,
      content: t`"Little Language Models is such a playful and important way of learning about probabilistic thinking! <strong>This is such a refreshing change from purely one-way, passive, instructionist classes of using tools like ChatGPT </strong>that I see being implemented in schools and courses."`,
    },
  ],
  [
    {
      id: 6,
      content: t`"<strong>As an educator, the problems that we are trying to solve for, this
      is exactly it. </strong>We run thousands of workshops a year for youth and educators. I think this serves such a need!"`,
    },
  ],
];

const Slideshow = React.lazy(() => import('./Swiper'));

export default function Testimonials() {
  const { _ } = useLingui();
  const [isCarousel, setCarousel] = useState(false);

  useEffect(() => {
    condEnableCarousel();
    window.addEventListener('resize', condEnableCarousel);
    return () => {
      window.removeEventListener('resize', condEnableCarousel);
    };
  }, []);

  return (
    <>
      <div className={C.container}>
        {getEntries().map((it) => (
          <div className={C.cell} key={it.id}>
            <div className={C.header} style={{ backgroundColor: it.bkgColor }}>
              {it.header}
            </div>
            <div className={C.body}>{it.content}</div>
          </div>
        ))}
      </div>

      <div className={C.feedbackHeaderOuter}>
        <header className={C.feedbackHeader}>
          <div className={C.quoteContainer}>
            <img
              src={QuoteIcon}
              style={{ height: '30px', width: '30px' }}
              alt={_(msg`quote icon`)}
            />
          </div>
          <div>
            <Trans>Feedback from educators</Trans>
          </div>
        </header>
      </div>

      {isCarousel ? (
        <FeedbackCarousel />
      ) : (
        <div className={cc([C.feedbackContainer])}>
          <div className={C.feedbackInner}>
            <div className={C.feedbackGrid}>
              {getFeedbackEntries().map((feSet, idx) => (
                <div className={C.feedbackSet} key={`feset-${idx}`}>
                  <div className={C.feedbackSetInner}>
                    {feSet.map((fe) => (
                      <FeedbackSlide feedbackEntry={fe} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );

  function condEnableCarousel() {
    // Needs to be in sync with css
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const { matches } = window.matchMedia('(max-width: 1000px)');
    if (matches) {
      import('./Swiper').then(() => {
        setCarousel(true);
      });
    } else {
      setCarousel(false);
    }
  }
}

const FeedbackCarousel = () => {
  const [maxHeight, setMaxHeight] = useState(0);
  return (
    <Suspense fallback={null}>
      <Slideshow>
        {({ SwiperSlide }) =>
          flatten(getFeedbackEntries()).map((fe) => (
            <SwiperSlide key={fe.id}>
              <FeedbackSlide
                feedbackEntry={fe}
                {...{ maxHeight, setMaxHeight }}
              />
            </SwiperSlide>
          ))
        }
      </Slideshow>
    </Suspense>
  );
};

const FeedbackSlide = (p: {
  maxHeight?: number;
  setMaxHeight?: (update: (prev: number) => number) => void;
  feedbackEntry: FeedbackEntry;
}) => {
  const fe = p.feedbackEntry;

  const handleBubble = (el: HTMLDivElement | null) => {
    if (!el || !p.setMaxHeight || p.maxHeight == null) return;
    const height = el.getBoundingClientRect()?.height;
    p.setMaxHeight((prev) => Math.max(prev, height));
  };

  return (
    <div key={`fe-${fe.id}`} className={C.feedback}>
      <div
        className={C.feedbackBubbleOuter}
        ref={handleBubble}
        style={{
          minHeight: p.maxHeight ? p.maxHeight + 'px' : undefined,
        }}
      >
        <div
          className={C.feedbackBubble}
          dangerouslySetInnerHTML={{ __html: fe.content }}
        />
      </div>
    </div>
  );
};
