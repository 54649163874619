import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useOwnOrganizations } from 'atoms/organizations';
import { useUserProfile } from 'atoms/user-profile';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledDropdown from 'components/forms/ControlledDropdown';
import ControlledTextField from 'components/forms/ControlledTextField';
import OnboardingStepper from 'components/onboarding-flow/OnboardingStepper';
import GET_AGE_GROUPS from 'constants/age-groups';
import ROUTES from 'constants/routes';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Swal from 'sweetalert2';
import ControlledAutoComplete from 'components/forms/ControlledAutoComplete';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export function OrgSetupForm(p: { onSuccess?: (p: { id: string }) => void }) {
  const { _ } = useLingui();
  const { loadOrganizations } = useOwnOrganizations();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      age: [],
      socials: [],
    },
  });
  const [dispatching, setDispatching] = React.useState(false);

  const [websiteInput, setWebsiteInput] = React.useState('');
  const track = useTrack();
  async function submitData(data) {
    let socials = data.socials;
    if (!socials || socials.length === 0) {
      if (websiteInput?.length > 0) {
        socials = [websiteInput];
      } else {
        Swal.fire({
          title: _('At least one relevant link is required for verification.'),
        });
        return;
      }
    }

    const age = data.age;
    if (!age || age.length === 0) {
      Swal.fire({
        title: _('Please select at least one age group.'),
      });
      return;
    }
    setDispatching(true);
    const website = JSON.stringify(socials);
    const orgData = {
      name: data.name,
      website: website,
      ageGroup: JSON.stringify(age),
      request: data.request,
      country: '',
    };

    try {
      const res = await httpClient.post('/organizations', orgData);
      await loadOrganizations();
      p.onSuccess?.({
        id: res.data.id,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDispatching(false);
    }
  }

  return (
    <Stack
      alignItems='center'
      component='form'
      onSubmit={handleSubmit(submitData)}
    >
      <Stack
        sx={{
          padding: '1rem 2rem',
          width: {
            xs: '100%',
            md: '550px',
          },
        }}
        spacing={3}
      >
        <FieldWithLabel
          label={_(msg`Name of your school / organization:`)}
          name='name'
          field={
            <ControlledTextField
              name='name'
              id='name_control'
              placeholder=''
              required
              rules={{
                required: true,
              }}
              inputProps={{
                onFocus: () => {
                  track(EVENTS.ORG_NAME_INPUT, INTERACTIONS.FOCUS);
                },
              }}
              {...{ control }}
            />
          }
        />
        <Stack>
          <Typography variant='body1'>
            <Trans>
              Organization website / any relevant links (e.g. your LinkedIn url)
            </Trans>
            :
            <br />
            <Typography variant='caption'>
              <Trans>
                Press ENTER after each link to add it. At least one link
                required for verification.
              </Trans>
            </Typography>
          </Typography>
          <Box mt={1} />
          <ControlledAutoComplete
            name='socials'
            id='socials'
            items={[]}
            multiple
            // label={_(msg`Social 1:`)}
            {...{ control }}
            setInputValue={setWebsiteInput}
            onFocus={() => {
              track(EVENTS.ORG_WEBSITE_INPUT, INTERACTIONS.FOCUS);
            }}
          />
        </Stack>
        <FieldWithLabel
          label={_(msg`Primary age group(s) of members you work with`) + ':'}
          name='age'
          field={
            <Stack direction='row' gap={2}>
              {GET_AGE_GROUPS().map((ageGroup) => {
                const age = ageGroup.value;
                return (
                  <Stack direction='row' alignItems='center'>
                    <Checkbox
                      key={age}
                      value={age}
                      name='age'
                      onChange={(e) => {
                        track(EVENTS.ORG_AGE_INPUT, INTERACTIONS.CLICK, {
                          age,
                          checked: e.target.checked,
                        });
                        if (e.target.checked) {
                          setValue('age', [...getValues('age'), age]);
                        } else {
                          setValue(
                            'age',
                            getValues('age').filter((v) => v !== age),
                          );
                        }
                      }}
                    />
                    <Typography variant='body1'>{age}</Typography>
                  </Stack>
                );
              })}
            </Stack>
          }
        />
        {/*
        <FieldWithLabel
          label={_(msg`What makes you want to try CoCo?`)}
          name='request'
          field={
            <ControlledTextField
              name='request'
              id='request'
              placeholder={_(
                msg`We would love to learn more about you and your interest in using CoCo with your community.`,
              )}
              // required 
              inputProps={{
                autoComplete: 'one-time-code',
              }}
              multiline
              minRows={4}
              {...{ control }}
            />
          }
        />
        */}
        <LoadingButton
          fullWidth
          variant='contained'
          type='submit'
          loadingPosition='end'
          loading={dispatching}
          data-analytics={EVENTS.NEXT}
        >
          <strong>
            <Trans>Next</Trans>
          </strong>
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

export default function EducationalOnboardingOrgDetails() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { fetchUserProfile, userProfile } = useUserProfile();
  const history = useHistory();
  const [dispatching, setDispatching] = React.useState(false);

  return (
    <Fade in>
      <Stack alignItems='center'>
        <Typography mt={4} variant='h1' textAlign='center'>
          <Trans>Add your organization details</Trans>
        </Typography>

        <Typography mt={2} mb={2} variant='subtitle1' textAlign='center'>
          <Trans>
            This information is required for verifiying educational accounts.
          </Trans>
        </Typography>

        <Divider sx={{ width: '100%' }} />
        <Box mt={4} />
        <OrgSetupForm
          onSuccess={async () => {
            await fetchUserProfile();
            history.push(ROUTES.ONBOARDING_EDUCATIONAL_CORRIDOR_SETUP);
          }}
        />
        <Box mt={2} />
        <OnboardingStepper totalSteps={3} activeStep={1} />
      </Stack>
    </Fade>
  );
}
