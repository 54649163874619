import { atom, WritableAtom } from "./store"

export class ObservableMap<K, V> {
    inner: WritableAtom<Map<K, V>>
    constructor(entries?: [K, V][]) {
        this.inner = atom(new Map<K, V>(entries))
    }
    clear() {
        this.inner.set(new Map())
    }
    delete(key: K) {
        let didDelete = false
        this.inner.update((prevMap) => {
            didDelete = prevMap.delete(key)
            return new Map(prevMap.entries())
        })
        return didDelete
    }
    forEach(cb: (value: V, key: K, map: Map<K, V>) => void) {
        this.inner.get().forEach(cb)
    }
    get(key: K) {
        return this.inner.get().get(key)
    }
    has(key: K) {
        return this.inner.get().has(key)
    }
    set(key: K, value: V) {
        this.inner.update((prevMap) => {
            prevMap.set(key, value)
            return new Map(prevMap.entries())
        })
        return this
    }
    get size() {
        return this.inner.get().size
    }
}
