import { httpClient } from '@cocoplatform/coco-rtc-client';
import { atom, useRecoilState } from 'recoil';
import { reportServerError } from 'utils/report-error';
import { SyncState } from 'utils/sync-state';

export const organizationsAtom = atom<{
  syncState: SyncState;
  organizations: { id: string; name: string }[];
}>({
  key: 'organizations',
  default: {
    syncState: 'pending',
    organizations: [],
  },
});

export const useOwnOrganizations = () => {
  const [state, setState] = useRecoilState(organizationsAtom);
  const loadOrganizations = async () => {
    try {
      setState((s) => ({ ...s, syncState: 'loading' }));
      const resp = await httpClient.get(`/organizations`);
      setState({
        organizations: resp.data.organizations,
        syncState: 'loaded',
      });
    } catch (e: any) {
      reportServerError(e);
      setState((s) => ({ ...s, syncState: 'failed' }));
    }
  };
  return {
    ...state,
    loadOrganizations,
  };
};
