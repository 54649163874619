import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useUserProfile } from 'atoms/user-profile';
import ControlLabel from 'components/control-label/ControlLabel';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledAutoComplete from 'components/forms/ControlledAutoComplete';
import ControlledCheckbox from 'components/forms/ControlledCheckbox';
import ControlledDropdown from 'components/forms/ControlledDropdown';
import ControlledTextField from 'components/forms/ControlledTextField';
import OnboardingStepper from 'components/onboarding-flow/OnboardingStepper';
import { PasswordHints } from 'components/profile-update-page/ProfileUpdatePage';
import getCountryList, { getCountryListForDropdown } from 'constants/countries';
import ROUTES from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'utils/auth-state';
import { PASSWORD_REGEX } from 'utils/validation';
import CheckIcon from '@mui/icons-material/Check';
import UserAvatar from 'components/user-avatar/UserAvatar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import useFileSelector from 'utils/hooks/useFileSelector';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { reportServerError } from 'utils/report-error';
import { browserStorage } from 'utils/browser-storage';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';
//import { LocaleSwitcherIcon } from 'components/locale-switcher/LocaleSwitcher';

export default function EducationalOnboardingProfileDetails(p: {
  disableRedirect?: boolean;
  disableResetPassword?: boolean;
  disableDeactivateAccount?: boolean;
  buttonText?: string;
  onSuccess?: () => void;
}) {
  const { _ } = useLingui();
  const { updateUserProfile, userProfile, uploadUserAvatar } = useUserProfile();
  const [isDispatching, setDispatching] = useState(false);
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      keepSignedIn: true,
      emailPreference: true,
      password: '',
      firstName: userProfile?.firstName,
      lastName: userProfile?.lastName,
      countryCode:
        getCountryListForDropdown().find(
          (it) => it.value === userProfile?.countryCode,
        ) ||
        userProfile?.countryCode ||
        '',
      email: userProfile?.email,
      avatar: '',
    },
  });

  console.log(errors);

  const { setProfileCompletionState } = useAuthState();

  const [showPassword, setShowPassword] = useState(false);

  const { triggerRef, file: avatarFile } = useFileSelector();
  const auth = useAuthState();

  const [resettingPassword, setResettingPassword] = useState(false);
  const [isDeactivatingAccount, setDeactivatingAccount] = useState(false);

  useEffect(() => {
    if (!avatarFile) return;
    uploadUserAvatar(avatarFile);
    setValue('avatar', avatarFile.name, { shouldDirty: true });
  }, [avatarFile]);

  const history = useHistory();

  const password = useWatch({ name: 'password', control });
  const firstName = useWatch({ name: 'firstName', control });

  const [passwordFocused, setPasswordFocused] = useState(false);

  const track = useTrack();

  async function submitProfile(data: any) {
    try {
      browserStorage.selectStorage(data.keepSignedIn);
      setDispatching(true);
      await updateUserProfile({
        ...data,
        countryCode: data.countryCode.value || data.countryCode,
      });
      setProfileCompletionState('COMPLETE');
    } catch (err) {
      console.error(err);
    } finally {
      setDispatching(false);
    }

    if (p.disableRedirect) {
      p.onSuccess?.();
      return;
    }

    if (userProfile?.onboardingComplete) {
      window.location.href = ROUTES.LANDING;
      return;
    }
    history.push(ROUTES.ONBOARDING_EDUCATIONAL_ORG_DETAILS);
  }

  return (
    <Fade in>
      <Stack
        alignItems='center'
        component='form'
        onSubmit={handleSubmit((data) => {
          submitProfile(data);
        })}
      >
        <Box mt={4} />
        <Typography variant='h1' textAlign='center'>
          <Trans>Add your details</Trans>
        </Typography>
        <Typography mt={1} variant='subtitle1' textAlign='center'>
          <Trans>This information helps us personalize your experience.</Trans>
        </Typography>

        <Stack
          sx={{
            // border: '1px solid var(--color-border)',
            padding: '1rem 2rem',
            width: {
              xs: '100%',
              sm: '400px',
              md: '550px',
            },
          }}
          mt={1}
          spacing={2}
        >
          {userProfile?.onboardingComplete && (
            <Grid spacing={8} alignItems='center' container>
              {userProfile.profileUpdatedAt && (
                <Grid
                  ref={triggerRef}
                  item
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                  xs={2}
                >
                  <UserAvatar
                    size={60}
                    user={userProfile}
                    style={{
                      overflow: 'visible',
                    }}
                    postContent={
                      <CameraAltIcon
                        sx={{
                          position: 'absolute',
                          zIndex: 20,
                          bottom: 0,
                          right: 0,
                          color: 'var(--color-text)',
                        }}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item xs={userProfile.profileUpdatedAt ? 10 : 12}>
                <FieldWithLabel
                  label={_(msg`Email`)}
                  name='email'
                  field={
                    <ControlledTextField
                      name='email'
                      control={control}
                      InputProps={{
                        disabled: true,
                        startAdornment: userProfile?.email ? (
                          <InputAdornment position='start'>
                            <CheckIcon
                              style={{
                                height: '1rem',
                                width: '1rem',
                                marginTop: '-16px',
                              }}
                            />
                          </InputAdornment>
                        ) : undefined,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Button
                              variant='text'
                              onClick={() => {
                                history.push(ROUTES.EMAIL_UPDATE);
                              }}
                            >
                              {userProfile?.email ? (
                                <Trans>Update</Trans>
                              ) : (
                                <Trans>Add</Trans>
                              )}
                            </Button>
                          </InputAdornment>
                        ),
                        style: {
                          background: 'var(--color-base)',
                        },
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}
          <Divider />
          <Stack spacing={2} direction='row'>
            <FieldWithLabel
              label={_(msg`First Name`)}
              name='firstName'
              style={{
                flexGrow: 1,
              }}
              field={
                <ControlledTextField
                  name='firstName'
                  id='first_name_control'
                  placeholder={_(msg`First Name`)}
                  required
                  rules={{
                    required: true,
                  }}
                  inputProps={{
                    autoComplete: 'one-time-code',
                    onFocus: () => {
                      track(EVENTS.FIRST_NAME_INPUT, INTERACTIONS.FOCUS);
                    },
                  }}
                  {...{ control }}
                />
              }
            />
            <FieldWithLabel
              label={_(msg`Last Name`)}
              name='firstName'
              style={{
                flexGrow: 1,
              }}
              field={
                <ControlledTextField
                  name='lastName'
                  id='last_name_control'
                  placeholder={_(msg`Last Name`)}
                  inputProps={{
                    autoComplete: 'one-time-code',
                    onFocus: () => {
                      track(EVENTS.LAST_NAME_INPUT, INTERACTIONS.FOCUS);
                    },
                  }}
                  {...{ control }}
                />
              }
            />
          </Stack>
          <FieldWithLabel
            label={_(msg`Where are you based?`)}
            name='countryCode'
            field={
              <ControlledAutoComplete
                {...{ control, register, errors }}
                onFocus={() => {
                  track(EVENTS.COUNTRY_INPUT, INTERACTIONS.FOCUS);
                }}
                placeholder={_(msg`Type country/region`)}
                name='countryCode'
                error={!!errors.countryCode}
                items={getCountryListForDropdown()}
                getOptionLabel={(option) => option.name || option}
                onOptionClick={(option) => {
                  track(EVENTS.COUNTRY_INPUT_SELECT, INTERACTIONS.CLICK, {
                    country: option?.name,
                  });
                }}
                rules={{
                  required: false,
                }}
              />
            }
          />
          {/*
          <FieldWithLabel
            label={_(msg`Preferred Language`)}
            name='language'
            field={
              <Stack alignItems='flex-start'>
                <LocaleSwitcherIcon showLanguageName color='black' />
                <Typography variant='caption'>
                  <Trans>
                    You can change your language preference at any time using
                    the language switcher in the top right corner of the page.
                  </Trans>
                </Typography>
              </Stack>
            }
          />
          */}

          {(!userProfile?.onboardingComplete ||
            (userProfile?.onboardingComplete &&
              !userProfile.profileUpdatedAt)) && (
            <Stack spacing={0.5}>
              <FieldWithLabel
                label={_(msg`Set Password (optional)`)}
                name='password'
                field={
                  <ControlledTextField
                    name='password'
                    error={errors['password'] !== undefined}
                    type={showPassword ? 'text' : 'password'}
                    id='password_control'
                    placeholder=''
                    rules={{
                      required: false,
                      pattern: PASSWORD_REGEX,
                    }}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      onFocus: () => {
                        track(EVENTS.PASSWORD_INPUT, INTERACTIONS.FOCUS);
                      },
                    }}
                    InputProps={{
                      autoComplete: 'one-time-code',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Button
                            variant='text'
                            size='small'
                            onClick={() => {
                              setShowPassword((s) => !s);
                            }}
                          >
                            {showPassword ? (
                              <Trans>Hide</Trans>
                            ) : (
                              <Trans>Show</Trans>
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                      onBlur: () => {
                        setPasswordFocused(false);
                      },
                      onFocus: () => {
                        setPasswordFocused(true);
                      },
                    }}
                    {...{ control }}
                  />
                }
              />
              <Collapse in={passwordFocused}>
                <PasswordHints password={password} />
              </Collapse>
            </Stack>
          )}
          <Stack>
            <FieldWithLabel
              label={_(msg`It is okay to send me relevant emails about CoCo.`)}
              name='emailPreference'
              row
              reverse
              labelMuted
              field={
                <ControlledCheckbox
                  onClick={(val) => {
                    track(EVENTS.EMAIL_PREFERENCE, INTERACTIONS.CLICK, {
                      checked: val,
                    });
                  }}
                  name='emailPreference'
                  {...{ control }}
                />
              }
            />
            <FieldWithLabel
              label={_(msg`Keep me signed in on this device.`)}
              name='keepSignedIn'
              row
              reverse
              labelMuted
              field={
                <ControlledCheckbox
                  onClick={(val) => {
                    track(EVENTS.KEEP_ME_SIGNED_IN, INTERACTIONS.CLICK, {
                      checked: val,
                    });
                  }}
                  name='keepSignedIn'
                  {...{ control }}
                />
              }
            />
            <Box mt={2} />
            <LoadingButton
              disabled={
                (userProfile?.onboardingComplete && !isDirty) || !firstName
              }
              fullWidth
              variant='contained'
              type='submit'
              loading={isDispatching}
              loadingPosition='end'
              onClick={() => {
                track(EVENTS.NEXT, INTERACTIONS.CLICK, {
                  passwordFilled: !!password,
                  emailPreference: getValues('emailPreference'),
                  keepSignedIn: getValues('keepSignedIn'),
                });
              }}
            >
              <strong>{p.buttonText ?? <Trans>Next</Trans>}</strong>
            </LoadingButton>
            {userProfile?.onboardingComplete &&
              userProfile.profileUpdatedAt && (
                <Stack mt={2} direction='row'>
                  {!p.disableResetPassword && (
                    <LoadingButton
                      loading={resettingPassword}
                      variant='text'
                      onClick={submitPasswordResetRequest}
                      sx={{
                        alignSelf: 'start',
                      }}
                    >
                      <Trans>Reset Password</Trans>
                    </LoadingButton>
                  )}
                  <Box mt={2} />
                  {!p.disableResetPassword && (
                    <LoadingButton
                      loading={isDeactivatingAccount}
                      variant='text'
                      color='warning'
                      onClick={submitUserDeleteRequest}
                      sx={{
                        alignSelf: 'start',
                      }}
                    >
                      <Trans>Deactivate Account</Trans>
                    </LoadingButton>
                  )}
                </Stack>
              )}
          </Stack>
        </Stack>
        {!userProfile?.onboardingComplete && (
          <OnboardingStepper activeStep={0} totalSteps={3} />
        )}
      </Stack>
    </Fade>
  );

  async function submitPasswordResetRequest() {
    const email = userProfile?.email;
    if (!email) return;
    setResettingPassword(true);
    try {
      await httpClient.post('/auth/password/reset/request', {
        email,
      });
      history.push(ROUTES.POST_PASSWORD_RESET);
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to reset password`),
        error,
      });
    } finally {
      setResettingPassword(false);
    }
  }

  async function submitUserDeleteRequest() {
    if (
      !confirm(
        _(
          msg`Are you sure that you want to deactivate your account? You will lose access to all your corridors and will not be able to undo this later.`,
        ),
      )
    ) {
      return;
    }
    try {
      setDeactivatingAccount(true);
      await httpClient.delete('/user');
      auth.clearToken();
      history.push(ROUTES.HOME);
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to deactivate account`),
        error,
      });
    } finally {
      setDeactivatingAccount(false);
    }
  }
}
