function base64ToArrayBuffer(base64: string | null) {
    if (base64 == null) return null
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

function arrayBufferToBase64(buffer: ArrayBufferLike | null) {
    var binary = ""
    if (buffer == null) return binary
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export { arrayBufferToBase64, base64ToArrayBuffer }
