import * as z from "zod"
import { FileUploadSchema } from "../../utils/file-upload"
import { optFlag } from "../../utils/zod"

export const ProjectInputSchema = z.object({
    peerId: z.string(),
    userId: z.string(),
    sessionId: z.string(),
    projectId: z.string(),
    snapshot: z.string().nullish(),
    spaceId: z.string(),
    spaceSessionId: z.string().optional(),
    parentVersion: z.string().optional(),
    version: z.string().optional(),
})

export const ProjectUploadInputSchema = ProjectInputSchema.and(
    z.object({
        file: FileUploadSchema,
        ensurePreview: optFlag.nullish(),
    })
)

export type ProjectUploadInput = z.TypeOf<typeof ProjectUploadInputSchema>

export const ProjectEntryInputSchema = ProjectInputSchema.and(
    z.object({
        parentVersion: z.string(),
        key: z.string(),
        content: z.any(),
    })
)

export const ProjectUploadOutputSchema = z.object({
    version: z.string(),
    sizeBytes: z.number().nullish(),
    previewId: z.string().nullish(),
})

export const ProjectVersionInputSchema = z.object({
    userId: z.string(),
    projectId: z.string(),
    spaceSessionId: z.string(),
    parentVersion: z.string().nullish(),
    version: z.string().nullish(),
    snapshot: z.string().nullish(),
    spaceId: z.string(),
    entries: z.object({
        all: z.string().array(),
        updated: z.string().array().nullish(),
        deleted: z.string().array().nullish()
    })
})

export const ProjectVersionOutputSchema = ProjectUploadOutputSchema.and(z.object({
    uploadUrls: z.record(z.object({
        url: z.string(),
        fields: z.any()
    }))
}))

export const ProjectPromoteVersionInputSchema = z.object({
    ensurePreview: optFlag.nullish(),
    version: z.string(),
    userId: z.string(),
    spaceId: z.string(),
    projectId: z.string(),
    spaceSessionId: z.string()
})

export type ProjectUploadOutput = z.TypeOf<typeof ProjectUploadOutputSchema>

export const ProjectUploadUrlOutputSchema = z.object({
    version: z.string(),
    url: z.string()
})

export type ProjectUploadUrlOutput = z.TypeOf<typeof ProjectUploadUrlOutputSchema>
