import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  NOTIFICATION_EVENTS,
  Notification,
} from '@cocoplatform/coco-rtc-shared';
import { useHistory } from 'react-router-dom';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import BellIcon from '!!react-svg-loader!./bell.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { reportServerError } from 'utils/report-error';
import { timeout } from 'utils/async';
import C from './NotificationTrigger.css';
import Button from '@mui/material/Button';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { usePreviousDifferent } from 'rooks';
import { Evt } from 'evt';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { HTMLAnchorElement } from 'evt/lib/types/lib.dom';
import { qualifiedUserName } from 'utils/user';
import { CocoLogger } from '@cocoplatform/coco-logger';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export const notificationsEvt = new Evt<Notification>();

export default function NotificationTrigger(p: {
  style?: CSSProperties;
  className?: string;
}) {
  const { _ } = useLingui();
  const history = useHistory();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  useEffect(pollNotifications, []);
  const prevNotifications = usePreviousDifferent(notifications);
  const loadCounter = useRef(0);
  const notifButtonRef = useRef<any>(null);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'notification-menu',
  });

  useEffect(() => {
    // Skip the first load
    if (loadCounter.current === 1) return;

    // Trigger events for new notifications
    const prevIds = new Set(prevNotifications?.map((it) => it.id) ?? []);
    let newNotifications = false;
    notifications?.forEach((n) => {
      if (prevIds.has(n.id)) return;
      if (!n.eventName) return;
      newNotifications = true;
      notificationsEvt.post(n);
    });

    if (newNotifications) {
      // play audio
      const audio = new Audio(
        'https://frontendstackmain-s3bucket64cb25af-eyfhw2zc6738.s3.amazonaws.com/audio/notification_simple.wav',
      );
      audio
        .play()
        .catch((e) => console.warn(e))
        .then(() => {
          popupState.open(notifButtonRef.current);
        });
    }
  }, [prevNotifications, notifications]);

  return (
    <>
      <IconButton
        ref={notifButtonRef}
        style={{ ...p.style, position: 'relative' }}
        className={p.className}
        {...bindTrigger(popupState)}
        onClick={(e) => {
          loadNotifications();
          bindTrigger(popupState).onClick(e);
        }}
      >
        {notifications.length > 0 && (
          <div className={C.trigger}>{notifications.length}</div>
        )}
        <BellIcon style={{ height: '30px', width: '30px' }} />
        {/* isLoading && (
                <CircularProgress
                  size='40px'
                  style={{ position: 'absolute' }}
                />
              ) */}
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        {notifications.length === 0 && (
          <MenuItem>
            <ListItemText>
              <Trans>No new notifications</Trans>
            </ListItemText>
          </MenuItem>
        )}
        {notifications.length > 0 && (
          <MenuItem
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              marginTop: '-6px',
            }}
          >
            <ListItemText>
              <strong>
                <Trans>Notifications</Trans>
              </strong>
            </ListItemText>
            <ListItemSecondaryAction>
              <Button
                variant='outlined'
                size='small'
                className={C.clearBtn}
                onClick={async () => {
                  await httpClient.delete('/notifications', {
                    params: { id: notifications.map((n) => n.id) },
                  });
                  setNotifications([]);
                }}
              >
                <Trans>Clear</Trans>
              </Button>
            </ListItemSecondaryAction>
          </MenuItem>
        )}
        {processNotifications(notifications).map((n) => (
          <MenuItem
            key={n.id}
            onClick={() => {
              popupState.close();
              if (n.primaryActionUrl) {
                history.push(
                  n.primaryActionUrl.replace(/^(https?:\/\/[^/]*)?\//, '/'),
                );
              }
              httpClient.delete(`/notifications?id=${n.id}`).catch((e) => {
                console.error(e);
              });
            }}
          >
            <ListItemText
              style={{
                maxWidth: '200px',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  /* Handlebars would have escaped the content server side */
                  __html: n.content ?? '',
                }}
              />
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  function pollNotifications() {
    let isActive = true;
    const runLoop = async () => {
      while (isActive) {
        await loadNotifications();
        await timeout(10 * 1000);
      }
    };
    runLoop();
    return () => {
      isActive = false;
    };
  }

  function processNotifications(notifications: Notification[]) {
    return notifications.map((n) => {
      if (
        n.eventName === NOTIFICATION_EVENTS.INVITED_TO_SPACE_BY_MEMBER &&
        n.content
      ) {
        const payload = JSON.parse(n.content);
        return {
          ...n,
          // eslint-disable-next-line lingui/no-unlocalized-strings
          content: `<strong>${qualifiedUserName(payload.hosts[0])}</strong> ${_(
            msg`has invited you to join the space`,
          )} <strong>${payload.title}</strong>.`,
        };
      }

      if (
        n.eventName === NOTIFICATION_EVENTS.NEW_OPEN_TO_JOIN_SPACE &&
        n.content
      ) {
        const payload = JSON.parse(n.content);
        return {
          ...n,
          // eslint-disable-next-line lingui/no-unlocalized-strings
          content: `<strong>${qualifiedUserName(payload.hosts[0])}</strong> ${_(
            msg`has created the space`,
          )} <strong>${payload.title}</strong> ${_(msg`for members to join.`)}`,
        };
      }
      return n;
    });
  }

  async function loadNotifications() {
    try {
      const { data: notifications } = await httpClient.get(
        '/notifications/pending',
      );

      loadCounter.current++;
      setNotifications(notifications);
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      CocoLogger.error('Failed to fetch notifications', error);
    }
  }
}
