import React from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import snakeCase from 'lodash/snakeCase';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { Trans } from '@lingui/macro';

export default function ErrorList(p: {
  errors: Record<string, any> | string[];
}) {
  if (isEmpty(p.errors)) return null;
  return (
    <Grid item xs={12}>
      <Alert severity='error' style={{ marginBottom: '1rem' }}>
        <div>
          <Trans>Please fix following errors</Trans>:
        </div>
        <ul style={{ marginBottom: '0px' }}>
          {isArray(p.errors)
            ? p.errors.map((err) => <li key={err}>{err}</li>)
            : Object.entries(p.errors).map(([key, { type }]) => (
                <li key={key}>
                  {capitalize(snakeCase(key).replace(/[^a-z0-9]/gi, ' '))}{' '}
                  {type === 'required' ? (
                    <Trans>is required</Trans>
                  ) : (
                    <Trans>is not valid</Trans>
                  )}
                </li>
              ))}
        </ul>
      </Alert>
    </Grid>
  );
}
