export const urlSearchParams =
    typeof location === "undefined"
        ? new URLSearchParams()
        : new URLSearchParams(location.search)

export const projectPeerPullSizeThreshold =
    Number(urlSearchParams.get("project-peer-pull-size-lt") ?? 1024) *
    1024 /* Bytes */

export const projectServerPullTimeThreshold =
    Number(urlSearchParams.get("project-server-pull-time-gt") ?? 5) *
    1000 /* MS */

export const supportRenegotiation =
    urlSearchParams.get("support-reneg") !== "false"

export const supportAutoReconnect =
    urlSearchParams.get("support-auto-reconnect") !== "false"

export const supportProjectSaveToServer =
    urlSearchParams.get("support-project-save-to-server") != "false"
