import {
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  Grid,
  InputAdornment,
  MobileStepper,
  Stack,
  Typography,
} from '@mui/material';
import ControlLabel from 'components/control-label/ControlLabel';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledTextField from 'components/forms/ControlledTextField';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import getCountryList, { getCountryListForDropdown } from 'constants/countries';
import GET_AGE_GROUPS from 'constants/age-groups';

import ControlledDropdown from 'components/forms/ControlledDropdown';
import StorageOptionControl from 'components/storage-option-control/StorageOptionControl';
import ControlledCheckbox from 'components/forms/ControlledCheckbox';
import OnboardingStepper from 'components/onboarding-flow/OnboardingStepper';
import { LoadingButton } from '@mui/lab';
import { useUserProfile } from 'atoms/user-profile';
import { useAuthState } from 'utils/auth-state';
import { useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import ControlledAutoComplete from 'components/forms/ControlledAutoComplete';
import { browserStorage } from 'utils/browser-storage';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { PasswordHints } from 'components/profile-update-page/ProfileUpdatePage';
import { PASSWORD_REGEX } from 'utils/validation';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';
// import { LocaleSwitcherIcon } from 'components/locale-switcher/LocaleSwitcher';

export default function PersonalOnboardingProfileDetails(p: {
  disableRedirect?: boolean;
  onSuccess?: () => void;
}) {
  const { _ } = useLingui();
  const { updateUserProfile, userProfile } = useUserProfile();
  const { setProfileCompletionState } = useAuthState();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      keepSignedIn: true,
      emailPreference: true,
      firstName: userProfile?.firstName,
      lastName: '',
      guardianFirstName: '',
      guardianLastName: '',
      age: '',
      countryCode: '',
      request: '',
      password: '',
    },
  });

  const [dispatching, setDispatching] = useState(false);
  const history = useHistory();
  const track = useTrack();

  async function submit(data) {
    browserStorage.selectStorage(data.keepSignedIn);
    setDispatching(true);
    try {
      await updateUserProfile({
        ...data,
        firstName: data.firstName || data.guardianFirstName,
        countryCode: data.countryCode.value,
      });
      setProfileCompletionState('COMPLETE');

      if (!p.disableRedirect) {
        history.push(ROUTES.ONBOARDING_PERSONAL_CORRIDOR_SETUP);
      }

      if (p.onSuccess) {
        p.onSuccess();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDispatching(false);
    }
  }

  const [showPassword, setShowPassword] = useState(false);

  const [passwordFocused, setPasswordFocused] = useState(false);

  const password = useWatch({
    control,
    name: 'password',
  });

  return (
    <Fade in>
      <Stack
        component='form'
        onSubmit={handleSubmit(submit)}
        alignItems='center'
      >
        <Typography variant='h1' textAlign='center'>
          <Trans>Add your details</Trans>
        </Typography>
        <Typography mt={2} variant='subtitle1' textAlign='center'>
          <Trans>
            If you are a parent/guardian, please enter your name and
            <br /> select display name and age group for your child.
          </Trans>
        </Typography>
        <Box mt={2} />
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <Box mt={2} />
        <Stack spacing={3} mt={2} width='100%'>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FieldWithLabel
                label={_(msg`First Name`)}
                name='guardianFirstName'
                field={
                  <ControlledTextField
                    name='guardianFirstName'
                    id='first_name_control'
                    placeholder={_(msg`Enter first name`)}
                    required
                    error={errors.firstName}
                    register={register('guardianFirstName', {
                      required: true,
                    })}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      onFocus: () => {
                        track(EVENTS.FIRST_NAME_INPUT, INTERACTIONS.FOCUS);
                      },
                    }}
                    {...{ control }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FieldWithLabel
                label={_(msg`Last Name`)}
                name='guardianLastName'
                field={
                  <ControlledTextField
                    id='last_name_control'
                    name='guardianLastName'
                    placeholder={_(msg`Enter last name`)}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      onFocus: () => {
                        track(EVENTS.LAST_NAME_INPUT, INTERACTIONS.FOCUS);
                      },
                    }}
                    error={errors.lastName}
                    register={register('guardianLastName', {})}
                    {...{ control }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FieldWithLabel
                label={_(msg`Display Name (Optional)`)}
                name='firstName'
                field={
                  <ControlledTextField
                    name='firstName'
                    id='first_namcontrol'
                    placeholder={_(msg`Enter display name`)}
                    error={!!errors.firstName}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      onFocus: () => {
                        track(EVENTS.DISPLAY_NAME_INPUT, INTERACTIONS.FOCUS);
                      },
                    }}
                    {...{ control }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FieldWithLabel
                label={_(msg`Age Group`)}
                name='ageGroup'
                field={
                  <ControlledDropdown
                    {...{ control, register, errors }}
                    onFocus={() => {
                      track(EVENTS.PERSONAL_AGE_INPUT, INTERACTIONS.FOCUS);
                    }}
                    onItemSelect={(opt) => {
                      track(
                        EVENTS.PERSONAL_AGE_INPUT_SELECT,
                        INTERACTIONS.CLICK,
                        {
                          ageGroup: opt,
                        },
                      );
                    }}
                    placeholder={_(msg`Choose an option`)}
                    error={!!errors.age}
                    name='ageGroup'
                    items={GET_AGE_GROUPS()}
                    rules={{
                      required: true,
                    }}
                  />
                }
              />
            </Grid>
          </Grid>

          <FieldWithLabel
            label={_(msg`Where are you based?`)}
            name='countryCode'
            field={
              <ControlledAutoComplete
                {...{ control, register, errors }}
                placeholder={_(msg`Type country/region`)}
                name='countryCode'
                error={!!errors.countryCode}
                items={getCountryListForDropdown()}
                getOptionLabel={(option) => option.name || option}
                rules={{
                  required: true,
                }}
                onFocus={() => {
                  track(EVENTS.COUNTRY_INPUT, INTERACTIONS.FOCUS);
                }}
                onOptionClick={(opt) => {
                  track(EVENTS.COUNTRY_INPUT_SELECT, INTERACTIONS.CLICK, {
                    country: opt.name,
                  });
                }}
              />
            }
          />
          {/*<FieldWithLabel
            label={_(msg`Preferred Language`)}
            name='language'
            field={
              <Stack alignItems='flex-start'>
                <LocaleSwitcherIcon showLanguageName color='black' />
                <Typography variant='caption'>
                  <Trans>
                    You can change your language preference at any time using
                    the language switcher in the top right corner of the page.
                  </Trans>
                </Typography>
              </Stack>
            }
          />*/}
          <FieldWithLabel
            label={_(msg`Set Password (optional)`)}
            name='password'
            field={
              <ControlledTextField
                name='password'
                error={errors['password'] !== undefined}
                type={showPassword ? 'text' : 'password'}
                id='password_control'
                placeholder={_(msg`Enter password`)}
                rules={{
                  required: false,
                  pattern: PASSWORD_REGEX,
                }}
                inputProps={{
                  autoComplete: 'one-time-code',
                }}
                InputProps={{
                  autoComplete: 'one-time-code',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Button
                        variant='text'
                        size='small'
                        onClick={() => {
                          setShowPassword((s) => !s);
                        }}
                      >
                        {showPassword ? (
                          <Trans>Hide</Trans>
                        ) : (
                          <Trans>Show</Trans>
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                  onBlur: () => {
                    setPasswordFocused(false);
                  },
                  onFocus: () => {
                    track(EVENTS.PASSWORD_INPUT, INTERACTIONS.FOCUS);
                    setPasswordFocused(true);
                  },
                }}
                {...{ control }}
              />
            }
          />
          <Collapse in={passwordFocused}>
            <PasswordHints password={password} />
          </Collapse>
          <Stack>
            <LoadingButton
              fullWidth
              type='submit'
              variant='contained'
              loading={dispatching}
              onClick={() => {
                track(EVENTS.NEXT, INTERACTIONS.CLICK, {
                  displayNameFilled: !!getValues('firstName'),
                  passwordFilled: !!getValues('password'),
                });
              }}
            >
              <strong>
                <Trans>Next</Trans>
              </strong>
            </LoadingButton>
            <Box mt={2} />
            <FieldWithLabel
              label={_(msg`Keep me signed in on this device.`)}
              name='keepSignedIn'
              row
              reverse
              labelMuted
              field={
                <ControlledCheckbox
                  onClick={(val) => {
                    track(EVENTS.KEEP_ME_SIGNED_IN, INTERACTIONS.CLICK, {
                      checked: val,
                    });
                  }}
                  name='keepSignedIn'
                  {...{ control }}
                />
              }
            />
            <FieldWithLabel
              label={_(msg`It is okay to send me relevant emails about CoCo.`)}
              name='emailPreference'
              row
              reverse
              labelMuted
              field={
                <ControlledCheckbox
                  onClick={(val) => {
                    track(EVENTS.EMAIL_PREFERENCE, INTERACTIONS.CLICK, {
                      checked: val,
                    });
                  }}
                  name='emailPreference'
                  {...{ control }}
                />
              }
            />
          </Stack>
        </Stack>
        {!userProfile?.onboardingComplete && (
          <OnboardingStepper activeStep={0} totalSteps={2} />
        )}
      </Stack>
    </Fade>
  );
}
