export const UserReport = {
    reasonList: [{
        id: 'inappropriateProjectContent',
        label: 'Inappropriate project content'
    }, {
        id: 'offensiveDisplayName',
        label: 'Inappropriate display name'
    }, {
        id: 'other',
        label: 'Other'
    }]
}
