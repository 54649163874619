import React, { FormEvent, useState, useEffect } from 'react';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { useAuthState } from 'utils/auth-state';
import swal from 'sweetalert';
import qs from 'query-string';
import CodeInput from './CodeInput';
import { usePreviousDifferent } from 'rooks';

import C from './CodeForm.css';
import LC from '../prelogin-layout/PreLoginLayout.css';
import EmailProviderLinks from './EmailProviderLinks';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import FlexRow from 'components/flex/FlexRow';
import { reportServerError } from 'utils/report-error';
import { CocoLogger } from '@cocoplatform/coco-logger';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export default function CodeForm(p: {
  onSuccess: (res: {
    hasOrg: boolean;
    hasLocation: boolean;
    isProfileUpdated: boolean;
  }) => void;
  email: string;
  userType?: string;
  comunityId?: string;
}) {
  const { _ } = useLingui();
  const [code, setCode] = useState<string | null>(null);
  const [isDispatching, setDispatching] = useState(false);
  const [rerender, setRerender] = useState(0);
  const [showResend, setShowResend] = useState(false);
  const prevCode = usePreviousDifferent(code);
  const { saveToken, setProfileCompletionState } = useAuthState();

  useEffect(submitOnFirstComplete, [prevCode, code]);
  useEffect(loadNextPages, []);

  const track = useTrack();

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete='off'
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h1 className={LC.primaryHeader}>
        <Trans>Check your email for a code</Trans>
      </h1>
      <div
        className={LC.subText}
        style={{
          textAlign: 'center',
        }}
      >
        <Trans>
          We've sent a 6-letter code to{' '}
          <span style={{ fontWeight: 'var(--font-weight-bold)' }}>
            {p.email ?? _(msg`your email`)}
          </span>
          .
          <br />
          The code expires shortly, so please enter it soon.
        </Trans>
      </div>
      <div>
        <CodeInput
          key={rerender}
          required
          onChange={({ value }) => {
            setCode(value);
          }}
          onEnter={({ value }) => {
            setCode(value);
            setShowResend(false);
            dispatchToken(value);
          }}
        />
      </div>
      {isDispatching && (
        <div className={C.submitNotificationContainer}>
          <FlexRow alignItems='center' className={C.submitNotification}>
            <CircularProgress size='1rem' />
            <div className={C.label}>
              {showResend ? (
                <Trans>Sending new code....</Trans>
              ) : (
                <Trans>Submitting ...</Trans>
              )}
            </div>
          </FlexRow>
        </div>
      )}
      {showResend && (
        <Box textAlign='center'>
          <Typography color='error' variant='body1'>
            <Trans>The code entered is invalid or expired.</Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              <Link
                onClick={async () => {
                  track(EVENTS.RECEIVE_NEW_LOGIN_CODE, INTERACTIONS.CLICK);
                  setDispatching(true);
                  try {
                    const { data } = await httpClient.post('/auth/login-code', {
                      email: p.email,
                    });
                    if (data.success) {
                      setShowResend(false);
                      setRerender((prev) => prev + 1);
                    } else {
                      reportServerError({
                        title: _(msg`Failed to send login code`),
                      });
                    }
                  } catch (e) {
                    reportServerError({
                      title: _(msg`Failed to send login code`),
                    });
                  } finally {
                    setDispatching(false);
                  }
                }}
              >
                Click here
              </Link>{' '}
              to receive a new code.
            </Trans>
          </Typography>
        </Box>
      )}
      <EmailProviderLinks />
      <div className={C.spamHint}>
        <Trans>Can't find your code? Check your spam folder!</Trans>
      </div>
    </form>
  );

  function submitOnFirstComplete() {
    if (code) {
      dispatchToken();
    }
  }

  async function dispatchToken(codeStr = code) {
    setDispatching(true);
    try {
      track(EVENTS.SUBMIT_LOGIN_CODE, INTERACTIONS.CLICK, {
        code: codeStr,
      });
      const { data } = await httpClient.get(
        `/auth/login-code/verification?${qs.stringify({
          email: p.email,
          code: codeStr,
          userType: p.userType,
          communityId: p.comunityId,
        })}`,
      );
      if (data.success) {
        if (data.profileComplete) {
          setProfileCompletionState('COMPLETE');
        }
        saveToken(data.token, true);
        p?.onSuccess?.({
          isProfileUpdated: data.profileComplete,
          hasOrg: data.hasOrg,
          hasLocation: data.hasLocation,
        });
      } else {
        CocoLogger.error(
          _(msg`Failed to verify code with error`) +
            `: ${JSON.stringify(data)}`,
        );
        setShowResend(true);
      }
    } catch (e: any) {
      CocoLogger.error(_(msg`Failed to verify code with error`) + `: ${e}`);
      setShowResend(true);
    } finally {
      setDispatching(false);
    }
  }

  function handleRespErr(resp?: { error: string }) {
    swal({
      title: resp?.error ?? _(msg`Something went wrong`),
      icon: false as any,
      dangerMode: true,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    if (!code) return;
    dispatchToken();
  }

  function loadNextPages() {
    import('../profile-update-page/ProfileUpdatePage');
  }
}
