import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import CocoModal from 'components/coco-modal/coco-modal';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledTextField from 'components/forms/ControlledTextField';
import React, { useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { reportServerError } from 'utils/report-error';
import PlatformInfoFieldSet from 'components/PlatformInfoFieldSet/PlatformInfoFieldSet';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function BugReportModal({ onClose }: { onClose: () => void }) {
  const { _ } = useLingui();
  const formRef = useRef();
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <CocoModal onClose={onClose} header={_(msg`Report an issue`)}>
      <Box ref={formRef} p={4} component='form'>
        <PlatformInfoFieldSet />
        <FieldWithLabel
          label={_(msg`Please describe the issue you faced`) + ':'}
          name='issueDescription'
          field={
            <TextField
              multiline
              minRows={5}
              name='issueDescription'
              variant='outlined'
              autoFocus
              fullWidth
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '400px',
                },
              }}
            />
          }
        />
        <Box mt={3} />
        <Stack direction='row'>
          <FieldWithLabel
            label={_(msg`Share a screenshot:`)}
            name='screenshot'
            field={
              <Stack alignItems='center' direction='row' gap={3}>
                <Button
                  component='label'
                  color='primary'
                  variant='outlined'
                  startIcon={<CloudUploadIcon />}
                >
                  <Trans>Upload file</Trans>
                  <VisuallyHiddenInput
                    onChange={(e: any) => {
                      setSelectedFileName(e?.target?.files[0]?.name);
                    }}
                    type='file'
                    name='screenshot'
                  />
                </Button>
                {selectedFileName ? (
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      width: '200px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    variant='body1'
                  >
                    {selectedFileName}
                  </Typography>
                ) : null}
              </Stack>
            }
          />
        </Stack>
        <Box mt={4} />
        <Stack direction='row' justifyContent='end' gap={2}>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            color='primary'
            onClick={async () => {
              setIsLoading(true);

              try {
                const formData = new FormData(formRef.current);
                await httpClient.post(
                  '/survey/generic-in-app-bug-report/0/multipart',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                );
                await Swal.fire({
                  title: _(msg`Thank you for helping us make CoCo better.`),
                  text: _(
                    msg`We will look into the issue and get back to you soon.`,
                  ),
                  confirmButtonText: _(msg`Ok`),
                });
                onClose();
              } catch (e) {
                reportServerError({
                  title: _(msg`Failed to submit bug report`),
                  error: e,
                });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <Trans>Submit</Trans>
          </LoadingButton>
          <Button variant='outlined' color='primary' onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </Stack>
      </Box>
    </CocoModal>
  );
}
