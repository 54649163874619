import React from 'react';
import GoogLogo from '!!react-svg-loader!../sign-up-form/google-logo.svg';
import Button from '@mui/material/Button';
import { useGoogleLogin } from '@react-oauth/google';
import { Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { EVENTS } from 'utils/mixpanel';

export default function GoogleSignInButton(p: {
  onSuccess: (accessToken: string) => void;
  onError: (err: any) => void;
  style?: React.CSSProperties;
  signUp?: boolean;
}) {
  const login = useGoogleLogin({
    onSuccess: (res) => p.onSuccess(res.access_token),
    onError: p.onError,
  });
  return (
    <Button
      // variant='outlined'
      data-analytics={EVENTS.SIGNUP_WITH_GOOGLE}
      color='secondary'
      variant='contained'
      size='large'
      disableElevation
      onClick={() => {
        login();
      }}
      sx={{
        paddingY: '0.8rem',
      }}
      startIcon={<GoogLogo style={{ height: '1rem', marginRight: '0.5rem' }} />}
    >
      <Typography variant='h4'>
        {p.signUp ? <Trans>Sign up with</Trans> : <Trans>Sign in with</Trans>}{' '}
        <Trans>Google</Trans>
      </Typography>
    </Button>
  );
}
