import { Trans } from '@lingui/macro';
import { Stack, Typography, Box, Fade, Divider } from '@mui/material';
import CommunityInviteForm from 'components/community-invite-form/CommunityInviteForm';
import OnboardingStepper from 'components/onboarding-flow/OnboardingStepper';
import React from 'react';

export default function PersonalOnboardingCorridorInvite() {
  return (
    <Fade in>
      <Stack alignItems='center'>
        <Box mt={4} />
        <Typography variant='h1' textAlign='center'>
          <Trans>Invite friends to your corridor</Trans>
        </Typography>
        <Typography mt={4} variant='subtitle1' textAlign='center'>
          <Trans>
            CoCo is especially designed for collaborating with peers.
          </Trans>
          <br />
          <Trans>
            Make sure to invite others you know to experience it in action.
          </Trans>
        </Typography>
        <Box mt={2} />
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <Box mt={2} />

        <CommunityInviteForm showRequest={false} />
        <Box mt={4} />
        <OnboardingStepper totalSteps={3} activeStep={2} />
      </Stack>
    </Fade>
  );
}
