import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import Penguins from '../server-down/Penguins.png';
import { Step } from 'react-joyride';
import { useTutorial } from './tutorial-atom';
import C from './getting-started.css';
import { useWindowSize } from 'rooks';
import PenguinsGraphic from './penguin-pair.svg';
import { useUserProfile } from 'atoms/user-profile';
import { Trans } from '@lingui/macro';
export const GETTING_STARTED_TUTORIAL = 'Getting Started';

function GettingStartedStep() {
  const { innerWidth } = useWindowSize();
  const isMobile = (innerWidth || 0) < 600;

  const { userProfile } = useUserProfile();

  return (
    <Stack alignItems='center' className={C.GettingStartedSteps}>
      <Box
        px={8}
        pt={2}
        sx={{
          width: '100%',
          height: 'auto',
          backgroundColor: 'var(--color-coco-yellow)',
          overflow: 'hidden',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <img
          src={PenguinsGraphic}
          alt='Penguins'
          style={{
            width: '100%',
            height: 'auto',
            margin: '0 auto',
            transform: 'translateY(5%)',
          }}
        />
      </Box>
      <Typography mt={4} variant='h2'>
        <Trans>
          Welcome to CoCo,{' '}
          {userProfile?.firstName ||
            userProfile?.username ||
            userProfile?.lastName}
          !
        </Trans>
      </Typography>
      <Typography mb={2} variant='body1'>
        <Trans>Explore your dashboard in a few quick steps.</Trans>
      </Typography>
      {isMobile && (
        <>
          <br />
          <Typography color='red' variant='caption'>
            <Trans>
              CoCo is not optimized for mobile devices. Please use a desktop or
              laptop for the best experience.
            </Trans>
          </Typography>
        </>
      )}
    </Stack>
  );
}

export const getGettingStartedSteps = (showAddUserStep = true): Step[] => {
  return [
    {
      content: <GettingStartedStep />,
      placement: 'center',
      target: 'body',
      showSkipButton: true,
      showProgress: false,
      locale: {
        next: <Trans>Start</Trans>,
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '450px',
          paddingBottom: '1rem',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          borderRadius: '0.5rem',
          border: '1px solid black',
        },
        tooltipContent: {
          padding: 0,
        },
        buttonSkip: {
          marginLeft: 15,
          color: 'var(--color-primary)',
        },
        buttonNext: {
          marginRight: 15,
          padding: '0.5rem 1rem',
        },
      },
    },
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          <Typography variant='h2'>
            <Trans>Explore spaces shared in your community's corridor!</Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              This is your community's private corridor. Create, join, or
              explore collaborative spaces made by members.
            </Trans>
          </Typography>
        </Stack>
      ),
      target: '.dashboard-body',
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          <Typography variant='h2'>
            <Trans>Explore World Corridor</Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>Click this icon to see the world corridor!</Trans>
          </Typography>
        </Stack>
      ),
      target: '.world-corridor-icon',
      disableBeacon: true,
      hideFooter: true,
      spotlightClicks: true,
    },
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          <Typography variant='h2'>
            <Trans>Explore jams shared by creators around the world!</Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              See videos of collaborative spaces for inspiration. Click to
              explore and remix any space with friends.
            </Trans>
          </Typography>
        </Stack>
      ),
      target: '.dashboard-body',
      disableBeacon: true,
      placement: 'top-end',
    },
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          <Typography variant='h2'>
            <Trans>Go to your corridor</Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              Click this icon to return to your community's private corridor.
            </Trans>
          </Typography>
        </Stack>
      ),
      target: '.first-corridor-icon',
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true,
    },
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          <Typography variant='h2'>
            <Trans>Create any number of CoCo spaces with peers</Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              Build collaborative projects with peers in real-time. All spaces
              are saved in your corridor.
            </Trans>
          </Typography>
        </Stack>
      ),
      target: '#coco-new-space-btn',
      disableBeacon: true,
      ...(!showAddUserStep
        ? { hideFooter: true, spotlightClicks: true, hideCloseButton: false }
        : {}),
    },
    ...(showAddUserStep
      ? [
        {
          content: (
            <Stack className={C.GettingStartedSteps}>
              <Typography variant='h2'>
                <Trans>Invite members and start co-creating!</Trans>
              </Typography>
              <Typography variant='body1'>
                <Trans>
                  Add new members to your corridor anytime. Only the members
                  you invite can access this corridor.
                </Trans>
              </Typography>
            </Stack>
          ),
          target: '#add-members-button',
          disableBeacon: true,
          spotlightClicks: true,
          hideFooter: true,
          hideCloseButton: false,
        },
      ]
      : []),
  ];
};
