import type { CommunityDetails, ProjectUpdate, Space, User } from "@cocoplatform/coco-rtc-shared"
import uniqueId from "lodash/uniqueId"

export const Types = {
    UPDATE_VISITED_ID: "scratch-gui/space/UPDATE_VISITED_ID",
    UPDATE_SESSION: "scratch-gui/space/UPDATE_SESSION",
    SET_HOST: "scratch-gui/space/SET_HOST",
    SET_MANAGER: "scratch-gui/space/SET_MANAGER",
    SET_HOST_AWAITED: "scratch-gui/space/SET_HOST_AWAITED",
    ENQ_EVENT: "scratch-gui/space/ENQ_EVENT",
    DEQ_EVENT: "scratch-gui/space/DEQ_EVENT",
    ENQ_NOTIFICATION: "scratch-gui/space/ENQ_NOTIFICATION",
    DEQ_NOTIFICATION: "scratch-gui/space/DEQ_NOTIFICATION",
    ENQ_REACTION: "scratch-gui/space/ENQ_REACTION",
    DEQ_REACTIONS: "scratch-gui/space/DEQ_REACTIONS",
    UPDATE_LIVE_STATUS: "scratch-gui/space/UPDATE_LIVE_STATUS",
    MARK_PROJECT_DIRTY: "scratch-gui/space/MARK_PROJECT_DIRTY",
    START_SAVING_PROJECT: "scratch-gui/space/START_SAVING_PROJECT",
    END_SAVING_PROJECT: "scratch-gui/space/END_SAVING_PROJECT",
    MARK_SPACE_LOCKED: "scratch-gui/space/MARK_SPACE_LOCKED",
    MARK_SPACE_ENDED: "scratch-gui/space/MARK_SPACE_ENDED",
    SET_SPACE_DETAILS: "scratch-gui/space/SET_SPACE_DETAILS",
    SET_MIC_AVAILABILITY: "scratch-gui/space/SET_MIC_AVAILABILITY",
    SET_VIDEO_AVAILABILITY: "scratch-gui/space/SET_VIDEO_AVAILABILITY",
    SET_COLLABORATOR_CHUNKS: "scratch-gui/space/SET_COLLABORATOR_CHUNKS",
    SET_USERNAME_FROZEN: "scratch-gui/space/SET_USERNAME_FROZEN",
    NOTIFY_USER_REPORTED: "scratch-gui/space/NOTIFY_USER_REPORTED",
    COMPLETE_REMIX: "scratch-gui/space/COMPLETE_REMIX",
}

export type PeerProject = Pick<ProjectUpdate, "projectId"> &
    Partial<ProjectUpdate>

export interface PeerState {
    user?: User
    isLive?: boolean
    project: PeerProject
}

export interface SpaceBranchState {
    spaceId?: string
    spaceTitle?: string
    spaceSessionId?: string
    isEnded: boolean
    isLocked: boolean
    ownPeerId: string
    selectedPeerId?: string
    peers: { [peerId: string]: PeerState }
}

export const updateSpaceSession = (state: Partial<SpaceBranchState>) => ({
    type: Types.UPDATE_SESSION,
    ...state,
})

export const setSelectedPeerId = (id: string) =>
    updateSpaceSession({
        selectedPeerId: id,
    })

export const updatePeerProject = (id: string, project: PeerProject) =>
    updateSpaceSession({
        peers: {
            [id]: {
                project,
            },
        },
    })

export type UpdateSessionAction = ReturnType<typeof updateSpaceSession>

export type EventNotificationType = "JoinSpace" | "LeaveSpace" | "SentReaction" | "ReceivedSignalSprout"

export const enqueueEvent = (
    message: string | undefined,
    eventType: EventNotificationType,
    meta?: any,
    id?: string
) => ({
    type: Types.ENQ_EVENT,
    message,
    eventType,
    meta,
    id: id ?? uniqueId("space-msg-"),
})

export type EnqueueEventAction = ReturnType<typeof enqueueEvent>

export const dequeueEvent = (id: string) => ({
    type: Types.DEQ_EVENT,
    id,
})

export type DequeueEventAction = ReturnType<typeof dequeueEvent>

export const enqueueNotification = (
    message: string | undefined,
    eventType: EventNotificationType,
    meta?: any,
    id?: string
) => ({
    type: Types.ENQ_NOTIFICATION,
    message,
    eventType,
    meta,
    id: id ?? uniqueId("space-msg-"),
})

export type EnqueueNotificationAction = ReturnType<typeof enqueueNotification>

export const dequeueNotification = (id: string) => ({
    type: Types.DEQ_NOTIFICATION,
    id,
})

export type DequeueNotificationAction = ReturnType<typeof dequeueNotification>

export const enqueueReaction = (
    reactionType: string,
    senderId: string,
    senderName?: string,
    id?: string
) => ({
    type: Types.ENQ_REACTION,
    reactionType,
    senderId,
    senderName,
    id: id ?? uniqueId("space-msg-"),
})

export type EnqueueReactionAction = ReturnType<typeof enqueueReaction>

export const dequeueReactions = (reactionType: string) => ({
    type: Types.DEQ_REACTIONS,
    reactionType,
})

export type DequeueReactionsAction = ReturnType<typeof dequeueReactions>

export const updateVisitedId = (id: string, visitedPeerId: string) => ({
    type: Types.UPDATE_VISITED_ID,
    id,
    visitedPeerId,
})

export type UpdateVisitedIdAction = ReturnType<typeof updateVisitedId>

export const updateLiveStatus = (id: string, isLive: boolean) => ({
    type: Types.UPDATE_LIVE_STATUS,
    id,
    isLive,
})

export type UpdateLiveStatusAction = ReturnType<typeof updateLiveStatus>

export const startSavingProject = () => ({
    type: Types.START_SAVING_PROJECT,
    at: +new Date(),
})

export type StartSavingProjectAction = ReturnType<typeof startSavingProject>

export const markProjectDirty = () => ({
    type: Types.MARK_PROJECT_DIRTY,
    at: +new Date(),
})

export type MarkProjectDirtyAction = ReturnType<typeof markProjectDirty>

export const endSavingProject = () => ({
    type: Types.END_SAVING_PROJECT,
    at: +new Date(),
})

export type EndSavingProjectAction = ReturnType<typeof endSavingProject>

export const markSpaceLocked = () => ({
    type: Types.MARK_SPACE_LOCKED,
})

export type MarkSpaceLockedAction = ReturnType<typeof markSpaceLocked>

export const markSpaceEnded = () => ({
    type: Types.MARK_SPACE_ENDED,
})

export type MarkSpaceEndedAction = ReturnType<typeof markSpaceEnded>

export const setHost = (isHost: boolean) => ({
    type: Types.SET_HOST,
    isHost,
})

export type SetHostAction = ReturnType<typeof setHost>

export const setManager = (isManager: boolean) => ({
    type: Types.SET_MANAGER,
    isManager,
})

export type SetManagerAction = ReturnType<typeof setManager>

export const setHostAwaited = () => ({
    type: Types.SET_HOST_AWAITED,
})

export type SetHostAwaitedAction = ReturnType<typeof setHostAwaited>

export const setSpaceDetails = (space: Space, community: CommunityDetails) => ({
    type: Types.SET_SPACE_DETAILS,
    space,
    community,
})

export type SetSpaceDetailsAction = ReturnType<typeof setSpaceDetails>

export const completeRemix = () => ({
    type: Types.COMPLETE_REMIX,
})

export type CompleteRemixAction = ReturnType<typeof completeRemix>

export const setMicAvailability = (isAvailable: boolean) => ({
    type: Types.SET_MIC_AVAILABILITY,
    isAvailable,
})

export type SetMicAvailabilityAction = ReturnType<typeof setMicAvailability>

export const setVideoAvailability = (isAvailable: boolean) => ({
    type: Types.SET_VIDEO_AVAILABILITY,
    isAvailable,
})

export type SetVideoAvailabilityAction = ReturnType<typeof setVideoAvailability>

export const setCollaboratorChunks = (chunks: string[][]) => ({
    type: Types.SET_COLLABORATOR_CHUNKS,
    chunks,
})

export type SetCollaboratorChunksAction = ReturnType<
    typeof setCollaboratorChunks
>

export const notifyUserReported = (
    reporterPeerId: string,
    reportedPeerId: string,
    reasons: string[]
) => ({
    type: Types.NOTIFY_USER_REPORTED,
    reporterPeerId,
    reportedPeerId,
    reasons,
})

export type NotifyUserReportedAction = ReturnType<typeof notifyUserReported>

export const setUsernameFrozen = () => ({
    type: Types.SET_USERNAME_FROZEN,
})

export type SetUsernameFrozenAction = ReturnType<typeof setUsernameFrozen>

export type SpaceAction =
    | UpdateSessionAction
    | EnqueueEventAction
    | DequeueEventAction
    | EnqueueReactionAction
    | DequeueReactionsAction
    | UpdateVisitedIdAction
    | UpdateLiveStatusAction
    | StartSavingProjectAction
    | EndSavingProjectAction
    | MarkSpaceLockedAction
    | MarkSpaceEndedAction
    | MarkProjectDirtyAction
    | SetHostAction
    | SetVideoAvailabilityAction
    | SetMicAvailabilityAction
    | SetSpaceDetailsAction
    | SetHostAwaitedAction
    | SetCollaboratorChunksAction
    | NotifyUserReportedAction
    | SetUsernameFrozenAction
    | SetManagerAction
    | CompleteRemixAction

export const spaceActionCreators = {
    updateSpaceSession,
    setSelectedPeerId,
    updatePeerProject,
    enqueueNotification,
    dequeueNotification,
    enqueueEvent,
    dequeueEvent,
    enqueueReaction,
    dequeueReactions,
    updateVisitedId,
    updateLiveStatus,
    startSavingProject,
    endSavingProject,
    markSpaceLocked,
    markSpaceEnded,
    setHost,
    setMicAvailability,
    setVideoAvailability,
    setSpaceDetails,
    setHostAwaited,
    setCollaboratorChunks,
    notifyUserReported,
    setUsernameFrozen,
    setManager,
    completeRemix,
}
