import { atom } from "nanostores"
import type { NodeWithChildren, Element } from "domhandler"
import {
    CodeEditorState,
    state,
    CodeFile,
    getDefaultHTML,
    AssetFile,
} from "./state"
import debounce from "lodash/debounce"

export interface ImportMapping {
    path: string
    target: string
}

export interface CodePreviewOptions {
    importMappings: ImportMapping[]
}

export const getCodePreviewStore = async (
    opts: CodePreviewOptions,
    editorState = state
) => {
    const imported = await import("../utils/dom-transformer")
    const { parseDocument, serialize, Text, Element } = imported
    const processChildren = (
        dom: NodeWithChildren,
        files: (CodeFile | AssetFile)[]
    ) => {
        let children: typeof dom.children = []
        for (let i = 0; i < dom.children.length; i++) {
            let el = dom.children[i]
            if (!(el instanceof Element)) {
                children.push(el)
                continue
            }
            let shouldIgnore = false
            if (el.tagName.toLowerCase() === "script") {
                const src = el.attribs.src
                const isLocal =
                    src?.match(/\.js$/) && !src?.match(/^https?:\/\//)
                if (src && isLocal) {
                    // Local script
                    const assetPath = normalizeAssetPath(src)
                    const file = files.find((f) => f.path === assetPath)
                    if (file?.isText) {
                        // Reference to file in project
                        delete el.attribs.src
                        const textNode = new Text(file.content)
                        textNode.parent = el;
                        el.children.push(textNode);
                        el.attribs["data-src"] = src
                    } else {
                        const importMapping = opts.importMappings.find(
                            (it) => it.path === assetPath
                        )
                        if (importMapping) {
                            el.attribs.src = importMapping.target
                        } else {
                            console.warn(
                                "Skipping spurious asset reference: ",
                                src
                            )
                            shouldIgnore = true
                        }
                    }
                }
            } else if (el.tagName.toLowerCase() === "link") {
                const href = el.attribs.href
                if (href?.match(/\.css$/) && !href.match(/^https?:\/\//)) {
                    // Local style link
                    const assetPath = normalizeAssetPath(href)
                    const file = files.find((f) => f.path === assetPath)
                    if (file?.isText) {
                        el = new Element("style", {
                            type: "text/css",
                        })
                        el.children ??= []
                        el.children.push(new Text(file.content))
                    } else {
                        const importMapping = opts.importMappings.find(
                            (it) => it.path === assetPath
                        )
                        if (importMapping) {
                            el.attribs.href = importMapping.target
                        } else {
                            console.warn(
                                "Skipping spurious asset reference: ",
                                href
                            )
                            shouldIgnore = true
                        }
                    }
                }
            } else if (el.children) {
                processChildren(el, files)
            }
            if (!shouldIgnore) {
                children.push(el)
            }
        }
        dom.children = children
    }

    const store = atom<string>("")

    const updatePreview = (es: CodeEditorState | null) => {
        if (!es) return
        const { files } = es
        const htmlFile: CodeFile | undefined = files.find(
            (it): it is CodeFile => it.path === "index.html" && it.isText
        )
        const html = htmlFile?.content ?? getDefaultHTML()
        const dom = parseDocument(html)
        processChildren(dom, files)
        store.set(serialize(dom))
    }

    const updatePreviewDebounced = debounce(updatePreview, 500)

    const unsubscribe = editorState.subscribe(updatePreviewDebounced)

    return { store, unsubscribe }
}

const normalizeAssetPath = (relPath: string) => {
    if (relPath?.startsWith("./")) {
        return relPath.slice(2)
    }
    return relPath
}
