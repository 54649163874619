import React, { useEffect, useRef, useState } from 'react';
import C from './CodeForm.css';
import times from 'lodash/times';
import uniqueId from 'lodash/uniqueId';

export const CODE_LEN = 6;

export default function CodeInput(p: {
  required: boolean;
  onChange?: (p: { value: string }) => void;
  onEnter?: (p: { value: string }) => void;
}) {
  const [code, setCode] = useState<(string | null)[]>([]);
  const inputs = useRef<(HTMLInputElement | null)[]>([]);
  const isComplete = (codeInput = code) => {
    return (
      codeInput.length === CODE_LEN &&
      codeInput.findIndex((it) => it == null) === -1
    );
  };

  useEffect(() => {
    if (isComplete(code)) {
      p.onChange?.({ value: code.join('') });
    }
  }, [code]);

  return (
    <div className={C.inputGroupOuter}>
      <div className={C.inputGroupContainer}>
        {times(2, (firstIdx) => {
          return (
            <div key={firstIdx} className={C.inputGroup}>
              {times(3, (secIdx) => {
                const idx = firstIdx * 3 + secIdx;
                return (
                  <input
                    value={`${code[idx] ?? ''}`}
                    onKeyDown={handleKeyDown(firstIdx, secIdx)}
                    onPaste={handlePaste(firstIdx, secIdx)}
                    ref={(el) => {
                      inputs.current[idx] = el;
                    }}
                    maxLength={1}
                    key={`${firstIdx}:${secIdx}`}
                    required={p.required}
                    autoComplete='off'
                    name={uniqueId(`code-input-${new Date()}`)}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );

  function handlePaste(firstIdx: number, secIdx: number) {
    return (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const idx = firstIdx * 3 + secIdx;
      const value = e.clipboardData.getData('text');
      if (!value) return;
      const chars = value
        .toUpperCase()
        .split('')
        .filter((it) => it.match(/^[a-z0-9]$/i));
      if (chars.length === 0) return;
      // Ensure we don't paste more than 6 characters, starting from the current index
      setCode((prevCode) => {
        const nextCode = [...chars.slice(0, 6)];
        return nextCode;
      });

      inputs.current[idx + chars.length]?.focus();
    };
  }

  function handleKeyDown(firstIdx: number, secIdx: number) {
    return (e: any) => {
      if (e.ctrlKey || e.metaKey) return;
      const idx = firstIdx * 3 + secIdx;
      const { key } = e;
      if (key === 'Backspace') {
        e.stopPropagation();
        e.preventDefault();
        setCode((prevCode) => {
          const nextCode = [...prevCode];
          nextCode[idx] = null;
          return nextCode;
        });
        inputs.current[idx - 1]?.focus();
        return;
      } else if (key === 'Delete') {
        e.stopPropagation();
        e.preventDefault();
        setCode((prevCode) => {
          const nextCode = [...prevCode];
          nextCode[idx] = null;
          return nextCode;
        });
        return;
      } else if (key === 'ArrowRight') {
        e.stopPropagation();
        e.preventDefault();
        inputs.current[idx + 1]?.focus();
        return;
      } else if (key === 'ArrowLeft') {
        e.stopPropagation();
        e.preventDefault();
        inputs.current[idx - 1]?.focus();
        return;
      } else if (key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        if (
          code.length === CODE_LEN &&
          code.findIndex((it) => it == null) === -1
        ) {
          p.onEnter?.({
            value: code.join(''),
          });
        }
      } else {
        let keycode;
        if (e.key !== undefined) {
          if (e.key.length !== 1) {
            e.stopPropagation();
            e.preventDefault();
            return;
          }
          keycode = e.key.toUpperCase().charCodeAt(0);
        } else if (e.keyIdentifier !== undefined) {
          if (e.keyIdentifier.length !== 1) {
            e.stopPropagation();
            e.preventDefault();
            return;
          }
          keycode = e.keyIdentifier.toUpperCase().charCodeAt(0);
        } else if (e.keyCode !== undefined) {
          keycode = e.keyCode;
        }
        if (
          (keycode >= 48 && keycode <= 57) ||
          (keycode >= 65 && keycode <= 90)
        ) {
          e.stopPropagation();
          e.preventDefault();
          setCode((prevCode) => {
            const nextCode = [...prevCode];
            nextCode[idx] = String.fromCharCode(keycode);
            return nextCode;
          });
          inputs.current[idx + 1]?.focus();
        }
      }
    };
  }

  function handleChange(firstIdx: number, secIdx: number) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const idx = firstIdx * 3 + secIdx;
      const value = e.currentTarget.value;
      if (!value) return;
      const chars = value
        .toUpperCase()
        .split('')
        .filter((it) => it.match(/^[a-z0-9]$/i));
      if (chars.length === 0) return;
      setCode((prevCode) => {
        const nextCode = [...prevCode];
        nextCode.splice(idx, chars.length, ...chars);
        return nextCode;
      });
      inputs.current[idx + chars.length]?.focus();
    };
  }
}
