import ControlledTextField from 'components/forms/ControlledTextField';
import cc from 'classcat';
import React, { useEffect, useMemo, useState } from 'react';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { useForm } from 'react-hook-form';
import ErrorList from 'components/forms/ErrorList';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import { reportServerError } from 'utils/report-error';
import * as communityCache from '../../caches/community';
import {
  CommunityDetails,
  OrganizationInfo,
} from '@cocoplatform/coco-rtc-shared';
import { useCommunityDetails } from '../../atoms/community-details';
import { LightTooltip } from 'components/min-sidebar-community-list/MinSidebarCommunityList';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import CameraAltIcon from '!!react-svg-loader!../community-overview-panel/Camera.svg';
import useFileSelector from 'utils/hooks/useFileSelector';
import { cssUrl } from 'utils/css';
import C from './CommunityInfoForm.css';
import { getCommunityColor } from 'components/min-sidebar-community-list/MinSidebarCommunityList';
import ModalDialog from 'components/modal-dialog/ModalDialog';
import FlexCol from 'components/flex/FlexCol';
import CommunityMessageInput from 'components/community-message-input/CommunityMessageInput';
import { useUserProfile } from 'atoms/user-profile';
import { useOwnOrganizations } from 'atoms/organizations';
import _ from 'lodash';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Typography } from '@mui/material';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

const maxDescriptionLen = 100;
const maxNameLen = 75;

interface CommunityInput {
  name: string;
  description?: string;
}

export default function CommunityInfoForm(p: {
  community?: CommunityDetails;
  organization?: Partial<OrganizationInfo>;
  awaitingOrganizationId?: boolean;
  additionalTargetNames?: string[];
  type?: 'ORG' | 'PERSONAL';
  onSuccess?: (p: { id: string; educationalAccessApproved: boolean }) => void;
}) {
  const { _ } = useLingui();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: p.community?.name ?? p.organization?.name ?? '',
      description: p.community?.description ?? '',
    },
  });
  const [isDispatching, setDispatching] = useState(false);
  const { userProfile } = useUserProfile();
  const {
    organizations,
    syncState: orgListSyncState,
    loadOrganizations,
  } = useOwnOrganizations();

  const [message, setMessage] = useState<string>('');

  const [nameFocused, setNameFocused] = useState(false);

  const [descriptionLen, setDescriptionLen] = useState(0);
  const { patchCommunityDetails } = useCommunityDetails({
    communityId: p.community?.id,
    autoLoad: false,
  });
  const { file, triggerRef } = useFileSelector();

  const track = useTrack();

  const bkgUrl = useMemo(() => {
    if (!file) return p.community?.avatarUrl;
    return URL.createObjectURL(file);
  }, [file]);

  const communityColor =
    getCommunityColor(p.community?.id) ?? 'rgba(0,0,0,0.06)';

  const [pendingCommunityInput, setPendingCommunityInput] =
    useState<CommunityInput | null>(null);

  useEffect(() => {
    if (!p.awaitingOrganizationId && pendingCommunityInput) {
      setPendingCommunityInput(null);
      createCommunity(pendingCommunityInput);
    }
  }, [p.awaitingOrganizationId, pendingCommunityInput]);

  useEffect(() => {
    if (
      orgListSyncState === 'pending' &&
      !p.organization &&
      !p.awaitingOrganizationId
    ) {
      loadOrganizations();
    }
  }, [orgListSyncState]);

  return (
    <>
      <form
        onSubmit={handleSubmit(p.community ? updateCommunity : createCommunity)}
        autoComplete='off'
      >
        <Grid container spacing={2} maxWidth='500px'>
          <ErrorList errors={errors} />
          <Grid item xs={1}>
            <LightTooltip
              arrow
              placement='top'
              title={_(msg`Upload icon (optional)`)}
            >
              <Button
                className={cc({
                  [C.uploadBtn]: true,
                  [C.withPreview]: !!bkgUrl,
                })}
                style={{
                  backgroundImage: bkgUrl ? cssUrl(bkgUrl) : 'none',
                  backgroundColor: bkgUrl ? communityColor : '#F0EAFD',
                }}
                variant='contained'
                size='large'
                ref={triggerRef}
                data-analytics={EVENTS.CORRIDOR_IMAGE_INPUT}
              >
                <CameraAltIcon
                  style={{
                    height: '39px',
                    width: '39px',
                  }}
                />
              </Button>
            </LightTooltip>
          </Grid>
          <Grid item xs={11}>
            <LightTooltip
              open={nameFocused}
              arrow
              placement='bottom'
              sx={{
                '& .MuiTooltip-tooltip': {
                  maxWidth: '400px',
                },
              }}
              title={
                p.type === 'PERSONAL' ? (
                  <Typography p={1} textAlign='center'>
                    <Trans>
                      Pick a fun name that represents <strong>everyone</strong>{' '}
                      <br />
                      in the group, not just one person.
                    </Trans>
                  </Typography>
                ) : (
                  ''
                )
              }
            >
              <Box>
                <ControlledTextField
                  {...{ control }}
                  register={register('name', {
                    required: true,
                  })}
                  name='name'
                  required
                  placeholder={_(msg`Corridor Name`)}
                  InputProps={{
                    onFocus: () => {
                      track(EVENTS.CORRIDOR_NAME_INPUT, INTERACTIONS.FOCUS);
                      setNameFocused(true);
                    },
                    onBlur: () => {
                      setNameFocused(false);
                    },
                  }}
                  interceptField={(field) => ({
                    ...field,
                    onChange: (e) => {
                      let len = e.currentTarget.value.length;
                      if (len > maxNameLen) {
                        e.currentTarget.value = e.currentTarget.value.slice(
                          0,
                          maxNameLen,
                        );
                        len = maxNameLen;
                      }
                      return field.onChange(e);
                    },
                  })}
                />
              </Box>
            </LightTooltip>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              {...{ control }}
              multiline
              InputProps={{
                rows: 3,
              }}
              placeholder={_(msg`Tagline / Description (optional)`)}
              register={register('description', {
                maxLength: maxDescriptionLen,
              })}
              inputProps={{
                onFocus: () => {
                  track(EVENTS.CORRIDOR_DESCRIPTION_INPUT, INTERACTIONS.FOCUS);
                },
              }}
              interceptField={(field) => ({
                ...field,
                onChange: (e) => {
                  let len = e.currentTarget.value.length;
                  if (len > maxDescriptionLen) {
                    e.currentTarget.value = e.currentTarget.value.slice(
                      0,
                      maxDescriptionLen,
                    );
                    len = maxDescriptionLen;
                  }
                  setDescriptionLen(len);
                  return field.onChange(e);
                },
              })}
              name='description'
            />
            <FormHelperText style={{ textAlign: 'right' }}>
              <Trans>
                {Math.max(0, maxDescriptionLen - descriptionLen)} chars
                remaining
              </Trans>
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isDispatching}
              size='large'
              fullWidth
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => {
                track(EVENTS.NEXT, INTERACTIONS.CLICK, {
                  hasImage: !!file,
                  hasDescription: !!getValues('description'),
                });
              }}
            >
              <strong>
                {p.community ? (
                  <Trans>Update Corridor</Trans>
                ) : (
                  <Trans>Next</Trans>
                )}
              </strong>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );

  async function updateCommunity(input: {
    name: string;
    description?: string;
  }) {
    try {
      setDispatching(true);
      const formData = new FormData();
      formData.append(
        'input',
        JSON.stringify({
          name: input.name,
          description: input.description,
        }),
      );
      if (file) {
        formData.append('avatar', file);
      }
      const { data } = await httpClient.put(
        `/community/${p.community?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      patchCommunityDetails((c) => ({
        ...c,
        ...input,
        avatarUrl: data.avatarUrl,
      }));
      await p.onSuccess?.({
        id: data.id,
        educationalAccessApproved: data.educationalAccessApproved,
      });
    } catch (error: any) {
      console.error(error);
      reportServerError({
        title: _(msg`Failed to update community`),
        error,
      });
    } finally {
      setDispatching(false);
    }
  }

  async function createCommunity(input: CommunityInput) {
    try {
      // if (!userProfile?.instanceRoles.includes('PRIMARY_COMMUNITY_BUILDER') && !showRequest) {
      //   setShowRequest(true);
      //   return;
      // }
      setDispatching(true);
      if (p.awaitingOrganizationId) {
        setPendingCommunityInput(input);
        return;
      }
      const formData = new FormData();
      formData.append(
        'input',
        JSON.stringify({
          name: input.name,
          description: input.description,
          organizationId: p.organization?.id,
          targetNames: p.additionalTargetNames,
          message: message,
        }),
      );
      if (file) {
        formData.append('avatar', file);
      }
      const { data } = await httpClient.post('/community', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await p.onSuccess?.({
        id: data.id,
        educationalAccessApproved: data.educationalAccessApproved,
      });
      communityCache.storeLastCommunityId(data.id);
      communityCache.storeCommunityInfo({
        id: data.id,
        name: input.name,
        description: input.description,
        avatarUrl: data.avatarUrl,
        isActive: data.isActive,
      });
    } catch (error: any) {
      console.error(error);
      reportServerError({
        title: _(msg`Failed to create new corridor`),
        error,
      });
    } finally {
      setDispatching(false);
    }
  }
}
