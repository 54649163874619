import zipObject from "lodash/zipObject"

export const NOTIFICATION_EVENT_NAMES = [
    "INVITED_TO_COMMUNITY_BY_MEMBER",
    "INVITED_TO_SPACE_BY_MEMBER",
    "COMMUNITY_MEMBERSHIP_REQ_APPROVED",
    "COMMUNITY_INVITATION_REQ_ACCEPTED",
    "COMMUNITY_NEW_MEMBER",
    "NEW_OPEN_TO_JOIN_SPACE",
] as const

export type NotificationEventName = typeof NOTIFICATION_EVENT_NAMES[number]

export const NOTIFICATION_EVENTS = zipObject(
    NOTIFICATION_EVENT_NAMES,
    NOTIFICATION_EVENT_NAMES
) as { [K in NotificationEventName]: K }
