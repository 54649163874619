import { atom } from "nanostores";
import { Dispatcher } from "./actions";

export enum ConnectionQuality {
    Excellent,
    Good,
    Average,
    Poor,
}

export enum ConnectionStatus {
    NotStarted,
    Connected,
    Disconnected,
    Concluded,
    Failed,
}

export interface ConnectivityState {
    websocket: {
        status: ConnectionStatus,
        quality: ConnectionQuality,
    };
    outgoingMedia: {
        status: ConnectionStatus,
        quality: ConnectionQuality,
    };
    incomingMedia: {
        status: ConnectionStatus,
        quality: ConnectionQuality,
    };
}

export const connectivityState = atom<ConnectivityState>({
    websocket: {
        status: ConnectionStatus.NotStarted,
        quality: ConnectionQuality.Poor,
    },
    outgoingMedia: {
        status: ConnectionStatus.NotStarted,
        quality: ConnectionQuality.Poor,
    },
    incomingMedia: {
        status: ConnectionStatus.NotStarted,
        quality: ConnectionQuality.Poor,
    }
});

export const mediaFailurePopupDismissed = atom<boolean>(false);

export const setWebsocketConnectionQuality = (quality: ConnectionQuality) => {
    const state = connectivityState.get();
    state.websocket.quality = quality;
    connectivityState.set({
        ...state,
    });
};

export const setOutgoingMediaConnectionQuality = (quality: ConnectionQuality) => {
    const state = connectivityState.get();
    state.outgoingMedia.quality = quality;

    connectivityState.set({
        ...state,
    });
};


export const setIncomingMediaConnectionQuality = (quality: ConnectionQuality) => {
    const state = connectivityState.get();
    state.incomingMedia.quality = quality;
    connectivityState.set({
        ...state,
    });
}


export const setWebsocketConnectionStatus = (status: ConnectionStatus) => {
    const state = connectivityState.get();
    state.websocket.status = status;
    connectivityState.set({
        ...state,
    });
}

export const setOutgoingMediaConnectionStatus = (status: ConnectionStatus) => {
    const state = connectivityState.get();
    state.outgoingMedia.status = status;

    if (status === ConnectionStatus.Failed && !mediaFailurePopupDismissed.get()) {
        Dispatcher.openMediaFailureModal();
    }

    connectivityState.set({
        ...state,
    });
}

export const setIncomingMediaConnectionStatus = (status: ConnectionStatus) => {
    const state = connectivityState.get();
    state.incomingMedia.status = status;

    if (status === ConnectionStatus.Failed && !mediaFailurePopupDismissed.get()) {
        Dispatcher.openMediaFailureModal();
    }
    
    connectivityState.set({
        ...state,
    });
}
