import CloseableDialog from 'components/modal-dialog/CloseableDialog';
import React from 'react';

import C from './coco-modal.css';
import { Box, Stack } from '@mui/material';

export default function CocoModal({
  onClose,
  header,
  children,
  ...p
}: {
  onClose: () => void;
  header: string | React.ReactNode;
  children: React.ReactNode;
  p?: any;
}) {
  return (
    <CloseableDialog onClose={onClose} {...p}>
      <Stack className={C.cocoModal}>
        <header className={C.modalHeader}>{header}</header>
        <Box className={C.modalContent}>{children}</Box>
      </Stack>
    </CloseableDialog>
  );
}
