import { SpaceVarsMessage, ListSpaceVarMessage } from "@cocoplatform/coco-rtc-shared"
import { Evt } from "evt"
import { atom } from "./utils/store"

export let spaceVars = atom<ListSpaceVarMessage["variables"]>([])

export const spaceVarsEmitter = new Evt<SpaceVarsMessage>()

spaceVarsEmitter.attach((action) => {
    if (action.type === "list-space-var") {
        spaceVars.set(action.variables)
    }
})
