import { FormControlLabel, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { Controller } from 'react-hook-form';

export default function ControlledCheckbox(p: {
  control: any; // TODO FIXME
  register?: any; // TODO FIXME
  placeholder?: string;
  rules?: any;
  name: string;
  onClick?: (val: boolean) => void;
}) {
  return (
    <Controller
      name={p.name}
      control={p.control}
      rules={p.rules}
      render={({ field }) => (
        <Checkbox
          {...field}
          checked={field.value}
          onChange={(e) => {
            field.onChange(e);
            if (p.onClick) {
              p.onClick(e.target.checked);
            }
          }}
        />
      )}
    />
  );
}
