import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import ControlLabel from 'components/control-label/ControlLabel';
import React from 'react';
import { Controller } from 'react-hook-form';

export default function ControlledDropdown(p: {
  control: any; // TODO FIXME
  register?: any; // TODO FIXME
  error?: boolean; // TODO FIXME
  placeholder?: string;
  name: string;
  items: { value: string; name?: string }[];
  rules?: any;
  multiple?: boolean;
  onFocus?: () => void;
  onItemSelect?: (value: string) => void;
}) {
  return (
    <Controller
      control={p.control}
      name={p.name}
      rules={p.rules}
      render={({ field }) => (
        <Select
          onFocus={p.onFocus}
          {...field}
          fullWidth
          variant='filled'
          displayEmpty
          multiple={p.multiple}
          style={{
            ...(p.error && {
              border: '1px solid red',
            }),
          }}
          renderValue={(selected) => {
            if (p.multiple) {
              return (selected as string[]).join(', ');
            }
            return selected;
          }}
          sx={{
            '& .MuiSelect-select .notranslate::after': p.placeholder
              ? {
                  content: `"${p.placeholder}"`,
                  opacity: 0.42,
                }
              : {},
          }}
          // renderValue={value => value?.length ? Array.isArray(value) ? value.join(', ') : value : p.placeholder}
          // renderValue={(value) => value === '' ? p.placeholder : value}
          error={p.error}
        >
          {p.items.map((it) => (
            <MenuItem
              onClick={() => {
                if (p.onItemSelect) {
                  p.onItemSelect(it.value);
                }
              }}
              value={it.value}
            >
              {p.multiple && (
                <Checkbox checked={field.value.indexOf(it.value) > -1} />
              )}
              <ListItemText>{it.name || it.value}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
