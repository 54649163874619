import { msg } from '@lingui/macro';

export enum COMMUNITY_PERMISSION_TYPES {
  MEMBER,
  MANAGER,
  ADMIN,
}

export const COMMUNITY_PERMISSIONS_TEXT = {
  [COMMUNITY_PERMISSION_TYPES.MEMBER]: msg`Can create and explore CoCo spaces in this corridor.`,
  [COMMUNITY_PERMISSION_TYPES.MANAGER]: msg`Can delete spaces, add / remove members, and update media library / corridor settings.`,
  [COMMUNITY_PERMISSION_TYPES.ADMIN]: msg`Can edit corridor details and delete this corridor.`
}

export const COMMUNITY_MEMBERSHIP_ROLES = [
  {
    value: 'MEMBER',
    label: msg`Member`,
    isDisplayed: false,
    isSelectable: true,
    requiresEmail: false,
    permissions: [
      COMMUNITY_PERMISSION_TYPES.MEMBER,
    ]
  },
  {
    // Currently bypassed in the ui completely
    value: 'HOST',
    label: msg`Host`,
    isDisplayed: false,
    isSelectable: false,
  },
  {
    value: 'MANAGER',
    label: msg`Manager`,
    isDisplayed: true,
    adviseCaution: true,
    isSelectable: true,
    requiresEmail: true,
    permissions: [
      COMMUNITY_PERMISSION_TYPES.MEMBER,
      COMMUNITY_PERMISSION_TYPES.MANAGER,
    ]
  },
  {
    value: 'OWNER',
    label: msg`Admin`,
    isDisplayed: true,
    adviseCaution: true,
    isSelectable: true,
    requiresEmail: true,
    permissions: [
      COMMUNITY_PERMISSION_TYPES.MEMBER,
      COMMUNITY_PERMISSION_TYPES.MANAGER,
      COMMUNITY_PERMISSION_TYPES.ADMIN,
    ]
  },
  {
    value: 'SYSADMIN',
    label: msg`SysAdmin`,
    isDisplayed: false,
    isSelectable: false,
    requiresEmail: true,
  },
];

export type MembershipRole = (typeof COMMUNITY_MEMBERSHIP_ROLES)[number];

const getRoleIdx = (role: string) => {
  return COMMUNITY_MEMBERSHIP_ROLES.findIndex((r) => r.value === role);
};

const getMaxRoleIdx = (roles: string[]) => {
  const indexes = roles.map(getRoleIdx);
  return Math.max(...indexes);
};

export const isLTE = (roles: string[], refRole: string) => {
  const maxIdx = getMaxRoleIdx(roles);
  const refIdx = getRoleIdx(refRole);
  return maxIdx <= refIdx;
};

export const isGTE = (roles: string[], refRole: string) => {
  const maxIdx = getMaxRoleIdx(roles);
  const refIdx = getRoleIdx(refRole);
  return maxIdx >= refIdx;
};

export const getSameOrLowerRoles = (
  ownRoles: string[],
  filterSelectable = true,
) => {
  const indexes = ownRoles.map((roleName) => {
    return COMMUNITY_MEMBERSHIP_ROLES.findIndex((r) => r.value === roleName);
  });
  const maxIdx = Math.max(...indexes);
  const roles = COMMUNITY_MEMBERSHIP_ROLES.slice(0, maxIdx + 1);
  if (filterSelectable) {
    return roles.filter((it) => it.isSelectable);
  }
  return roles;
};

export const isOwner = (roles: string[]) => isGTE(roles, 'OWNER');
export const isManager = (roles: string[]) => isGTE(roles, 'MANAGER');
export const isHost = (roles: string[]) => isGTE(roles, 'HOST');
