import React, { useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  LOCALE_STORAGE_KEY,
  loadI18n,
  localeStateAtom,
  updatedLocaleStateAtom,
} from 'atoms/locale';

loadI18n('en');

const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const locale = useRecoilValue(localeStateAtom);

  const [updatedLocale, setUpdatedLocale] = useRecoilState(
    updatedLocaleStateAtom,
  );

  useEffect(() => {
    // const persistedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);
    // // let localeToUpdate = locale;
    // // if (persistedLocale) {
    // //   localeToUpdate = JSON.parse(persistedLocale);
    // // }
    loadI18n(locale).then(() => {
      setUpdatedLocale(locale);
    });
  }, [locale]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default LocaleProvider;
