import {
  Community,
  CommunityDetails,
  CommunityDetailsSchema,
  CommunitySchema,
} from '@cocoplatform/coco-rtc-shared';
import { browserStorage } from 'utils/browser-storage';

const LAST_COMMUNITY_ID_KEY = 'coco:u:$user:community:lastid';
const COMMUNITY_INFO_KEY_PREFIX = 'coco:u:$user:community:info:';
const COMMUNITY_DETAILS_KEY_PREFIX = 'coco:u:$user:community:details:';

// Local storage is not reliable esp. in incognito etc.
// so we need to ensure that browserStorage access failure does not
// block the rest of the flow

export const storeLastCommunityId = (id: string) => {
  try {
    browserStorage.setItem(LAST_COMMUNITY_ID_KEY, id);
  } catch (e) {
    console.error(e);
  }
};

export const storeCommunityInfo = (community: Community) => {
  try {
    browserStorage.setItem(
      COMMUNITY_INFO_KEY_PREFIX + community.id,
      JSON.stringify(community),
    );
  } catch (e) {
    console.error(e);
  }
};

export const storeCommunityDetails = (community: CommunityDetails) => {
  try {
    browserStorage.setItem(
      COMMUNITY_DETAILS_KEY_PREFIX + community.id,
      JSON.stringify(community),
    );
  } catch (e) {
    console.error(e);
  }
};

export const getCommunityInfo = (id: string) => {
  const raw = browserStorage.getItem(COMMUNITY_INFO_KEY_PREFIX + id);
  if (!raw) return null;
  try {
    return CommunitySchema.parse(JSON.parse(raw));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getCommunityDetails = (id: string) => {
  const raw = browserStorage.getItem(COMMUNITY_DETAILS_KEY_PREFIX + id);
  if (!raw) return null;
  try {
    return CommunityDetailsSchema.parse(JSON.parse(raw));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getLastCommunityId = () => {
  return browserStorage.getItem(LAST_COMMUNITY_ID_KEY);
};

export const removeCommunity = (id: string) => {
  if (getLastCommunityId() === id) {
    browserStorage.removeItem(LAST_COMMUNITY_ID_KEY);
  }
  browserStorage.removeItem(COMMUNITY_INFO_KEY_PREFIX + id);
  browserStorage.removeItem(COMMUNITY_DETAILS_KEY_PREFIX + id);
};
