import React from 'react';

export default function PlatformInfoFieldSet() {
  return (
    <>
      <input type='hidden' value={navigator.product} name='navProduct' />
      <input type='hidden' value={navigator.oscpu} name='navOSCPU' />
      <input type='hidden' value={navigator.platform} name='navPlatform' />
      <input type='hidden' value={navigator.userAgent} name='navUserAgent' />
      <input type='hidden' value={navigator.language} name='navLanguage' />
    </>
  );
}
