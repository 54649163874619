import { t } from '@lingui/macro';

export const getCountryListForDropdown = () =>
  getCountryList().map((it) => ({
    value: it.code,
    name: it.display || it.name,
  }));

const getCountryList = () => [
  {
    name: t`Select Country`,
    code: '',
  },
  {
    name: t`Afghanistan`,
    code: 'af',
  },
  {
    name: t`Aland Islands`,
    display: t`Åland Islands`,
    code: 'ax',
  },
  {
    name: t`Albania`,
    code: 'al',
  },
  {
    name: t`Algeria`,
    code: 'dz',
  },
  {
    name: t`American Samoa`,
    code: 'as',
  },
  {
    name: t`Andorra`,
    code: 'ad',
  },
  {
    name: t`Angola`,
    code: 'ao',
  },
  {
    name: t`Anguilla`,
    code: 'ai',
  },
  {
    name: t`Antarctica`,
    code: 'aq',
  },
  {
    name: t`Antigua and Barbuda`,
    code: 'ag',
  },
  {
    name: t`Argentina`,
    code: 'ar',
  },
  {
    name: t`Armenia`,
    code: 'am',
  },
  {
    name: t`Aruba`,
    code: 'aw',
  },
  {
    name: t`Australia`,
    code: 'au',
  },
  {
    name: t`Austria`,
    code: 'at',
  },
  {
    name: t`Azerbaijan`,
    code: 'az',
  },
  {
    name: t`Bahamas`,
    code: 'bs',
  },
  {
    name: t`Bahrain`,
    code: 'bh',
  },
  {
    name: t`Bangladesh`,
    code: 'bd',
  },
  {
    name: t`Barbados`,
    code: 'bb',
  },
  {
    name: t`Belarus`,
    code: 'by',
  },
  {
    name: t`Belgium`,
    code: 'be',
  },
  {
    name: t`Belize`,
    code: 'bz',
  },
  {
    name: t`Benin`,
    code: 'bj',
  },
  {
    name: t`Bermuda`,
    code: 'bm',
  },
  {
    name: t`Bhutan`,
    code: 'bt',
  },
  {
    name: t`Bolivia`,
    code: 'bo',
  },
  {
    name: t`Bonaire, Sint Eustatius and Saba`,
    code: 'bq',
  },
  {
    name: t`Bosnia and Herzegovina`,
    code: 'ba',
  },
  {
    name: t`Botswana`,
    code: 'bw',
  },
  {
    name: t`Bouvet Island`,
    code: 'bv',
  },
  {
    name: t`Brazil`,
    code: 'br',
  },
  {
    name: t`British Indian Ocean Territory`,
    code: 'io',
  },
  {
    name: t`Brunei`,
    display: t`Brunei Darussalami`,
    code: 'bn',
  },
  {
    name: t`Bulgaria`,
    code: 'bg',
  },
  {
    name: t`Burkina Faso`,
    code: 'bf',
  },
  {
    name: t`Burundi`,
    code: 'bi',
  },
  {
    name: t`Cambodia`,
    code: 'kh',
  },
  {
    name: t`Cameroon`,
    code: 'cm',
  },
  {
    name: t`Canada`,
    code: 'ca',
  },
  {
    name: t`Cape Verde`,
    code: 'cv',
  },
  {
    name: t`Cayman Islands`,
    code: 'ky',
  },
  {
    name: t`Central African Republic`,
    code: 'cf',
  },
  {
    name: t`Chad`,
    code: 'td',
  },
  {
    name: t`Chile`,
    code: 'cl',
  },
  {
    name: t`China`,
    code: 'cn',
  },
  {
    name: t`Christmas Island`,
    code: 'cx',
  },
  {
    name: t`Cocos (Keeling) Islands`,
    code: 'cc',
  },
  {
    name: t`Colombia`,
    code: 'co',
  },
  {
    name: t`Comoros`,
    code: 'km',
  },
  {
    name: t`Congo`,
    code: 'cg',
  },
  {
    name: t`Congo, The Democratic Republic of the`,
    code: 'cd',
  },
  {
    name: t`Cook Islands`,
    code: 'ck',
  },
  {
    name: t`Costa Rica`,
    code: 'cr',
  },
  {
    name: t`Cote d'Ivoire`,
    display: t`Côte d'Ivoire`,
    code: 'ci',
  },
  {
    name: t`Croatia`,
    code: 'hr',
  },
  {
    name: t`Cuba`,
    code: 'cu',
  },
  {
    name: t`Curacao`,
    display: t`Curaçao`,
    code: 'cw',
  },
  {
    name: t`Cyprus`,
    code: 'cy',
  },
  {
    name: t`Czech Republic`,
    code: 'cz',
  },
  {
    name: t`Denmark`,
    code: 'dk',
  },
  {
    name: t`Djibouti`,
    code: 'dj',
  },
  {
    name: t`Dominica`,
    code: 'dm',
  },
  {
    name: t`Dominican Republic`,
    code: 'do',
  },
  {
    name: t`Ecuador`,
    code: 'ec',
  },
  {
    name: t`Egypt`,
    code: 'eg',
  },
  {
    name: t`El Salvador`,
    code: 'sv',
  },
  {
    name: t`Equatorial Guinea`,
    code: 'gq',
  },
  {
    name: t`Eritrea`,
    code: 'er',
  },
  {
    name: t`Estonia`,
    code: 'ee',
  },
  {
    name: t`Swaziland`,
    display: t`Eswatini`,
    code: 'sz',
  },
  {
    name: t`Ethiopia`,
    code: 'et',
  },
  {
    name: t`Falkland Islands (Malvinas)`,
    code: 'fk',
  },
  {
    name: t`Faroe Islands`,
    code: 'fo',
  },
  {
    name: t`Fiji`,
    code: 'fj',
  },
  {
    name: t`Finland`,
    code: 'fi',
  },
  {
    name: t`France`,
    code: 'fr',
  },
  {
    name: t`French Guiana`,
    code: 'gf',
  },
  {
    name: t`French Polynesia`,
    code: 'pf',
  },
  {
    name: t`French Southern Territories`,
    code: 'tf',
  },
  {
    name: t`Gabon`,
    code: 'ga',
  },
  {
    name: t`Gambia`,
    code: 'gm',
  },
  {
    name: t`Georgia`,
    code: 'ge',
  },
  {
    name: t`Germany`,
    code: 'de',
  },
  {
    name: t`Ghana`,
    code: 'gh',
  },
  {
    name: t`Gibraltar`,
    code: 'gi',
  },
  {
    name: t`Greece`,
    code: 'gr',
  },
  {
    name: t`Greenland`,
    code: 'gl',
  },
  {
    name: t`Grenada`,
    code: 'gd',
  },
  {
    name: t`Guadeloupe`,
    code: 'gp',
  },
  {
    name: t`Guam`,
    code: 'gu',
  },
  {
    name: t`Guatemala`,
    code: 'gt',
  },
  {
    name: t`Guernsey`,
    code: 'gg',
  },
  {
    name: t`Guinea`,
    code: 'gn',
  },
  {
    name: t`Guinea-Bissau`,
    code: 'gw',
  },
  {
    name: t`Guyana`,
    code: 'gy',
  },
  {
    name: t`Haiti`,
    code: 'ht',
  },
  {
    name: t`Heard Island and McDonald Islands`,
    code: 'hm',
  },
  {
    name: t`Honduras`,
    code: 'hn',
  },
  {
    name: t`Hong Kong`,
    code: 'hk',
  },
  {
    name: t`Hungary`,
    code: 'hu',
  },
  {
    name: t`Iceland`,
    code: 'is',
  },
  {
    name: t`India`,
    code: 'in',
  },
  {
    name: t`Indonesia`,
    code: 'id',
  },
  {
    name: t`Iran`,
    code: 'ir',
  },
  {
    name: t`Iraq`,
    code: 'iq',
  },
  {
    name: t`Ireland`,
    code: 'ie',
  },
  {
    name: t`Isle of Man`,
    code: 'im',
  },
  {
    name: t`Israel`,
    code: 'il',
  },
  {
    name: t`Italy`,
    code: 'it',
  },
  {
    name: t`Jamaica`,
    code: 'jm',
  },
  {
    name: t`Japan`,
    code: 'jp',
  },
  {
    name: t`Jersey`,
    code: 'je',
  },
  {
    name: t`Jordan`,
    code: 'jo',
  },
  {
    name: t`Kazakhstan`,
    code: 'kz',
  },
  {
    name: t`Kenya`,
    code: 'ke',
  },
  {
    name: t`Kiribati`,
    code: 'ki',
  },
  {
    name: t`North Korea`,
    display: t`Korea, Democratic People's Republic of`,
    code: 'kp',
  },
  {
    name: t`South Korea`,
    display: t`Korea, Republic of`,
    code: 'kr',
  },
  {
    name: t`Kosovo`,
    code: 'xk',
  },
  {
    name: t`Kuwait`,
    code: 'kw',
  },
  {
    name: t`Kyrgyzstan`,
    code: 'kg',
  },
  {
    name: t`Laos`,
    code: 'la',
  },
  {
    name: t`Latvia`,
    code: 'lv',
  },
  {
    name: t`Lebanon`,
    code: 'lb',
  },
  {
    name: t`Lesotho`,
    code: 'ls',
  },
  {
    name: t`Liberia`,
    code: 'lr',
  },
  {
    name: t`Libya`,
    code: 'ly',
  },
  {
    name: t`Liechtenstein`,
    code: 'li',
  },
  {
    name: t`Lithuania`,
    code: 'lt',
  },
  {
    name: t`Luxembourg`,
    code: 'lu',
  },
  {
    name: t`Macao`,
    code: 'mo',
  },
  {
    name: t`Madagascar`,
    code: 'mg',
  },
  {
    name: t`Malawi`,
    code: 'mw',
  },
  {
    name: t`Malaysia`,
    code: 'my',
  },
  {
    name: t`Maldives`,
    code: 'mv',
  },
  {
    name: t`Mali`,
    code: 'ml',
  },
  {
    name: t`Malta`,
    code: 'mt',
  },
  {
    name: t`Marshall Islands`,
    code: 'mh',
  },
  {
    name: t`Martinique`,
    code: 'mq',
  },
  {
    name: t`Mauritania`,
    code: 'mr',
  },
  {
    name: t`Mauritius`,
    code: 'mu',
  },
  {
    name: t`Mayotte`,
    code: 'yt',
  },
  {
    name: t`Mexico`,
    code: 'mx',
  },
  {
    name: t`Micronesia, Federated States of`,
    code: 'fm',
  },
  {
    name: t`Moldova`,
    code: 'md',
  },
  {
    name: t`Monaco`,
    code: 'mc',
  },
  {
    name: t`Mongolia`,
    code: 'mn',
  },
  {
    name: t`Montenegro`,
    code: 'me',
  },
  {
    name: t`Montserrat`,
    code: 'ms',
  },
  {
    name: t`Morocco`,
    code: 'ma',
  },
  {
    name: t`Mozambique`,
    code: 'mz',
  },
  {
    name: t`Myanmar`,
    code: 'mm',
  },
  {
    name: t`Namibia`,
    code: 'na',
  },
  {
    name: t`Nauru`,
    code: 'nr',
  },
  {
    name: t`Nepal`,
    code: 'np',
  },
  {
    name: t`Netherlands`,
    code: 'nl',
  },
  {
    name: t`Netherlands Antilles`,
    code: 'an',
  },
  {
    name: t`New Caledonia`,
    code: 'nc',
  },
  {
    name: t`New Zealand`,
    code: 'nz',
  },
  {
    name: t`Nicaragua`,
    code: 'ni',
  },
  {
    name: t`Niger`,
    code: 'ne',
  },
  {
    name: t`Nigeria`,
    code: 'ng',
  },
  {
    name: t`Niue`,
    code: 'nu',
  },
  {
    name: t`Norfolk Island`,
    code: 'nf',
  },
  {
    name: t`Macedonia, The Former Yugoslav Republic of`,
    display: t`North Macedonia`,
    code: 'mk',
  },
  {
    name: t`Northern Mariana Islands`,
    code: 'mp',
  },
  {
    name: t`Norway`,
    code: 'no',
  },
  {
    name: t`Oman`,
    code: 'om',
  },
  {
    name: t`Pakistan`,
    code: 'pk',
  },
  {
    name: t`Palau`,
    code: 'pw',
  },
  {
    name: t`Palestine, State of`,
    code: 'ps',
  },
  {
    name: t`Panama`,
    code: 'pa',
  },
  {
    name: t`Papua New Guinea`,
    code: 'pg',
  },
  {
    name: t`Paraguay`,
    code: 'py',
  },
  {
    name: t`Peru`,
    code: 'pe',
  },
  {
    name: t`Philippines`,
    code: 'ph',
  },
  {
    name: t`Pitcairn`,
    code: 'pn',
  },
  {
    name: t`Poland`,
    code: 'pl',
  },
  {
    name: t`Portugal`,
    code: 'pt',
  },
  {
    name: t`Puerto Rico`,
    code: 'pr',
  },
  {
    name: t`Qatar`,
    code: 'qa',
  },
  {
    name: t`Reunion`,
    code: 're',
  },
  {
    name: t`Romania`,
    code: 'ro',
  },
  {
    name: t`Russia`,
    code: 'ru',
  },
  {
    name: t`Rwanda`,
    code: 'rw',
  },
  {
    name: t`Saint Barthelemy`,
    display: t`Saint Barthélemy`,
    code: 'bl',
  },
  {
    name: t`Saint Helena`,
    display: t`Saint Helena, Ascension and Tristan Da Cunha`,
    code: 'sh',
  },
  {
    name: t`Saint Kitts and Nevis`,
    code: 'kn',
  },
  {
    name: t`Saint Lucia`,
    code: 'lc',
  },
  {
    name: t`Saint Martin`,
    code: 'mf',
  },
  {
    name: t`Saint Pierre and Miquelon`,
    code: 'pm',
  },
  {
    name: t`Saint Vincent and the Grenadines`,
    code: 'vc',
  },
  {
    name: t`Samoa`,
    code: 'ws',
  },
  {
    name: t`San Marino`,
    code: 'sm',
  },
  {
    name: t`Sao Tome and Principe`,
    code: 'st',
  },
  {
    name: t`Saudi Arabia`,
    code: 'sa',
  },
  {
    name: t`Senegal`,
    code: 'sn',
  },
  {
    name: t`Serbia`,
    code: 'rs',
  },
  {
    name: t`Seychelles`,
    code: 'sc',
  },
  {
    name: t`Sierra Leone`,
    code: 'sl',
  },
  {
    name: t`Singapore`,
    code: 'sg',
  },
  {
    name: t`Sint Maarten`,
    code: 'sx',
  },
  {
    name: t`Slovakia`,
    code: 'sk',
  },
  {
    name: t`Slovenia`,
    code: 'si',
  },
  {
    name: t`Solomon Islands`,
    code: 'sb',
  },
  {
    name: t`Somalia`,
    code: 'so',
  },
  {
    name: t`South Africa`,
    code: 'za',
  },
  {
    name: t`South Georgia and the South Sandwich Islands`,
    code: 'gs',
  },
  {
    name: t`South Sudan`,
    code: 'ss',
  },
  {
    name: t`Spain`,
    code: 'es',
  },
  {
    name: t`Sri Lanka`,
    code: 'lk',
  },
  {
    name: t`Sudan`,
    code: 'sd',
  },
  {
    name: t`Suriname`,
    code: 'sr',
  },
  {
    name: t`Svalbard and Jan Mayen`,
    code: 'sj',
  },
  {
    name: t`Sweden`,
    code: 'se',
  },
  {
    name: t`Switzerland`,
    code: 'ch',
  },
  {
    name: t`Syria`,
    code: 'sy',
  },
  {
    name: t`Taiwan`,
    code: 'tw',
  },
  {
    name: t`Tajikistan`,
    code: 'tj',
  },
  {
    name: t`Tanzania`,
    code: 'tz',
  },
  {
    name: t`Thailand`,
    code: 'th',
  },
  {
    name: t`Timor-Leste`,
    code: 'tl',
  },
  {
    name: t`Togo`,
    code: 'tg',
  },
  {
    name: t`Tokelau`,
    code: 'tk',
  },
  {
    name: t`Tonga`,
    code: 'to',
  },
  {
    name: t`Trinidad and Tobago`,
    code: 'tt',
  },
  {
    name: t`Tunisia`,
    code: 'tn',
  },
  {
    name: t`Turkey`,
    code: 'tr',
  },
  {
    name: t`Turkmenistan`,
    code: 'tm',
  },
  {
    name: t`Turks and Caicos Islands`,
    code: 'tc',
  },
  {
    name: t`Tuvalu`,
    code: 'tv',
  },
  {
    name: t`Uganda`,
    code: 'ug',
  },
  {
    name: t`Ukraine`,
    code: 'ua',
  },
  {
    name: t`United Arab Emirates`,
    code: 'ae',
  },
  {
    name: t`United Kingdom`,
    code: 'gb',
  },
  {
    name: t`United States`,
    display: t`United States of America`,
    code: 'us',
  },
  {
    name: t`United States Minor Outlying Islands`,
    code: 'um',
  },
  {
    name: t`Uruguay`,
    code: 'uy',
  },
  {
    name: t`Uzbekistan`,
    code: 'uz',
  },
  {
    name: t`Vanuatu`,
    code: 'vu',
  },
  {
    name: t`Vatican City`,
    code: 'va',
  },
  {
    name: t`Venezuela`,
    code: 've',
  },
  {
    name: t`Vietnam`,
    code: 'vn',
  },
  {
    name: t`Virgin Islands, British`,
    code: 'vg',
  },
  {
    name: t`Virgin Islands, U.S.`,
    code: 'vi',
  },
  {
    name: t`Wallis and Futuna`,
    code: 'wf',
  },
  {
    name: t`Western Sahara`,
    code: 'eh',
  },
  {
    name: t`Yemen`,
    code: 'ye',
  },
  {
    name: t`Zambia`,
    code: 'zm',
  },
  {
    name: t`Zimbabwe`,
    code: 'zw',
  },
];

export default getCountryList;
