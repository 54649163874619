import * as z from "zod"

export const strNum = z
    .string()
    .or(z.number())
    .transform((it) => {
        if (it == null) return it
        return Number(it)
    })

export const strDate = z
    .string()
    .or(z.date())
    .transform((it): Date | null => {
        if (it == null || it instanceof Date) return it
        return new Date(it)
    })

export const optFlag = z
    .boolean()
    .or(z.string())
    .nullish()
    .transform((it) => {
        if (it === "true" || it === true) return true
        if (it === "false" || it === false) return false
        return undefined
    })
