import React from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import FeedbackIcon from '!!react-svg-loader!./icons/icon--feedback.svg';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import { useModal } from 'mui-modal-provider';
import BugReportModal from 'components/bug-report-modal/bug-report-modal';
import FeedbackModal from 'components/feedback-modal/feedback-modal';
import { Trans } from '@lingui/macro';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import { useAuthState } from 'utils/auth-state';
import { useFlags } from 'flagsmith/react';
import { Button, Link } from '@mui/material';

export default function FeedbackButton(p: {
  color?: string;
  fontWeight?: string;
}) {
  const { showModal } = useModal();
  const tutorial = useTutorial();
  const { isSignedIn, isProfileUpdated } = useAuthState();

  const { onboarding_tutorial } = useFlags(['onboarding_tutorial']);

  return (
    <>
      <PopupState variant='popover' popupId='feedback-btn'>
        {(popupState) => {
          return (
            <span>
              <Link
                style={{
                  color: p.color ?? 'white',
                  fontWeight: p.fontWeight ?? 'bold',
                  marginRight: '12px',
                }}
                {...bindTrigger(popupState)}
              >
                Feedback
              </Link>
              <Menu {...bindMenu(popupState)}>
                {isSignedIn && isProfileUpdated && (
                  <MenuItem
                    onClick={() => {
                      const modal = showModal(FeedbackModal, {
                        onClose: () => modal.destroy(),
                      });
                      popupState.close();
                    }}
                  >
                    <Trans>Share Feedback</Trans>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    const modal = showModal(BugReportModal, {
                      onClose: () => modal.destroy(),
                    });
                    popupState.close();
                  }}
                >
                  <Trans>Report an Issue</Trans>
                </MenuItem>
              </Menu>
            </span>
          );
        }}
      </PopupState>
      {/* {activeModal === 'feedback' && (
        <FeedbackModal
          closeFeedbackModal={() => {
            setActiveModal(null);
          }}
        />
      )} */}
    </>
  );
}
