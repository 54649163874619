import { CollabMessage, ServerMessage, SpaceVarsMessage, WsQueryParams } from "@cocoplatform/coco-rtc-shared";
import { Socket, io } from "socket.io-client"
import qs from "qs"
import { getServerBaseUrl } from "./http-client";
import { ConnectionQuality, ConnectionStatus, setWebsocketConnectionQuality, setWebsocketConnectionStatus } from "./connectivity-status";
import { Dispatcher } from "./actions";
import { CocoLogger } from "@cocoplatform/coco-logger";
import { client, handleCollabMessage, receiveClusterDetails, receiveParticipantList, receiveProjectDetails, receiveUserRemoved } from "./rtc-handlers";
import { onAddNewMessage, onMessageToCreator, onReplaceMessages, onSetSpaceVar, onWaveBroadcast } from "./data-channel-handler";
import { spaceVarsEmitter } from "./space-vars";


export interface RTCClientParams {
    spaceId: string
    authToken: string
    initialQueryParams: Record<string, any>
}

const SOCKET_REQUEST_BLACKLIST = [
    "refresh-participant-list",
];

export let socket: SocketClient | undefined = undefined;
export let spaceId: string | undefined = undefined;

export function ensureSocketConnected(params: RTCClientParams) {
    if (!socket) {
        socket = new SocketClient(params);
        spaceId = params.spaceId;
    }
    return socket;
}

export async function sendWSMessage<T>(
    event: T & { type?: string },
) {
    if (!socket) {
        throw new Error("Socket not connected");
    }
    return socket.sendWsMessage(event);
}

export async function sendWsMessageWithResponse<T>(
    event: T & { type?: string },
) {
    if (!socket) {
        throw new Error("Socket not connected");
    }
    return socket.sendWsMessageWithResponse(event);
}

export async function concludeSocket() {
    if (socket) {
        socket.concludeSocket();
        socket = undefined;
    }
}

export class SocketClient {
    private socket!: Socket;
    public spaceId: string | undefined;

    constructor(
        private params: RTCClientParams
    ) {
        this.spaceId = params.spaceId;
        this.connect();
    }

    private connect() {
        const queryParams: WsQueryParams = {
            room: this.params.spaceId,
            authToken: this.params.authToken,
        };

        this.socket = io(
            `${getServerBaseUrl().replace(
                /^http/,
                "ws"
            )}/?${qs.stringify(queryParams)}`
        )

        this.attachListeners();
    }

    private attachListeners() {
        this.socket.on("connect", () => {
            setWebsocketConnectionStatus(
                ConnectionStatus.Connected,
            );
            setWebsocketConnectionQuality(
                ConnectionQuality.Good, // Can be upgraded to exceltent after RTT calculation
            );
            CocoLogger.info(
                "Websocket::Connected"
            );
        });

        // Maintain round trip time EMA

        let roundTripTime = 0;
        const alpha = 0.6;

        let rttInterval = setInterval(() => {
            const start = Date.now();

            // volatile, so the packet will be discarded if the socket is not connected
            this.socket.volatile.emit("ping", () => {
                const reading = Date.now() - start;

                if (roundTripTime === 0) roundTripTime = reading;

                roundTripTime = alpha * reading + (1 - alpha) * roundTripTime;
                setWebsocketConnectionQuality(
                    roundTripTime < 100 ? ConnectionQuality.Excellent :
                        roundTripTime < 200 ? ConnectionQuality.Good :
                            roundTripTime < 300 ? ConnectionQuality.Average :
                                ConnectionQuality.Poor
                );

            });
        }, 5000);

        this.socket.on("disconnect", (reason) => {
            if (reason === "io client disconnect" || reason === "io server disconnect") {
                setWebsocketConnectionStatus(
                    ConnectionStatus.Concluded,
                );
                CocoLogger.info(
                    "Websocket::Concluded"
                );
                clearInterval(rttInterval);
                return;
            }

            // Unexpected disconnect
            setWebsocketConnectionStatus(
                ConnectionStatus.Disconnected,
            );
            setWebsocketConnectionQuality(
                ConnectionQuality.Poor,
            );
            roundTripTime = 0;
            CocoLogger.info(
                "Websocket::Disconnected",
                reason,
            );
        });

        this.socket.on("message", (data, ack) => {
            console.log("Received message", data);
            this.handleMessage(data, ack);
        });

    }

    public concludeSocket() {
        setWebsocketConnectionStatus(
            ConnectionStatus.Concluded,
        );
        this.socket.disconnect();
    }

    private async handleMessage(
        msg: any,
        ack: (response: any) => void
    ) {
        let signal: ServerMessage = JSON.parse(msg);
        console.log("Received message", signal);

        switch (signal.type) {
            case "auth-error": {
                Dispatcher.receiveAuthError(
                    signal
                );
                return;
            }

            case "access-error": {
                Dispatcher.receiveAccessError(
                    signal
                );
                return;
            }

            case "session-error": {
                Dispatcher.receiveSessionError(
                    signal
                );
                return;
            }

            case "processing-error": {
                CocoLogger.error(
                    signal.message
                );
                return;
            }

            case "participant-list": {
                receiveParticipantList(
                    signal,
                    msg
                );
                return;
            }

            case "project-details": {
                await receiveProjectDetails(signal);
                return;
            }

            case "space-cluster-details": {
                await receiveClusterDetails(signal);
                return;
            }

            case "block-reported-user":
            case "remove-user": {
                this.socket.close();
                alert("You have been blocked from this space")
                return;
            }

            case "user-removed": {
                receiveUserRemoved(signal);
                return;
            }

            case "new-consumer": {
                try {
                    client.newConsumer({
                        ...signal,
                        type: signal.consumerType, // re-assign 'type' parameter correctly
                        ack,
                    })
                } catch (err) {
                    console.error("Failed to create new consumer with error", err)
                }
                return
            }
            case "consumer-resumed": {
                try {
                    client.consumerResumed({
                        consumerId: signal.consumerId,
                    });
                } catch (err) {
                    console.error("Failed to resume consumer with error", err)
                }
                return;
            }
            case "consumer-closed": {
                try {
                    client.consumerClosed({
                        consumerId: signal.consumerId,
                    });
                } catch (err) {
                    console.error("Failed to close consumer with error", err)
                }
                return;
            }
            case "sendMessageToCreator": {
                onMessageToCreator(signal);
                return;
            }
            case "set-space-var": {
                onSetSpaceVar(signal);
                return;
            }
            case "add-new-message": {
                onAddNewMessage(signal);
                return;
            }
            case "replace-messages": {
                onReplaceMessages(signal);
                return;
            }
            case "wave-broadcast": {
                onWaveBroadcast(signal);
                return;
            }

        }


        if (signal.type.match(/-space-var$/)) {
            spaceVarsEmitter.post(signal as SpaceVarsMessage)
            return
        }

        await handleCollabMessage(signal as CollabMessage, this.params);
    }

    public async sendWsMessage<T>(
        event: T & { type?: string },
    ) {
        if (!SOCKET_REQUEST_BLACKLIST.includes(event.type as string)) {
            CocoLogger.info("Websocket::sendWsMessage", { type: event.type })
        }
        this.socket.emit("message", JSON.stringify(event));
    }

    public async sendWsMessageWithResponse<T>(
        event: T & { type?: string },
    ) {
        if (!SOCKET_REQUEST_BLACKLIST.includes(event.type as string)) {
            CocoLogger.info("Websocket::sendWsMessage", { type: event.type })
        }
        return new Promise<any>((resolve, reject) => {
            this.socket.emit("message", JSON.stringify(event), (response: any) => {
                resolve(
                    JSON.parse(response)
                );
            });
        });
    }
}

