import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import CocoModal from 'components/coco-modal/coco-modal';
import FieldWithLabel from 'components/field-with-label/FieldWithLabel';
import ControlledTextField from 'components/forms/ControlledTextField';
import React, { useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { reportServerError } from 'utils/report-error';
import PlatformInfoFieldSet from 'components/PlatformInfoFieldSet/PlatformInfoFieldSet';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function FeedbackModal({ onClose }: { onClose: () => void }) {
  const { _ } = useLingui();
  const formRef = useRef();
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <CocoModal onClose={onClose} header={_(msg`Share your feedback`)}>
      <Box ref={formRef} p={4} component='form'>
        <PlatformInfoFieldSet />
        <FieldWithLabel
          label={_(msg`We'd love to hear about your experiences with CoCo:`)}
          name='suggestions'
          field={
            <>
              <Box mt={3} />
              <TextField
                multiline
                minRows={10}
                name='suggestions'
                variant='outlined'
                placeholder={_(
                  msg`Anything you are loving? Anything that could be better? Anything you are excited to try next? Any other comments?...`,
                )}
                fullWidth
                sx={{
                  minWidth: {
                    xs: '100%',
                    sm: '450px',
                  },
                }}
              />
            </>
          }
        />
        <Box mt={4} />
        <Stack direction='row' justifyContent='end' gap={2}>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            color='primary'
            onClick={async () => {
              setIsLoading(true);

              try {
                const formData = new FormData(formRef.current);
                await httpClient.post(
                  '/survey/generic-in-app-feedback/0/multipart',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                );
                await Swal.fire({
                  title: _(msg`Thank you for sharing your feedback.`),
                  text: _(
                    msg`We will look into the issue and get back to you soon.`,
                  ),
                  confirmButtonText: _(msg`Ok`),
                });
                onClose();
              } catch (e) {
                await Swal.fire({
                  title: _(msg`Failed to submit bug report`),
                  text: _(
                    msg`We failed to submit your feedback. Please try after some time or write to us at hello@coco.build`,
                  ),
                  confirmButtonText: _(msg`Ok`),
                });
                // reportServerError({
                //     title: 'Failed to submit bug report',
                //     error: e
                // });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <Trans>Submit</Trans>
          </LoadingButton>
          <Button variant='outlined' color='primary' onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </Stack>
      </Box>
    </CocoModal>
  );
}
