import { useTheme } from '@emotion/react';
import MobileStepper from '@mui/material/MobileStepper';
import React from 'react';

export default function OnboardingStepper({
  activeStep,
  totalSteps = 3,
}: {
  activeStep?: number;
  totalSteps?: number;
}) {
  return (
    <MobileStepper
      variant='dots'
      activeStep={activeStep}
      steps={totalSteps}
      position='static'
      backButton={<></>}
      nextButton={<></>}
      sx={(theme) => ({
        marginTop: '1rem',
        backgroundColor: 'transparent',
        '& .MuiMobileStepper-dot': {
          backgroundColor:
            activeStep === undefined
              ? theme.palette.action.disabled
              : theme.palette.primary.main,
        },
        '& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot': {
          backgroundColor: theme.palette.action.disabled,
        },
      })}
    />
  );
}
