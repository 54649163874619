import * as z from "zod"
import { FileUploadSchema } from "../../utils/file-upload"

export const EffectInputSchema = z.object({
    name: z.string(),
    assetType: z.union([
        z.literal("block_set"),
        z.literal("p5_text"),
        z.literal("sprite"),
        z.literal("canvas"),
        z.literal("signal_sprite"),
        z.literal("signal_code"),
        z.literal("costume"),
        z.literal("backdrop"),
        z.literal("sound"),
        z.literal("sprite_sprout"),
    ]),
    data: z.any(),
    isLive: z.boolean().nullish(),
    tags: z.string().array().nullish(),
    categories: z.string().array().nullish(),
    sortOrder: z.number().nullish(),    
    primaryFile: FileUploadSchema.nullish(),
    staticPreview: FileUploadSchema.nullish(),
    animatedPreview: FileUploadSchema.nullish(),
    senderSprite: FileUploadSchema.nullish(),
    receiverSprite: FileUploadSchema.nullish(),
    signalPopupTitle: z.string().nullish(),
    enabledForStage: z.boolean().nullish(),
    enabledForSprites: z.boolean().nullish(),
})

export type EffectInput = z.TypeOf<typeof EffectInputSchema>

export const BlockSetSchema = z.object({
    keyPath: z.string().nullish(),
    blocks: z.any().nullish(),
    comments: z.any().nullish(),
    enabledForStage: z.boolean().nullish(),
    enabledForSprites: z.boolean().nullish(),
})

export type BlockSet = z.TypeOf<typeof BlockSetSchema>

export const P5CodeSchema = z.object({
    keyPath: z.string(),
    tags: z.string().array().nullish(),
})

export const CanvasItemScema = z.object({
    keyPath: z.string(),
    rotationCenterX: z.number().nullish(),
    rotationCenterY: z.number().nullish(),
})

export const SpriteItemSchema = z.object({
    keyPath: z.string().nullish()
})

export const SignalSproutSchema = z.object({
    sender: z.object({
        key: z.string().nullish()
    }),
    receiver: z.object({
        key: z.string().nullish()
    }),
    signalPopupTitle: z.string().nullish(),
    enabledForStage: z.boolean().nullish(),
    enabledForSprites: z.boolean().nullish(),
})

export const SpaceEffectSchema = z
    .discriminatedUnion("assetType", [
        BlockSetSchema.extend({ assetType: z.literal("block_set") }),
        BlockSetSchema.extend({ assetType: z.literal("sprite_sprout") }),
        P5CodeSchema.extend({ assetType: z.literal("p5_text") }),
        SpriteItemSchema.extend({ assetType: z.literal("sprite") }),
        SpriteItemSchema.extend({ assetType: z.literal("costume") }),
        SpriteItemSchema.extend({ assetType: z.literal("backdrop") }),
        SpriteItemSchema.extend({ assetType: z.literal("sound") }),
        CanvasItemScema.extend({ assetType: z.literal("canvas") }),
        SignalSproutSchema.extend({ assetType: z.literal("signal_code") }),
        SignalSproutSchema.extend({ assetType: z.literal("signal_sprite") })
    ])
    .and(
        z.object({
            id: z.string(),
            name: z.string(),
            isLive: z.boolean().nullish(),
            tags: z.string().array().nullish(),
            categories: z.string().array().nullish(),
            sortOrder: z.number().nullish(),
            staticPreviewPath: z.string().nullish(),
            animatedPreviewPath: z.string().nullish(),
        })
    )

export type SpaceEffect = z.TypeOf<typeof SpaceEffectSchema>

export const RawSpaceAssetSchema = z.object({
    id: z.string(),
    communityId: z.string().nullish(),
    assetType: z.string(),
    sizeMB: z.number().nullish(),
    keyPath: z.string().nullish(),
    parentId: z.string().nullish(),
    data: z.any(),
})

export type RawSpaceAsset = z.TypeOf<typeof RawSpaceAssetSchema>

export const AssetUpdateReq = z.object({
    id: z.string(),
    userId: z.string(),
    name: z.string().nullish(),
})

export const AssetTagSchema = z.object({
    tag: z.string(),
    label: z.string(),
})

export type AssetTag = z.TypeOf<typeof AssetTagSchema>
