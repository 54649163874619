import * as z from "zod"
import { FileUploadSchema } from "../../utils/file-upload"


export const WorldCorridorSubmissionInputSchema = z.object({
    userId: z.string(),
    title: z.string().nullish(),
    description: z.string().nullish(),
    participantCount: z.number().nullish(),
    country: z.string().nullish(),
    includesWebcam: z.boolean().nullish(),
    audioLanguage: z.string().nullish(),
    submittedFromSpace: z.string().nullish(),
    submittedFromCommunity: z.string().nullish(),
    jamVideo: FileUploadSchema.nullish(),
    thumbnail: FileUploadSchema.nullish(),
    themes: z.string().array().nullish(),
    submittedBy: z.string().nullish(),
    id: z.string().nullish(),
    approved: z.boolean().nullish(),
    rating: z.number().nullish(),
    featuredOrder: z.number().nullish(),
    createdByName: z.string().nullish(),
})

export type WorldCorridorSubmission = z.TypeOf<typeof WorldCorridorSubmissionInputSchema>

export const WorldCorridorSchema = z.object({
    id: z.string(),
    title: z.string().nullish(),
    thumbnail: z.string().nullish(),
    description: z.string().nullish(),
    participantCount: z.number().nullish(),
    country: z.string().nullish(),
    includesWebcam: z.boolean().nullish(),
    audioLanguage: z.string().nullish(),
    approved: z.boolean().nullish(),
    rating: z.number().nullish(),
    featuredOrder: z.number().nullish(),
    spaceId: z.string().nullish(),
    submittedFromSpace: z.string().nullish(),
    submittedFromCommunity: z.string().nullish(),
    jamVideoUrl: z.string().nullish(),
    jamVideoLength: z.number().nullish(),
    createdAt: z.number().nullish(),
    submittedBy: z.string().nullish(),
    createdByName: z.string().nullish(),
})

export type WorldCorridor = z.TypeOf<typeof WorldCorridorSchema>
