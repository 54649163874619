import axios from "axios"


export const getBaseUrl = (
    envHost?: string,
    envPort?: string,
    envSSLEnabled?: boolean
) => {
    const proto = "http" + (envSSLEnabled ? "s" : "")
    return `${proto}://${envHost}:${envPort}`
}

export const getServerBaseUrl = () => {
    // get the host and port from the window object
    if (typeof window !== "undefined") {
        const host = window.location.hostname;
        if (host === 'dev.coco.build') {
            return getBaseUrl(
                'dev-server-v2.coco.build',
                '443',
                true
            )
        } else if (host === 'coco.build') {
            return getBaseUrl(
                'prod-server-v2.coco.build',
                '443',
                true
            )
        }
    }

    return getBaseUrl(
        process.env.SERVER_HOST,
        process.env.SERVER_PORT,
        process.env.SERVER_SSL_ENABLED === "1",
    )
}

export const getPublicAssetBucketUrl = () => `https://${process.env.PUBLIC_ASSET_BUCKET}.s3.amazonaws.com`

export const httpClient = axios.create({
    baseURL: `${getServerBaseUrl()}/api`,
})
