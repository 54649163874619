export const timeout = (delayMS: number) =>
    new Promise<void>((resolve) =>
        setTimeout(() => {
            resolve()
        }, delayMS)
    )

export const rejectAfter = (delayMS: number) =>
    new Promise<void>((_resolve, reject) =>
        setTimeout(() => {
            reject(new Error(`Timeout exceeded: ${delayMS}ms`))
        }, delayMS)
    )

export type Thunk = () => void

export class Deferred<T> {
    didResolve?: boolean
    didResolveOnce?: boolean
    promise!: Promise<T>
    resolve!: (value: T | PromiseLike<T>) => void
    reject!: (reason?: any) => void

    constructor() {
        this.reset()
    }

    reset() {
        this.didResolve = undefined
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = (val) => {
                this.didResolve = true
                this.didResolveOnce = true
                return resolve(val)
            }
            this.reject = (val) => {
                this.didResolve = false
                return reject(val)
            }
        })
    }
}

export const ensureSeq = <TFn extends (...args: any[]) => any>(
    fn: TFn
): TFn => {
    let seqP = Promise.resolve()
    return ((...args: any[]) => {
        seqP = seqP.catch().then(() => {
            return fn(...args)
        })
        return seqP
    }) as any
}

export const retryOnErr = async <T>(
    callback: (fail: (error: Error) => never) => T | Promise<T>,
    maxAttempts = 10
): Promise<T> => {
    let delay = 1000

    let abort = false;

    const fail = (error: Error) => {
        abort = true;
        throw error;
    };
    for (let i = 0; i < maxAttempts; i++) {
        try {
            return await callback(fail)
        } catch (e) {
            console.error(e)
            if (abort) {
                throw e;
            }
            await timeout(delay)
            delay = delay * 2
        }
    }
    throw new Error("Exceeded max attempts")
}
