import { PresenceInfo } from '@cocoplatform/coco-rtc-shared';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { useEffect, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';

const defaultState: {
  [communityId: string]: PresenceInfo;
} = {};

export const communityPresenceAtom = atom({
  key: 'communityPresence',
  default: defaultState,
});

const refreshDuration = 5 * 60 * 1000; // 5 mins -> ms

export const useCommunityPresence = (communityId?: string) => {
  const [presenceMapping, setPresenceMapping] = useRecoilState(
    communityPresenceAtom,
  );

  const updatePresence = async () => {
    try {
      const resp = await httpClient.get('/presence', {
        params: {
          communityId: communityId === '$all' ? undefined : communityId,
        },
      });
      const presenceInfo: PresenceInfo = resp.data;
      setPresenceMapping((mapping) => {
        return {
          ...mapping,
          [communityId ?? '$all']: presenceInfo,
        };
      });
    } catch (e) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error('Failed to fetch presence');
      console.error(e);
    }
  };

  useEffect(() => {
    updatePresence();
    const step = async () => {
      await updatePresence();
      timer = setTimeout(step, refreshDuration);
    };
    let timer = setTimeout(step, refreshDuration);
    return () => {
      clearTimeout(timer);
    };
  }, [communityId]);

  const presenceInfo = communityId ? presenceMapping[communityId] : null;

  const onlineCount = useMemo(() => {
    if (presenceInfo?.users == null) return null;
    return presenceInfo.users.filter((user) => user.isOnline).length;
  }, [presenceInfo?.users]);

  return { presenceInfo, onlineCount };
};
