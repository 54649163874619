import { useEffect, useRef, useState } from 'react';

export default function useFileSelector(p?: {
  onSelect?: (file: File) => void;
}) {
  const fileSelectorRef = useRef<HTMLInputElement>();
  useEffect(createFileSelector, []);
  const [file, setFile] = useState<File>();
  return { file, triggerRef };

  function createFileSelector() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.style.visibility = 'hidden';
    input.addEventListener('change', handleFileChange);
    document.body.appendChild(input);

    fileSelectorRef.current = input;

    return () => {
      document.body.removeChild(input);
      fileSelectorRef.current = undefined;
    };
  }

  function triggerRef(input: HTMLElement | null) {
    if (!input) return;
    input.onclick = () => {
      fileSelectorRef.current?.click();
    };
  }

  function handleFileChange(e: Event) {
    const input = e.currentTarget as HTMLInputElement;
    const file = input.files?.[0];
    setFile(file);
    if (file) {
      p?.onSelect?.(file);
      input.value = '';
    }
    input.files;
  }
}
