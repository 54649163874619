import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  fetchAvailableLocales,
  localeStateAtom,
  updatedLocaleStateAtom,
} from './../../atoms/locale';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { localesMap } from './../../utils/locales';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import GlobeIcon from '!!react-svg-loader!./icon--globe.svg';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useModal } from 'mui-modal-provider';
import LanguageContributeModal, {
  isLanguageContributeModalDismissed,
} from 'components/language-contribute-modal/language-contribute-modal';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export const useLocale = () => {
  const [locale, setLocale] = useRecoilState(localeStateAtom);
  const updatedLocale = useRecoilValue(updatedLocaleStateAtom);
  const [availableLocales, setAvailableLocales] = useState<string[]>([locale]);

  useEffect(() => {
    fetchAvailableLocales().then((locales) => setAvailableLocales(locales));
  }, []);

  useEffect(() => {
    if (locale === 'ach') {
      if (
        !document.querySelector('script[src="//cdn.crowdin.com/jipt/jipt.js"]')
      ) {
        const script = document.createElement('script');
        script.src = '//cdn.crowdin.com/jipt/jipt.js';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
      }
      return;
    } else {
      const existingScript = document.querySelector(
        'script[src="//cdn.crowdin.com/jipt/jipt.js"]',
      );
      if (existingScript) {
        existingScript.parentNode?.removeChild(existingScript);
        window.location.reload();
      }
    }
  }, [locale]);

  const track = useTrack();

  const handleChangeLocale = (newLocale: string) => {
    setLocale(newLocale);
  };

  return { availableLocales, locale, updatedLocale, handleChangeLocale };
};

const LocaleSwitcher: React.FC<{ size?: SelectProps['size'] }> = ({
  size = 'small',
}) => {
  const { locale, handleChangeLocale, availableLocales } = useLocale();
  const track = useTrack();
  return (
    <div>
      <Select
        value={locale}
        onChange={(e) => {
          handleChangeLocale(e.target.value);
        }}
        onOpen={() => {
          track(EVENTS.LOCALE_SWITCHER, INTERACTIONS.CLICK);
        }}
        size={size}
        style={{ backgroundColor: 'white', minWidth: 180 }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          anchorPosition: {
            top: 500,
            left: 100,
          },
        }}
        renderValue={(selected) => localesMap[selected as string]}
      >
        {availableLocales.map((code) => (
          <MenuItem key={code} value={code}>
            {code === locale && <span style={{ marginRight: 8 }}>✔</span>}
            {localesMap[code]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export const LocaleSwitcherIcon = ({
  showLanguageName,
  color,
}: {
  showLanguageName?: boolean;
  color?: string;
}) => {
  const { _ } = useLingui();
  const { locale, handleChangeLocale, availableLocales } = useLocale();
  const [localeAnchorEl, setLocaleAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const localeMenuOpen = Boolean(localeAnchorEl);
  const { showModal } = useModal();

  return (
    <>
      <>
        <Tooltip title={_(msg`Change Language`)}>
          <IconButton
            data-analytics={EVENTS.LOCALE_SWITCHER_NAVBAR}
            onClick={(e) => setLocaleAnchorEl(e.currentTarget)}
            sx={{ paddingRight: 0 }}
            aria-controls={localeMenuOpen ? 'locale-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={localeMenuOpen ? 'true' : undefined}
            disableRipple
          >
            <GlobeIcon style={{ width: 30, height: 30, padding: 2 }} />
            {showLanguageName ? (
              <span
                style={{
                  color: color ?? 'white',
                  fontSize: '1rem',
                  marginLeft: '0.3rem',
                }}
              >
                {localesMap[locale]}
              </span>
            ) : (
              <span
                style={{
                  color: color ?? 'white',
                  fontSize: '0.8rem',
                  marginLeft: '0.2rem',
                }}
              >
                {locale?.toLocaleUpperCase()}
              </span>
            )}
            {localeAnchorEl ? (
              <ArrowDropUp sx={{ color: color ?? 'white' }} />
            ) : (
              <ArrowDropDown sx={{ color: color ?? 'white' }} />
            )}
          </IconButton>
        </Tooltip>
      </>
      <Menu
        anchorEl={localeAnchorEl}
        open={localeMenuOpen}
        id='locale-menu'
        onClose={() => setLocaleAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          // offset the menu items down
          marginTop: '14px',
        }}
      >
        {availableLocales.map((code) => (
          <MenuItem
            key={code}
            value={code}
            onClick={() => {
              setLocaleAnchorEl(null);
              handleChangeLocale(code);
              if (
                code !== 'en' &&
                code !== 'tr' &&
                !isLanguageContributeModalDismissed()
              ) {
                const modal = showModal(LanguageContributeModal, {
                  onClose: () => {
                    modal.destroy();
                  },
                });
              }
            }}
            selected={code === locale}
          >
            {
              <span style={{ marginRight: 8 }}>
                {code === locale ? '✔' : ' '}
              </span>
            }
            {localesMap[code]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LocaleSwitcher;
