import { v4 as uuid } from 'uuid';

declare function gtag(key: string, ...params: any[]): void;

// Session id to be used only for analytics - distinct from space session ids
export let cocoSessionId: string | null = null;

const sessionIdKey = 'coco:anSId';

if (typeof localStorage !== 'undefined') {
  cocoSessionId = localStorage.getItem(sessionIdKey);
  if (!cocoSessionId) {
    cocoSessionId = uuid();
    localStorage.setItem(sessionIdKey, cocoSessionId!);
  }
}

if (typeof gtag !== 'undefined') {
  gtag('set', { cocoSessionId });
}
