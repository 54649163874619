import { MenuItem, Autocomplete, TextField } from '@mui/material';
import ControlLabel from 'components/control-label/ControlLabel';
import React from 'react';
import { Controller } from 'react-hook-form';

export default function ControlledAutoComplete(p: {
  control: any; // TODO FIXME
  register?: any; // TODO FIXME
  error?: boolean; // TODO FIXME
  placeholder?: string;
  name: string;
  items: any[];
  rules?: any;
  multiple?: boolean;
  getOptionLabel: (option: any) => string;
  setInputValue?: (value: string) => void;
  inputProps?: any;
  onOptionClick?: (option: any) => void;
  onFocus?: () => void;
}) {
  return (
    <Controller
      control={p.control}
      name={p.name}
      rules={p.rules}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <Autocomplete
              onChange={(e, item) => {
                onChange(item);
                p.onOptionClick?.(item);
              }}
              value={value}
              onFocus={p.onFocus}
              fullWidth
              freeSolo
              multiple={p.multiple}
              options={p.items}
              getOptionLabel={p.getOptionLabel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...p.inputProps,
                    ...params.inputProps,
                  }}
                  placeholder={p.placeholder}
                  // error={p.error}
                  name={p.name}
                  onChange={(e) => {
                    p?.setInputValue?.(e.target.value);
                    if (p.multiple) {
                      return;
                    }
                    onChange(e.target.value);
                  }}
                  variant='filled'
                  required={!!p.rules?.required}
                />
              )}
            />
          </>
        );
      }}
    />
  );
}
