import React, { useState, useEffect } from 'react';
import C from './ProfileUpdatePage.css';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CheckIcon from '!!react-svg-loader!../icons/tick.svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import times from 'lodash/times';
import setMonth from 'date-fns/setMonth';
import getYear from 'date-fns/getYear';
import format from 'date-fns/format';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { useAuthState } from 'utils/auth-state';
import ControlledTextField from 'components/forms/ControlledTextField';
import ErrorList from 'components/forms/ErrorList';
import LoadingButton from '@mui/lab/LoadingButton';
import { PASSWORD_REGEX } from 'utils/validation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ControlLabel from 'components/control-label/ControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { reportServerError } from 'utils/report-error';
import { getQueryParams } from '../../utils/url-util';
import { useCommunityRecommendations } from '../../atoms/community-recommendations';
import { UserProfile } from '@cocoplatform/coco-rtc-shared';
import StorageOptionControl from 'components/storage-option-control/StorageOptionControl';
import FlexRow from 'components/flex/FlexRow';
import { browserStorage } from 'utils/browser-storage';
import { useUserProfile } from 'atoms/user-profile';
import { Stack, Typography } from '@mui/material';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getCountryList from 'constants/countries';

const getMonthLabel = (monthNum: number) =>
  format(setMonth(new Date(), monthNum), 'MMMM');

export const months = times(12, (it) => ({
  value: it + 1,
  label: getMonthLabel(it),
}));

export const getCommonGenders = () => [t`Female`, t`Male`];
const fieldSets = [/* 'secondary' */ 'primary'] as const;
const minYear = getYear(new Date()) - 100;
const maxYear = getYear(new Date()) - 1;

export default function ProfileUpdatePage(p: { disableRedirect?: boolean }) {
  const { _ } = useLingui();
  const auth = useAuthState();
  const [isFetching, setFetching] = useState(false);
  const [isUpdating, setUpdating] = useState(auth.isProfileUpdated);
  const [isDispatching, setDispatching] = useState(false);
  const [fieldSet, setFieldSet] =
    useState<(typeof fieldSets)[number]>('primary');
  const { fetchCommunityRecommendations } = useCommunityRecommendations();
  const isLastFieldSet = () =>
    fieldSets.indexOf(fieldSet) === fieldSets.length - 1;
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const email = useWatch({ control, name: 'email' });
  const gender = useWatch({ control, name: 'gender' });
  const password = useWatch({ control, name: 'password' });
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(fetchProfile, []);
  const [keepSignedIn, setKeepSignedIn] = useState(DEFAULT_PERSIST_SESSION);
  const { updateUserProfile } = useUserProfile();

  const { canEditAcc, setProfileCompletionState } = auth;

  const header = isUpdating ? (
    <Trans>Update your profile</Trans>
  ) : (fieldSet as string) === 'secondary' ? (
    <Trans>Enter your details</Trans>
  ) : (
    <Trans>Set up your account</Trans>
  );

  if (!canEditAcc) {
    return <Redirect to={ROUTES.LANDING} />;
  }

  return (
    <>
      <h1>{header}</h1>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <div>
            <Trans>
              Your information will not be displayed publicly and will be kept{' '}
              <strong>confidential and secure</strong>.
            </Trans>
          </div>
          <form
            style={{ marginTop: '2rem' }}
            autoComplete='off'
            onSubmit={
              isLastFieldSet()
                ? handleSubmit((data) => {
                    submitProfile(data);
                  })
                : (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }
            }
          >
            <Grid
              container
              spacing={2}
              style={{ position: 'relative', textAlign: 'left' }}
            >
              <ErrorList {...{ errors }} />
              {fieldSet === 'primary' && (
                <>
                  <Grid item xs={12}>
                    <ControlLabel htmlFor='email_control'>
                      <Trans>Email</Trans>
                    </ControlLabel>
                    <TextField
                      id='email_control'
                      fullWidth
                      variant='filled'
                      value={email}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <CheckIcon
                              style={{
                                height: '1rem',
                                width: '1rem',
                                marginTop: '-16px',
                              }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Button
                              variant='text'
                              onClick={() => {
                                history.push(ROUTES.EMAIL_UPDATE);
                              }}
                            >
                              <Trans>Change</Trans>
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ControlLabel htmlFor='first_name_control'>
                      <Trans>First Name</Trans>
                    </ControlLabel>
                    <ControlledTextField
                      name='firstName'
                      id='first_name_control'
                      required
                      error={errors.firstName}
                      register={register('firstName', {
                        required: true,
                      })}
                      inputProps={{
                        autoComplete: 'one-time-code',
                      }}
                      {...{ control }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ControlLabel htmlFor='last_name_control'>
                      <Trans>Last Name</Trans>
                    </ControlLabel>
                    <ControlledTextField
                      id='last_name_control'
                      name='lastName'
                      inputProps={{
                        autoComplete: 'one-time-code',
                      }}
                      error={errors.lastName}
                      register={register('lastName', {})}
                      {...{ control }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlLabel htmlFor='password_control'>
                      <Trans>Password</Trans>
                    </ControlLabel>
                    <ControlledTextField
                      name='password'
                      id='password_control'
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      register={register('password', {
                        pattern: PASSWORD_REGEX,
                      })}
                      InputProps={{
                        autoComplete: 'one-time-code',
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Button
                              variant='text'
                              size='small'
                              onClick={() => {
                                setShowPassword((s) => !s);
                              }}
                            >
                              {showPassword ? (
                                <Trans>Hide</Trans>
                              ) : (
                                <Trans>Show</Trans>
                              )}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      {...{ control }}
                    />
                    <FormHelperText component={'div'}>
                      <PasswordHints password={password} />
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      loading={isDispatching}
                      size='large'
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='submit'
                      style={{ fontWeight: 'bold' }}
                    >
                      {isUpdating ? (
                        <Trans>Update Profile</Trans>
                      ) : (
                        <Trans>Join Coco</Trans>
                      )}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StorageOptionControl
                      checked={keepSignedIn}
                      onChange={(v) => {
                        setKeepSignedIn(v);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 0, paddingTop: 0 }}>
                    <Controller
                      name='acceptsEmail'
                      control={control}
                      render={({ field }) => (
                        <FlexRow alignItems='center'>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            id='accepts_email_control'
                          />
                          <ControlLabel
                            htmlFor='accepts_email_control'
                            style={{ padding: 0, fontSize: '13px' }}
                          >
                            <Trans>
                              It's okay to send me emails about CoCo.
                            </Trans>
                          </ControlLabel>
                        </FlexRow>
                      )}
                    />
                  </Grid>
                </>
              )}
              {(fieldSet as string) === 'secondary' && (
                <>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className={C.birthMonthControl}
                      error={errors.birthMonth}
                    >
                      <ControlLabel htmlFor='birth_month_control'>
                        <Trans>Birth Month</Trans>
                      </ControlLabel>
                      <Controller
                        control={control}
                        {...register('birthMonth', {
                          required: true,
                        })}
                        render={({ field }) => (
                          <Select
                            style={{ textAlign: 'left' }}
                            variant='filled'
                            id='birth_month_control'
                            {...field}
                          >
                            {months.map(({ value, label }) => (
                              <MenuItem key={label} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <ControlLabel htmlFor='birth_year_control'>
                      <Trans>Birth Year</Trans>
                    </ControlLabel>
                    <Controller
                      control={control}
                      {...register('birthYear', {
                        min: minYear,
                        max: maxYear,
                        required: true,
                      })}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant='filled'
                          id='birth_year_control'
                          value={field.value ?? null}
                          type='number'
                          error={!!errors.birthYear}
                          inputProps={{
                            min: minYear,
                            max: maxYear,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlLabel>
                      <Trans>Gender</Trans>
                    </ControlLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: 0,
                    }}
                  >
                    {getCommonGenders().map((cGender) => (
                      <>
                        <Radio
                          key={cGender}
                          name='gender'
                          id={cGender + 'radio'}
                          checked={gender === cGender}
                          onChange={(it) => {
                            if (it.currentTarget.checked) {
                              setValue('gender', cGender);
                            }
                          }}
                        />
                        <FormLabel htmlFor={cGender + 'radio'}>
                          {cGender}
                        </FormLabel>
                      </>
                    ))}
                    <Radio
                      name='gender'
                      checked={
                        !!(gender && getCommonGenders().indexOf(gender) === -1)
                      }
                    />
                    <FilledInput
                      type='text'
                      fullWidth
                      style={{
                        width: 'calc(100% - 1rem)',
                        marginRight: 0,
                        marginLeft: '1rem',
                      }}
                      onChange={(e) => {
                        setValue('gender', e.target.value);
                      }}
                      error={!!errors.gender}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      {...register('country', {
                        required: true,
                      })}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <ControlLabel htmlFor='country_control'>
                            <Trans>Country</Trans>
                          </ControlLabel>
                          <Select
                            {...field}
                            fullWidth
                            id='country_control'
                            variant='filled'
                            label={_(msg`Country`)}
                            error={!!errors.country}
                          >
                            {getCountryList().map((it) => (
                              <MenuItem value={it.code}>{it.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size='large'
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='submit'
                      onClick={
                        /* async () => {
                        const didValidate = await trigger();
                        if (!didValidate) return;
                        setFieldSet('primary');
                      }*/ undefined
                      }
                    >
                      <Trans>Continue</Trans>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </>
      )}
    </>
  );

  function fetchProfile() {
    const { firstVisit } = getQueryParams();
    if (firstVisit) {
      setValue('acceptsEmail', true);
      fetchCommunityRecommendations({ silent: true });
      if (auth.email) {
        setValue('email', auth.email);
      }
      return;
    }
    setFetching(true);
    httpClient
      .get('/user/profile')
      .then(({ data }) => {
        const profile = data as UserProfile;
        if (profile) reset(fromPairs(toPairs(profile).filter(([, v]) => v)));
        if (profile.email) setValue('email', profile.email);
        if (profile.profileUpdatedAt) {
          setUpdating(true);
        }
      })
      .catch((error) => {
        reportServerError({
          title: _(msg`Failed to fetch profile details`),
          error,
        });
      })
      .finally(() => {
        setFetching(false);
      });
  }

  async function submitProfile(data: any) {
    setDispatching(true);
    browserStorage.selectStorage(keepSignedIn);
    try {
      if (data.birthYear != null) data.birthYear = Number(data.birthYear);
      if (data.birthMonth != null) data.birthMonth = Number(data.birthMonth);
      await updateUserProfile(data);
      setProfileCompletionState('COMPLETE');
      if (p.disableRedirect) return;

      // If redirect is enabled, send user to home page
      history.push(ROUTES.HOME);
    } catch (error) {
      reportServerError({
        title: _(msg`Something went wrong`),
        error,
      });
    } finally {
      setDispatching(false);
    }
  }
}

const getPasswordRules = () => [
  { description: t`Have at least 8 characters`, pattern: /^\S*(?=\S{8,})\S*$/ },
  {
    description: t`Have at least 1 letter (a, b, c, ...)`,
    pattern: /^\S*(?=\S*[A-Z])\S*$/i,
  },
  {
    description: t`Have at least 1 number (1, 2, 3, ...)`,
    pattern: /^\S*(?=\S*\d)\S*$/,
  },
  {
    description: t`Include both uppercase and lowercase characters`,
    pattern: /^\S*(?=\S*[A-Z])(?=\S*[a-z])\S*$/,
  },
];

export function PasswordHints(p: { password: string }) {
  const { palette } = useTheme();
  return (
    <>
      <Typography variant='caption'>
        <Trans>Password must</Trans>:
      </Typography>
      <ul style={{ marginTop: '0.5rem' }}>
        {getPasswordRules().map((it) => (
          <li key={it.description}>
            <Stack direction='row' alignItems='center'>
              <Typography variant='caption'>{it.description}</Typography>
              {p.password &&
                (p.password.match(it.pattern) ? (
                  <CheckCircleOutlineIcon
                    style={{ color: palette.success.light, height: '1rem' }}
                  />
                ) : (
                  <CancelIcon
                    style={{ color: palette.error.light, height: '1rem' }}
                  />
                ))}
            </Stack>
          </li>
        ))}
      </ul>
    </>
  );
}
