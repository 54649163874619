import * as H from 'history';

export const visitUrl = (
  target: string,
  opts: { history: H.History; useNewWindow?: boolean },
) => {
  if (opts?.useNewWindow) {
    window.open(target);
  } else if (opts?.history) {
    opts.history.push(target);
  } else {
    location.href = target;
  }
};

export const shouldUseNewWindow = (event: React.MouseEvent) => {
  return event.metaKey || event.ctrlKey;
};

export const isImg = (target: string) =>
  target.match(/(jpe?g|png|svg|gif|webp)$/);
