import { Trans } from '@lingui/macro';
import { Button, CircularProgress } from '@mui/material';
import { useStore } from '@nanostores/react';
import { LocaleSwitcherIcon } from 'components/locale-switcher/LocaleSwitcher';
import OnboardingEntry from 'components/onboarding-flow/OnboardingEntry';
import EducationalOnboardingCorridorInvite from 'components/onboarding-flow/educational/EducationalOnboardingCorridorInvite';
import EducationalOnboardingCorridorSetup from 'components/onboarding-flow/educational/EducationalOnboardingCorridorSetup';
import EducationalOnboardingOrgDetails from 'components/onboarding-flow/educational/EducationalOnboardingOrgDetails';
import EducationalOnboardingProfileDetails from 'components/onboarding-flow/educational/EducationalOnboardingProfileDetails';
import EducationalOnboardingSignUp from 'components/onboarding-flow/educational/EducationalOnboardingSignUp';
import PersonalOnboardingCorridorInvite from 'components/onboarding-flow/personal/PersonalOnboardingCorridorInvite';
import PersonalOnboardingCorridorSetup from 'components/onboarding-flow/personal/PersonalOnboardingCorridorSetup';
import PersonalOnboardingProfileDetails from 'components/onboarding-flow/personal/PersonalOnboardingProfileDetails';
import PersonalOnboardingSignUp from 'components/onboarding-flow/personal/PersonalOnboardingSignUp';
import useInitialOnboardingStep, {
  OnboardingSteps,
} from 'components/onboarding-flow/useInitialOnboardingStep';
import PostLoginNavBar from 'components/post-login-nav-bar/PostLoginNavBar';
import PreLoginNavBar from 'components/prelogin-nav-bar/PreLoginNavBar';
import SharedLayout from 'components/shared-layout/SharedLayout';
import ROUTES from 'constants/routes';
import React, { ReactChild, ReactChildren, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { P, match } from 'ts-pattern';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export const currentActiveOnboardingStep = atom<string>({
  key: 'currentActiveOnboardingStep',
  default: OnboardingSteps[OnboardingSteps.ONBOARDING_LOADING],
});

export default function OnboardingContainer() {
  const { step } = useQueryParams();
  const history = useHistory();
  const initialOnboardingStep = useInitialOnboardingStep();
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState(
    initialOnboardingStep,
  );

  const [_, setCurrentActiveOnboardingStep] = useRecoilState(
    currentActiveOnboardingStep,
  );

  useEffect(() => {
    setCurrentActiveOnboardingStep(OnboardingSteps[currentOnboardingStep]);
  }, [currentOnboardingStep]);

  useEffect(() => {
    if (step === undefined) {
      return;
    }

    const parsedStep = OnboardingSteps[step as keyof typeof OnboardingSteps];

    if (parsedStep !== undefined) {
      setCurrentOnboardingStep(parsedStep);
      // remove step from query params, but keep other params
      const params = new URLSearchParams(window.location.search);
      params.delete('step');
      history.replace({
        search: params.toString(),
      });
    }
  }, [step]);

  useEffect(() => {
    if (currentOnboardingStep === initialOnboardingStep) {
      return;
    }

    if (currentOnboardingStep !== OnboardingSteps.ONBOARDING_LOADING) {
      return;
    }

    setCurrentOnboardingStep(initialOnboardingStep);
  }, [initialOnboardingStep]);

  const onboardingStepComponent =
    initialOnboardingStep === OnboardingSteps.ONBOARDING_LOADING ? (
      <CircularProgress />
    ) : (
      match(currentOnboardingStep)
        .with(OnboardingSteps.ONBOARDING_NOT_REQUIRED, () => (
          <Redirect to='/app/dashboard' />
        ))
        .with(OnboardingSteps.ONBOARDING_LOADING, () => <CircularProgress />)
        .with(OnboardingSteps.ENTRY_COMMMUNITY_TYPE, () => <OnboardingEntry />)
        .with(OnboardingSteps.PERSONAL_SIGNUP, () => (
          <PersonalOnboardingSignUp />
        ))
        .with(OnboardingSteps.EDUCATIONAL_SIGNUP, () => (
          <EducationalOnboardingSignUp />
        ))
        .with(OnboardingSteps.EDUCATIONAL_PROFILE_DETAILS, () => (
          <EducationalOnboardingProfileDetails />
        ))
        .with(OnboardingSteps.PERSONAL_PROFILE_DETAILS, () => (
          <PersonalOnboardingProfileDetails />
        ))
        .with(OnboardingSteps.PERSONAL_CORRIDOR_SETUP, () => (
          <PersonalOnboardingCorridorSetup />
        ))
        .with(OnboardingSteps.PERSONAL_INVITE_CORRIDOR, () => (
          <PersonalOnboardingCorridorInvite />
        ))
        .with(OnboardingSteps.EDUCATIONAL_ORGANIZATION_DETAILS, () => (
          <EducationalOnboardingOrgDetails />
        ))
        .with(OnboardingSteps.EDUCATIONAL_CORRIDOR_SETUP, () => (
          <EducationalOnboardingCorridorSetup />
        ))
        .with(OnboardingSteps.EDUCATIONAL_INVITE_CORRIDOR, () => (
          <EducationalOnboardingCorridorInvite />
        ))
        .otherwise(() => (
          <div>
            <Trans>Unimplemented step {currentOnboardingStep}</Trans>
          </div>
        ))
    );

  const navbar = match(currentOnboardingStep)
    .with(
      P.union(
        OnboardingSteps.ENTRY_COMMMUNITY_TYPE,
        OnboardingSteps.PERSONAL_SIGNUP,
        OnboardingSteps.EDUCATIONAL_SIGNUP,
        // OnboardingSteps.PERSONAL_PROFILE_DETAILS,
        // OnboardingSteps.PERSONAL_CORRIDOR_SETUP,
        // OnboardingSteps.PERSONAL_INVITE_CORRIDOR,
        // OnboardingSteps.EDUCATIONAL_PROFILE_DETAILS,
        // OnboardingSteps.EDUCATIONAL_ORGANIZATION_DETAILS,
        // OnboardingSteps.EDUCATIONAL_CORRIDOR_SETUP,
        // OnboardingSteps.EDUCATIONAL_INVITE_CORRIDOR,
      ),
      () => (
        <PreLoginNavBar
          rightControls={
            <div
              style={{ display: 'flex', gap: '1.5rem', margin: '0.25rem 0' }}
            >
              <Button
                data-analytics={EVENTS.SIGN_IN_NAVBAR}
                variant='outlined'
                color='secondary'
                onClick={() => {
                  history.push(ROUTES.SIGN_IN);
                }}
              >
                <Trans>Sign in</Trans>
              </Button>
              <LocaleSwitcherIcon />
            </div>
          }
        />
      ),
    )
    .otherwise(() => <PostLoginNavBar />);

  const backHref = match(currentOnboardingStep)
    .with(OnboardingSteps.ENTRY_COMMMUNITY_TYPE, () => ROUTES.WELCOME)
    .with(
      P.union(
        OnboardingSteps.PERSONAL_SIGNUP,
        OnboardingSteps.EDUCATIONAL_SIGNUP,
      ),
      () => ROUTES.ONBOARDING + '?step=' + 'ENTRY_COMMMUNITY_TYPE',
    )
    .otherwise(() => undefined);

  return (
    <SharedLayout backHref={backHref} medium={true} header={navbar}>
      {onboardingStepComponent}
    </SharedLayout>
  );
}
