import Box from '@mui/material/Box';
import React from 'react';

export default function ControlLabel(p: {
  htmlFor?: string;
  children?: React.ReactChild;
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <Box
      component='label'
      sx={{
        color: 'var(--color-subtext)',
        fontWeight: 'var(--font-weight-regular)',
        paddingBottom: '0.5rem',
        display: 'block',
        textAlign: 'left',
      }}
      htmlFor={p.htmlFor}
      style={p.style}
      className={p.className}
    >
      {p.children}
    </Box>
  );
}
