/* eslint-disable lingui/no-unlocalized-strings */
export const localesMap = {
  ach: 'Translate',
  ab: 'Аҧсшәа',
  af: 'Afrikaans',
  ar: 'العربية',
  am: 'አማርኛ',
  an: 'Aragonés',
  ast: 'Asturianu',
  az: 'Azeri',
  id: 'Bahasa Indonesia',
  bn: 'বাংলা',
  be: 'Беларуская',
  bg: 'Български',
  ca: 'Català',
  cs: 'Česky',
  cy: 'Cymraeg',
  da: 'Dansk',
  de: 'Deutsch',
  et: 'Eesti',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español (España)',
  'es-419': 'Español Latinoamericano',
  eo: 'Esperanto',
  eu: 'Euskara',
  fa: 'فارسی',
  fil: 'Filipino',
  fr: 'Français',
  fy: 'Frysk',
  ga: 'Gaeilge',
  gd: 'Gàidhlig',
  gl: 'Galego',
  ko: '한국어',
  ha: 'Hausa',
  hy: 'Հայերեն',
  he: 'עִבְרִית',
  hi: 'हिंदी',
  hr: 'Hrvatski',
  xh: 'isiXhosa',
  zu: 'isiZulu',
  is: 'Íslenska',
  it: 'Italiano',
  ka: 'ქართული ენა',
  kk: 'қазақша',
  qu: 'Kichwa',
  sw: 'Kiswahili',
  ht: 'Kreyòl ayisyen',
  ku: 'Kurdî',
  ckb: 'کوردیی ناوەندی',
  lv: 'Latviešu',
  mk: 'Macedonian',
  my: 'မြန်မာ',
  lt: 'Lietuvių',
  hu: 'Magyar',
  mi: 'Māori',
  mn: 'Монгол хэл',
  nl: 'Nederlands',
  ja: '日本語',
  'ja-Hira': 'にほんご',
  nb: 'Norsk Bokmål',
  nn: 'Norsk Nynorsk',
  oc: 'Occitan',
  or: 'ଓଡ଼ିଆ',
  uz: 'Oʻzbekcha',
  th: 'ไทย',
  km: 'ភាសាខ្មែរ',
  pl: 'Polski',
  pt: 'Português',
  'pt-br': 'Português Brasileiro',
  rap: 'Rapa Nui',
  rm: 'Rumantsch',
  ro: 'Română',
  ru: 'Русский',
  nso: 'Sepedi',
  tn: 'Setswana',
  sk: 'Slovenčina',
  sq: 'Shqip',
  sl: 'Slovenščina',
  sr: 'Српски',
  fi: 'Suomi',
  sv: 'Svenska',
  vi: 'Tiếng Việt',
  tr: 'Türkçe',
  uk: 'Українська',
  'zh-cn': '简体中文',
  'zh-tw': '繁體中文',
  'zh': '中文',
};
