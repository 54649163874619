import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useMemo } from 'react';

export const useQueryParams = (): any => {
  const { search } = useLocation();
  return useMemo(() => {
    return qs.parse(search.slice(1));
  }, [search]);
};
