import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from './components/home-page/HomePage';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/900.css';
import 'styles/global.css';
import '!!style-loader!css-loader?modules=false!animate.css';
import { enforeHttps, onReady } from 'utils/dom';
import { RecoilRoot } from 'recoil';
import './utils/auto-session.ts';
import LocaleProvider from 'components/locale-switcher/LocaleProvider';
import { BrowserRouter } from 'react-router-dom';

async function main() {
  if (ENFORCE_HTTPS) {
    const didRedirect = enforeHttps();
    if (didRedirect) return;
  }

  ReactDOM.hydrate(
    <RecoilRoot>
      <LocaleProvider>
        <BrowserRouter>
          <HomePage />
        </BrowserRouter>
      </LocaleProvider>
      ,
    </RecoilRoot>,
    document.getElementById('root'),
  );
}

onReady(main);
