import { msg, t } from '@lingui/macro';

const GET_AGE_GROUPS = () => [
  {
    value: t`Under 10`,
  },
  {
    value: '10-13',
  },
  {
    value: '14-18',
  },
  {
    value: t`Above 18`,
  },
];

export default GET_AGE_GROUPS;
