import { Trans } from '@lingui/macro';
import { Box, Divider, Fade, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useCommunitySummaries } from 'atoms/community-summaries';
import { useOwnOrganizations } from 'atoms/organizations';
import { useUserProfile } from 'atoms/user-profile';
import CommunityInfoForm from 'components/community-info-form/CommunityInfoForm';
import { EducationRequestModal } from 'components/community-invite-form/CommunityInviteForm';
import OnboardingStepper from 'components/onboarding-flow/OnboardingStepper';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import ROUTES from 'constants/routes';
import { useModal } from 'mui-modal-provider';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export default function EducationalOnboardingCorridorSetup(p: {
  postOnboarding: boolean;
}) {
  const { communities, syncState, trigger } = useCommunitySummaries({
    autoLoad: false,
  });

  const history = useHistory();

  const { startGettingStartedTutorial } = useTutorial();

  const { userProfile, fetchUserProfile } = useUserProfile();

  const { showModal } = useModal();

  const {
    organizations,
    loadOrganizations,
    syncState: orgSyncState,
  } = useOwnOrganizations();

  useEffect(() => {
    if (orgSyncState === 'pending') {
      loadOrganizations();
    }
  }, [orgSyncState]);

  if (orgSyncState !== 'loaded') {
    return <></>;
  }

  return (
    <Fade in>
      <Stack alignItems='center'>
        {!p.postOnboarding && (
          <Box>
            <Typography mt={4} variant='h1' textAlign='center'>
              <Trans>Set up your corridor</Trans>
            </Typography>
            <Box mt={4} />
          </Box>
        )}
        <Typography
          variant='subtitle1'
          textAlign='center'
          maxWidth='480px'
          mx='auto'
        >
          <Trans>
            A <strong>corridor</strong> is a safe, private community for invited
            members to co-create, code, and collaborate with one another.
          </Trans>
        </Typography>
        {!p.postOnboarding && (
          <>
            <Box mt={2} />
            <Divider sx={{ width: '100%' }} />
          </>
        )}
        <Box mt={4} />

        <CommunityInfoForm
          organization={organizations[0]}
          onSuccess={async ({ id }) => {
            await trigger();

            const data = await fetchUserProfile();

            if (!data?.educationalAccessApproved) {
              const modal = showModal(EducationRequestModal, {
                onClose: () => {
                  modal.destroy();
                },
              });
            }

            history.push(`/app/dashboard/${id}`);

            if (p.postOnboarding) {
              return;
            }
          }}
        />
        <Box mt={2} />
        {!p.postOnboarding && (
          <OnboardingStepper totalSteps={3} activeStep={2} />
        )}
      </Stack>
    </Fade>
  );
}
