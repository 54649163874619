import { atom } from 'recoil';

export const defaultFilters = {
  listQuery: '',
  sectionPresets: {
    'coco-jams': 'all',
    'spaces-to-remix': 'all',
    'new-spaces-to-join': 'all',
    'my-coco-spaces': 'all',
    'explore-other-spaces': 'all',
    /* active: 'all',
    upcoming: 'all',
    past: 'all', */
  },
  selectedThemeIds: [] as string[],
  participantCount: 'any',
};

export const spaceFiltersAtom = atom({
  key: 'spaceFilters',
  default: defaultFilters,
});
