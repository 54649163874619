// Lightweight enhancements over nanostores

import {
    atom as nsAtom,
    computed,
    ReadableAtom as NSReadableAtom,
    WritableAtom as NSWritableAtom,
} from "nanostores"

export interface ReadableAtom<TValue> extends NSReadableAtom<TValue> {
    select<TDerived>(
        selector: (val: TValue) => TDerived
    ): ReadableAtom<TDerived>
}

export interface WritableAtom<TValue>
    extends NSWritableAtom<TValue>,
        ReadableAtom<TValue> {
    update(updater: Updater<TValue>): void
}

export interface Selector<TValue, TDerived> {
    (original: TValue): TDerived
}

export interface Updater<TValue> {
    (prev: TValue): TValue
}

export const wrapReadable = <TValue, TWrapped extends NSReadableAtom<TValue>>(
    wrapped: TWrapped
): TWrapped & ReadableAtom<TValue> => {
    return Object.create(wrapped, {
        select: {
            value: <TDerived>(selector: Selector<TValue, TDerived>) => {
                return wrapReadable(computed(wrapped, selector))
            },
        },
    })
}

export const wrapWritable = <TValue, TWrapped extends NSWritableAtom<TValue>>(
    wrapped: TWrapped
): WritableAtom<TValue> & TWrapped => {
    return Object.create(wrapped, {
        update: {
            value: (updater: Updater<TValue>) => {
                wrapped.set(updater(wrapped.get()))
            },
        },
    })
}

export const atom = <TValue>(defaultVal: TValue): WritableAtom<TValue> => {
    const wrapped = nsAtom<TValue>(defaultVal)
    return wrapWritable(wrapReadable(wrapped))
}
