import * as z from "zod"

export const FileUploadSchema = z.object({
    path: z.string(),
    originalname: z.string(),
    mimetype: z.string().optional(),
    size: z.number(),
})

export type FileUpload = z.TypeOf<typeof FileUploadSchema>
